import filter from 'constants/filterConstants';
import { Day } from 'react-modern-calendar-datepicker';
import subjectTypes from 'constants/subjectTypesConstants';
import { sortType } from 'constants/sortTypeConstants';
import reportConstants from 'constants/reportConstants';
import { terms } from 'constants/termsConstants';
import additionalOptions from 'constants/additionalOptsConstants';
import { myReservationListLnb } from 'constants/lnbConstants';
import topics from 'constants/questionTopics';
import fields from 'constants/productCategoryFields';
import items from 'constants/productTypeConstants';
import productSortConstants from 'constants/productSortConstants';
import articleTypes from 'constants/articleTypes';
import productCategory from 'constants/productCategoryConstants';
import communityQuestionTopics from 'constants/communityQuestionTopics';
import { ISelectedAnswer } from 'hooks/MyItemEdit/useItemForm';
import campSortConstants from 'constants/campSortConstants';
import curation from 'constants/curationConstants';
import logFilter, { camplogFilterList } from 'constants/logFilterConstants';
import {
  SHOPBY_CLAIM_STATUS_TYPE,
  SHOPBY_BANK,
  SHOPBY_CARD_COMPANY_TYPE,
  SHOPBY_NEXT_ACTION_TYPE,
  SHOPBY_ORDER_REQUEST_TYPE,
  SHOPBY_ORDER_STATUS_TYPE,
  SHOPBY_PAY_TYPE,
  SHOPBY_PG_TYPE,
  SHOPBY_REFUND_TYPE,
  SHOPBY_CLAIM_CLASS_TYPE,
  SHOPBY_AGREEMENT,
  SHOPBY_CLAIM_TYPE,
  SHOPBY_CLAIM_REASON_TYPE,
  SHOPBY_ITEM_INQUIRY_TYPE_LIST,
  SHOPBY_ACCUMULATION_CONFIG,
  SHOPBY_WITHDRAWABLE_STATE_FROM_VALIDATION_TYPE,
  SHOPBY_ACCUMULATION_REASON_TYPE,
  SHOPBY_ACCUMULATION_STATUS_GROUP_TYPE,
  SHOPBY_ACCUMULATION_RESERVE_REASON_TYPE,
  SHOPBY_ACCUMULATION_STATUS_TYPE,
  SHOPBY_ITEM_STATUS_TYPE_LIST,
} from 'constants/shopbyConstants';
import { THostsAndSuppliers } from 'store/types';
import { ReactNode } from 'react';
import { campTypes, floorTypes } from 'constants/campInfoConstants';
import { IFeedBanner } from 'types/feed';

export interface ITo {
  pathname: string;
  search?: string;
  state?: { from: string | { pathname: string } } | { tab: string | number };
}

export interface ITerm {
  id: string;
  type?: string;
  name?: string;
  title: string;
  isRequired: boolean;
  content?: string;
  markdownContent?: JSX.Element | string;
  getMarkdownContent?: JSX.Element | string;
  warningText?: string;
}

export interface IContent {
  title?: string | ReactNode;

  content?: string | ReactNode;
}

export type TToType = string | ITo;
export type PropertyType<T> = T[keyof T];

export type TItemsMap<K> = { [key in keyof K]: K[key] };
export type TTermsData = typeof terms;
export type TFilter = typeof filter;

export type TLogFilter = typeof logFilter;
export interface IDefaultOption {
  name: string;
  value: string;
}

export interface ICampLogFilterValue {
  id: string;
  name: string;
  types: IDefaultOption[];
}
export type TCampLogFilter = typeof camplogFilterList;

export type IFilterCategory = PropertyType<TFilter>;

export type CustomDayClassNameItem = Day & { className: string };

export type TMyListLNBSubject =
  | 'boards'
  | 'bookmark'
  | 'replies'
  | 'reviews'
  | 'bookings'
  | string;

export type TAdditionalOptions = typeof additionalOptions;

export interface IResponse {
  status: 'success' | 'fail' | string;
  message: string;
}

export interface ICouponResonse {
  status: 'success' | 'fail';
  message: undefined | string;
  userCouponId: string | undefined;
  couponId: string;
}

export interface IProductEditResponse {
  status: string;
  equipmentId: string;
}

export interface ILogInResponse {
  jwt: string;
  user: ILoggedInUser;
  isCertified: boolean;
}

export interface ISocialLogInResponse extends ILogInResponse {
  status: 'success' | 'fail';
  message: string;
  nextRoute: string;
  email?: string;
  provider?: string;
}

export interface ILoggedInUser {
  email: string;
  nickname: string;
  thumbnail: IMedia;
  username: string;
  brief?: string;
  role: {
    name: string;
  };
  marketingAgreement: boolean;
}

export interface ICheckAuthResponse extends IResponse {
  username: string;
  email: string;
  newPassword: string;
}

export interface ICertResponse extends IResponse {
  code: string;
  isDuplicated: boolean;
}

export interface IRegisterResponse extends IResponse {
  isEmailDuplicated: boolean;
  isUsernameDuplicated: boolean;
  jwt: string;
  user: ILoggedInUser;
}

export interface ISocialLoginRegisterResponse extends IRegisterResponse {
  flow: 'register' | 'passwordConfirm' | 'passConfirm' | 'connect' | 'confirm';
  username: string;
  certificationId: string;
  socialLoginId: string;
  userId: string;
  name: string;
  email: string;
}

export interface IWriteOnBoardResponse extends IResponse {
  boardId: string;
  status: string;
}

export interface ISearchGeoResponse {
  camps: ICampGeo[];
  totalCount: number;
  page: number;
  totalPage: number;
  pageSize: number;
}

export interface TCouponPrice {
  isDiscountPrice: boolean;
  discountPrice: number;
  discountPercent: number;
}

export interface TCouponData extends TCouponPrice {
  masterCode: string;
  name: string;
  target: 'camp' | 'zone';
}

export interface IWriteReviewResponse extends IResponse {
  isCouponIssued: boolean;
  campName?: string;
  coupon?: TCouponData;
  userCouponId?: string;
  banners?: IFeedBanner[];
}

export interface IHostAndSuppliers {
  name: THostsAndSuppliers;
  data: string[];
}

export interface IBanner {
  id: string;
  link: string;
  image: IMedia;
  isNewWindow: boolean;
  mainGradient: string; // (hex color code)
  subGradient: string; // (hex color code)
}

export interface ICuration {
  id: string;
  name: string;
  link: string;
  isNewWindow: boolean;
  image: IMedia;
  order: number;
  badge?: string;
}

export interface ILandingPage {
  html: string;
  title: string;
}

export interface IExhibition {
  code: string;
  title: string;
  brief: string;
  thumbnail: IMedia;
  displayType: string;
  landingPageHTML?: string;
}

export enum EnumProuctExhibitions {
  title = 'title',
  view = 'view',
  undefined = 'undefined',
}

export interface IGetProuctExhibitions {
  /** 기획전당 상품을 몇개 보여줄지 정보 (필요 없으면 0) */
  sampleSize: number;

  /** 웬만하면 undefined로 쓰기 */
  sort: EnumProuctExhibitions;
  page: number;
  pageSize: number;
}

export interface IProductExhibitionsResponse {
  id: string;

  /** 기획전 코드 */
  code: string;

  /** 기획전명 */
  title: string;

  /** 기획전 소개글 */
  brief: string;

  /** 배너 사진 */
  thumbnail: IMedia;

  /** 메인 기획전 여부 */
  isForMain: boolean;

  /** 기획전에 등록된 상품 수 */
  numOfProducts: number;

  /** sampleSize 만큼 기획전 상품 뿌려줌 */
  products: [IProduct];
}

export interface IRecommendKeywords {
  type: 'bookmark' | 'recommend'; // recommend: 어드민 '추천 검색어' 탭에서 설정한 키워드
  keyword: string;
}

export interface ICampExhibition extends IExhibition {
  subMedia: IMedia;
  camps: ICamp[];
  numOfViewed: number;
  numOfCamps: number;
  displayPriceType?: 'all' | 'coupon';
}

export interface IRecommendExhibition {
  title: string;
  data: {
    camp: ICamp;
    count: number;
  }[];
}
export interface IAdditionalExhibitions {
  title: string;
  brief?: string;
  thumbnail: IMedia;
  subMedia: IMedia;
  numOfCamps: number;
  code: string;
  camps: ICamp[];
  type: TCampExhibitionType;
  displayPriceType?: 'all' | 'coupon' | null;
}

export interface IInnerBadge extends ICampBadges {
  _id?: string;
  camp?: string;
  theme: 'violet' | 'primary' | 'blue';
}

export interface ICampOriginBadge {
  badge: IInnerBadge;
  camp: string;
  id: string;
}

export interface ICamp {
  id: string; // DB id 값
  code: string; // C000000000
  name: string; // 캠핑장명
  types: string[]; // [“autoCamping”,“pension”]
  thumbnail: IMedia; // 하단 참고
  city: string; // 시
  major: string; // 군,구
  address1: string; // 상세주소 제외 주소
  priceStartFrom: number; // 캠핑장 내 최저가
  isOnlinePaymentAvailable: boolean;
  isBankPaymentAvailable: boolean;
  bookmarkCnt: number;
  numOfReviews: number;
  user?: {
    username: string;
  };
  sale?: string;
  touristAttractions: string[];
  leisureTypes: string[];
  facilities: string[];
  additionalFacilities: string[];
  availableDays: IAvailableDays;
  locationTypes: string[];
  hashtags: string[];
  mannerTime: number | null;
  mannerTimeEnd: number | null;
  brief: string;
  locationBrief: string;
  minBookableDays: number;
  maxBookableDays: number;
  canLongTermStay: boolean;
  medias: IMedia[];
  isAvailable: boolean; // 빈자리 있는지 여부
  vacantRate: number; // 공실 비율 0(만실) ~ 1(모두 공실)
  contact: string;
  isAD?: boolean;
  adId?: string;
  numOfCoupons: number;
  bestCoupons: TBestCoupon[];
  campBadges?: ICampOriginBadge[];
  couponPriceStartFrom: number | null;
  discountPercent: number;
  originalPrice: number | null;
}

export interface IExhibitionSite {
  name: string;
  _id: string;
}

export interface IExhibitionZone {
  name: string;
  numOfAdults: number;
  numOfMinors: number;
  numOfPeople: number;
  price: number;
  sites: IExhibitionSite[];
  thumbnail: string;
  _id: string;
}

export interface IMainNewCamp {
  id: string; // DB id 값
  name: string; // 캠핑장명
  types: string[]; // [“autoCamping”,“pension”]
  thumbnail: IMedia; // 하단 참고
  city: string; // 시
  major: string; // 군,구
  numOfCoupons?: number;
  priceStartFrom: number; // 캠핑장 내 최저가
  campBadges?: ICampOriginBadge[];
  couponPriceStartFrom?: number | null;
  discountPercent?: number;
  originalPrice?: number | null;
}
export interface IZoneSiteExhibitionCamp {
  _id: string;
  name: string;
  locationTypes: string[];
  isOnlinePaymentAvailable: boolean;
  isBankPaymentAvailable: boolean;
  city: string;
  major: string;
  code: string;
  priceStartFrom: number;
  types: string[];
  brief: string;
  thumbnail: IMedia;
  numOfReviews: number;
  numOfCoupons: number;
  zones?: IExhibitionZone[];
  medias: IMedia[];
}
export interface ICampAdvertisement extends ICamp {
  isAD: boolean;
  adId: string;
}
export interface ICampGeo extends ICamp {
  latitude: number;
  longitude: number;
}

export interface IMedia {
  _id: string;
  id: string;
  url: string;
  formats: IImgFormat; // image일 때만;
  ext: string; // 확장자
  name: string;
  mime: string;
  caption?: string; // 이미지에 대한 설명글
  height: number;
  width: number;
}

export interface IPagingMedia {
  equipmentId: string;
  user: {
    nickname: string; // 리뷰 작성자 닉네임
    thumbnail: undefined | string; // 리뷰 작성자 썸네일 (없으면 썸네일 미등록 유저)
  };
  media: IMedia;
  timestamp: number; // 해당 리뷰가 작성된 timestamp, 다음 사진을 추가로 불러올 때 이 timestamp로 보내주어 겹치는 사진 없이 가져오게 해야 함
}

export interface IImgFormat {
  large: IImgDetail;
  medium: IImgDetail;
  small: IImgDetail;
  thumbnail: IImgDetail;
}

export interface IImgDetail {
  url: string;
  height: number;
  weight: number;
}

export interface ISelfCheckinSelect {
  value: string | null;
}

export interface ISelfCheckInQuestion {
  title: string;
  isMandatory: boolean;
  isCommon: boolean;
  selects: ISelfCheckinSelect[];
  type: string;
}

export interface IZone {
  id: string;
  name: string;
  floorType: string;
  parkingType: string;
  siteLength: number;
  siteWidth: number;
  siteArea: number;
  thumbnail: IMedia;
  sale: string;
  price: number;
  campnicCheckoutTime: string;
  canCampnic: boolean;
  canLongTermStay: boolean;
  numOfAdults: number;
  numOfMinors: number;
  maxNumOfExtraPeople: number;
  minBookableDays: number; // 최소 예약 가능 박수
  maxBookableDays: number; // 최소 예약 가능 박수
  numOfAvailableSites: number; // 예약 가능 사이트
  numOfDays?: number; // 스케쥴 잡은 경우 박수
  camp: ICampDetail;
  availableDays: IAvailableDays;
  type: string;
  numOfCars: number;
  checkInTime: string;
  checkoutTime: string;
  isPeopleLimitTotal: boolean;
  numOfPeople: number;
  unavailableReason: string;
  introduction: string; // 캠핑존 한줄 소개
  canSelfCheckIn: boolean;
  campnicCharges: ICampnicCharge;
  minBookingPeriod?: number;
  originalPrice?: number;
}

export interface ISite {
  id: string;
  name: string;
  isAvailable: boolean;
  unavailableReason: string;
  siteLength: number;
  siteWidth: number;
  siteArea: number;
}

export interface ICampingLog {
  id: string;
  post?: string;
  title: string;
  text: string;
  camp: string;
  zone: string;
  site: string;
  booking: {
    checkInTimestamp: number;
    checkoutTimestamp: number;
  };
  medias: IMedia[];
  reviewTimestamp: number;
  bookingTimestamp: number;
  user: IUser;
  numOfViewed: number;
  campId: string;
  userType: string;
  isClean: boolean;
  isKind: boolean;
  isConvenient: boolean;
  isMannerTimeMaintained: boolean;
  isReplied: boolean;
  reply: string;
  // 캠지기 답변 이미지
  replyMedias?: IMedia[];
  replyTimestamp: number;
  isHidden: boolean;
  // 비공개 여부 (캠퍼, 캠지기, 마스터)
  isHiddenByCamper: boolean;
  // 캠퍼가 비공개 처리 (= 사장님만 보기)
  hostHiddenReason: string;
  // 캠지기 숨김 사유
  isUserSecret: boolean;
  // 메인 화면에서만 사용되는 파라미터
  campThumbnail?: IMedia;
  // 캠핑 로그에서만 사용되는 파라미터
  zoneType?: string;
  season?: string;
  city?: string;
  major?: string;
  locationTypes?: string;
  userEquipments: IUserEquipment[];
}

export interface IUserEquipment {
  id: string;
  medias: IMedia[];
}

export interface ICampingLogContent {
  file?: File;
  url?: string;
  isVideo: boolean;
  isExisted: boolean;
}

export interface IUser {
  thumbnail: IMedia;
  nickname: string;
  brief: string;
}

export interface TBestCoupon extends TCouponPrice {
  id: string;
}

export interface ICampBadges {
  title: string;
  link: string | null;
  image?: IMedia;
}

export interface ICampDetail {
  id: string; // DB id 값
  name: string; // 캠핑장명
  types: string[]; // [“autoCamping”,“pension”]
  thumbnail: IMedia; // 하단 참고
  medias: IMedia[];
  mapPicture: IMap;
  address1: string; // 상세주소 제외 주소
  address2: string;
  priceStartFrom: number; // 캠핑장 내 최저가
  description: string;
  phone: string;
  additionalFacilities: string[];
  leisureTypes: string[];
  locationBrief: string;
  facilities: string[];
  locationTypes: string[];
  isOnlinePaymentAvailable: boolean;
  canCampnic: boolean;
  canLongTermStay: boolean;
  warning: string;
  policy: string;
  peakPeriods: IPeriod[];
  refundPolicies: IRefundPolicies;
  contact: string;
  bookableMonth: number;
  bookableUntilTimestamp: number;
  bookableSettings: IBookableSetting[];
  schedules: ITimestamp[];
  availableDays: IAvailableDays;
  manualBookables: ITimestamp[];
  refundActualExpense: number; // 환불 수수료
  chargeActualExpenseEveryRefund: boolean; // true이면 모든 취소건에 대해 환불 수수료 공제, false면 100% 환불건에만 공제
  isActive: boolean; // 캠핑장 운영여부
  isUsingAutoBlacklist: boolean; // 자동 예약 제한 사용 여부
  autoBlacklistCheckDays: number; // 감시 기간 (일수)
  autoBlacklistPendingLimit: number; // 미입금취소 횟수
  autoBlacklistBlockDays: number; // 예약 제한 기간 (일수)
  dDayCloseHour: number; // 당일 예약 마감시간, 0~23 설정가능, 0이면 당일예약 불가
  dailyOpenHour: number; // 하루 단위로 여는 캠핑장(bookableMonth > 0)의 오픈 시간, 0~23 설정가능
  refundDay: string;
  hashtags: string[];
  mannerTime: number;
  mannerTimeEnd: number;
  brief: string;
  company: ICompany;
  minBookableDays?: number;
  isBookmarked: boolean;
  touristAttractions: string[];
  numOfCoupons: number;
  bestCoupons: TBestCoupon[];
  csCenterUrl?: string;
  city: string;
  major: string;
  isOnPlan: boolean;
  singleRefundPolicies?: ISingleRefundPolicies;
  campBadges: ICampBadges[];
  grade?: number;
  numOfViewed?: number;
  bookmarkCount?: number;
  longTerm: {
    isAvailable: boolean;
  };
  longTermAgreementForm?: string;
}

export interface ICompany {
  name: string; // 회사명
  president: string; // 대표자명
  email: string; // 회사 이메일
  address: string; // 회사 주소
  registrationNumber: string; // 사업자등록번호
  campRegistrationNumber: string; // 관광사업(야영장) 등록번호
}

export interface IBookableSetting {
  openTimestamp: number; // 예약 오픈할 날짜 + 시간 (분까지),
  bookableUntilTimestamp: number; // 시점에 오픈할 마지막 날짜
}

// 예약불가기간
export interface ITimestamp {
  startTimestamp: number;
  endTimestamp: number;
}

export interface IRefundPolicies {
  days: number[];
  peakWeekday: number[];
  peakWeekend: number[];
  superPeakWeekday: number[];
  superPeakWeekend: number[];
  weekday: number[];
  weekend: number[];
  longTermRefundPolicies?: {
    afterCheckIn: {
      fee: number;
    };
    beforeCheckIn: {
      fee: number;
      days: number;
    }[];
  };
}

// export interface IIPartnerPolicies {

// }

export interface IPeriod {
  fromMonth: number;
  fromDay: number;
  toMonth: number;
  toDay: number;
  isSuperPeak: boolean;
  minNumOfDays: number;
}

export interface IHoliday {
  month: number;
  day: number;
  year: number;
  name: boolean;
  isEveryYear: boolean;
}

export interface IMap {
  id: string;
  url: string;
  formats: IImgFormat;
}

export interface IPetInfo {
  canBringPet: boolean;
  isPetChargeOnce: boolean;
  hasRestrictedPets: boolean;
  restrictedPets: string[];
  minPetWeight: number;
  maxPetWeight: number;
  minPetHeight: number;
  maxPetHeight: number;
  basicNumOfPets: number;
  minNumOfPets: number;
  maxNumOfPets: number;
  extraPetCharge: number;
  peakExtraPetCharge: number;
}

export type ISingleRefundPolicies = { rate: number; baseDay: number }[];
export interface IZoneDetail {
  id: string;
  name: string;
  type: string;
  brief: string; // 캠핑존 소개
  parkingType: string;
  floorType: string;
  siteLength: number;
  siteWidth: number;
  siteArea: number;
  camp: ICampDetail;
  numOfAdults: number;
  numOfMinors: number;
  numOfCars: number;
  numOfSites: number;
  numOfActiveSites: number; // 영업중인 사이트
  extraPeopleCharges: IExtraPeopleCharge;
  extraCarCharge: number;
  extraCarChargeType: TExtraCarChargeType;
  peakExtraCarCharge: number;
  thumbnail: IMedia;
  medias: IMedia[];
  availableDays: IAvailableDays;
  charges: ICharge;
  campnicCharges: ICharge;
  maxNumOfExtraAdults: number;
  maxNumOfExtraMinors: number;
  maxNumOfExtraPeople: number;
  price?: number;
  isPeopleLimitTotal: boolean;
  numOfPeople: number;
  mapPicture: IMap;
  checkInTime: string;
  checkoutTime: string;
  isUnavailable: boolean;
  minBookableDays: number; // 최소 예약 가능 박수
  maxBookableDays: number;
  unavailableReason: string;
  autoPriorityChangeDayLeft: number;
  autoPriorityChangeHour: number;
  isAutoPriorityChangeActive: boolean;
  isSingleDayUnavailable: boolean;
  schedules: ITimestamp[];
  longTermAvailablePeriods: IPeriod[];
  canLongTermStay: boolean;
  canCampnic: boolean;
  campnicCheckoutTime: string;
  manualBookables: ITimestamp[];
  isCarChargeOnBooking: boolean; // true면 추가 주차요금이 현장결제가 아닌 예약시 결제
  isCarChargeOnce: boolean; // true면 추가 주차요금에 박수를 곱하는게 아니라 아니라 박수랑 상관없이 요금 적용
  isCampingCarAvailable: boolean; // true면 캠핑카 주차 가능
  campingCarCharge: number; // 캠핑카 비성수기 요금
  peakCampingCarCharge: number; // 캠핑카 (극)성수기 요금
  isTrailerAvailable: boolean; // true면 트레일러 주차 가능
  trailerCharge: number; // 트레일러 비성수기 요금
  peakTrailerCharge: number; // 트레일러 (극)성수기 요금
  multiPeriods: IPeriod[];
  introduction: string; // 한줄소개
  tempCharges: ITempCharge[];
  canBookJoint: boolean; // 낀 1박 가능여부

  // 반려동물 관련 정보
  canBringPet: boolean; // 반려동반 가능 여부
  hasRestrictedPets: boolean; // 반려견종 제한 여부
  restrictedPets: string[]; // 제한 견종명 Array
  minPetWeight: number; // 동반 가능한 최소 반려견 몸무게 (kg)
  maxPetWeight: number; // 동반 가능한 최대 반려견 몸무게 (kg)
  minPetHeight: number; // 동반 가능한 최소 반려견 키(cm)
  maxPetHeight: number; // 동반 가능한 최대 반려견 키(cm)
  basicNumOfPets: number; // 기본 요금에 포함된 반려견 수
  minNumOfPets: number; // 최소한 데려와야 하는 반려견수 (반려견을 안데려오면 안되는 캠핑장도 있음)/ 0 : 필수 아님, 1 : 필수, 2: 기타 사유 (추후 추가예정)
  maxNumOfPets: number; // 동반 가능한 최대 반려견수
  extraPetCharge: number; // 비성수기 추가 반려견 비용 (1마리 1박당 요금)
  peakExtraPetCharge: number; // (극)성수기 추가 반려견 비용 (1마리 1박당 요금)
  isPetChargeOnce: boolean; // 반려동물 요금 1박당 부과 or 1회 부과

  // 셀프 체크인
  canSelfCheckIn: boolean;
  selfCheckInQuestions: ISelfCheckInQuestion[];
  // 플랜 여부
  isOnPlan: boolean;
  singleRefundPolicies?: ISingleRefundPolicies;

  longTerm: {
    checkInTime?: string;
    checkoutTime?: string;
    price?: number;
    isAvailable: boolean;
    isUnavailable: boolean;
    originalPrice?: number;
    minBookingPeriod?: number;
  };
}

export interface ITempCharge {
  startTimestamp: number;
  endTimestamp: number;
  single: number;
  peakSingle: number;
  superPeakSingle: number;
  multiple: number;
  peakMultiple: number;
  superPeakMultiple: number;
  applyDays: IApplyDays;
  isDailyCharge: boolean;
}

export interface IApplyDays {
  beforeHoliday: boolean;
  fri: boolean;
  mon: boolean;
  onHoliday: boolean;
  sat: boolean;
  sun: boolean;
  thu: boolean;
  tue: boolean;
  wed: boolean;
}

export type TExtraCarChargeType = 'charge' | 'free' | 'unavailable';
export interface IAdditionalOpt {
  id: string;
  price: number;
  peakPrice: number;
  superPeakPrice: number;
  amountLimit: number;
  type: {
    name: TAdditionalOptions[keyof TAdditionalOptions];
    value: keyof TAdditionalOptions;
  };
  name: string;
  hasDailyLimit: boolean; // (재고 기능 사용 여부)
  shouldMultiplyDay: boolean; // (숙박일수만큼 곱해지는지 여부)
  dailyLimit: number; // (재고수량)
  unit: { name: string; value: string };
}

export interface ICharge {
  normal: IChargeByDate;
  peak: IChargeByDate;
  superPeak: IChargeByDate;
}

export interface ICampnicCharge {
  normal: WeekType;
  peak: WeekType;
  superPeak: WeekType;
}

export interface WeekType {
  weekday: number;
  weekend: number;
}

export interface IChargeByDate {
  weekday: IPricePerNight;
  weekend: IPricePerNight;
}

export interface IPricePerNight {
  single: number;
  multiple: number;
}

export interface IExtraPeopleCharge {
  adult: IExtraCharge;
  child: IExtraCharge;
  teen: IExtraCharge;
}

export interface IExtraCharge {
  normal: number;
  peak: number;
  superPeak: number;
}

export interface IAvailableDays {
  availability: IADay;
  campnic: IADay;
  single: IADay;
  max: IMaxDay;
}

export interface IADay {
  mon: boolean;
  tue: boolean;
  wed: boolean;
  thu: boolean;
  fri: boolean;
  sat: boolean;
  sun: boolean;
  holiday: boolean;
}

export interface IMaxDay {
  mon: number;
  tue: number;
  wed: number;
  thu: number;
  fri: number;
  sat: number;
  sun: number;
  holiday: number;
}

export interface IRecentCamp {
  id: string;
  name: string;
  thumbnail: IMedia;
  date: string;
}

export interface IServiceBrief {
  id: string;
  quantity: number;
}

export interface IBookingDate {
  year: number;
  month: number;
  day: number;
}

export interface IBookingInfo {
  bank: string;
  accountHolder: string;
  accountNumber: string;
  depositorName: string;
  accommodationCharge: number;
  bookedTimestamp: number;
  bookingServices: IService[];
  campName: string;
  checkInTimestamp: number;
  checkoutTimestamp: number;
  cancelledTimestamp: number;
  code: string;
  contact: string;
  couponDiscount: number;
  calculateLogs: ICalculateLog[];
  expireTimestamp: number;
  hostMemo: string;
  id: string;
  name: string;
  numOfAdults: number;
  numOfCars: number;
  numOfChildren: number;
  numOfTeens: number;
  onBookingCharge: number;
  onSiteCharge: number;
  paymentMethod: string;
  paymentTimestamp: number;
  siteName: string;
  status: string;
  totalCharge: number;
  zoneName: string;
  cancelMessage: string;
  isHostCancel: boolean;
  request: string;
  zone: IZoneDetail;
  policies?: IRefundPolicies;
  message?: string;
  isExpired: boolean;
  refundAccountHolder?: string;
  refundAccountNumber?: string;
  refundBank?: string;
  refundPercent?: number;
  refundedAmount?: number;
  review: string;
  isPendingCancel?: boolean;
  cancelDayDiff?: number;
  carNumbers?: string;
  camp: ICampDetail;
  needLogin?: boolean;
  isFullRefundTime?: boolean;
  isUsingAutoBanking: boolean;
  refundActualExpense: number; // 환불 수수료
  chargeActualExpenseEveryRefund: boolean; // true이면 모든 취소건에 대해 환불 수수료 공제, false면 100% 환불건에만 공제
  expireCount: number;
  hasTrailer: boolean;
  hasCampingCar: boolean;
  extendableDays: IExtendableDays;
  camperExpireTimestamp: number;
  isCancelledAndRefunded: boolean;
  banners: IBanner[];
  frontBanners: IFeedBanner[];
  user?: string;
  pets: IPet[];
  actualCheckInTimestamp: number;
  canSelfCheckIn: boolean;
  hasReviewCoupon: boolean;
  coupon: {
    id: string;
    name: string;
    isDiscountPrice: boolean;
    discountPrice: number;
    discountPercent: number;
  };
  isOnPlan?: boolean;
  singleRefundPolicies: ISingleRefundPolicies;

  site: {
    id: string;
  };
  services: IServiceBrief[];
  onBookingServices: IService[];
  onSiteServices: IService[];
  campingCarCharge: number;
  extraCarCharge: number;
  isCarChargeOnBooking: boolean;
  servicePrice: number;
  trailerCharge: number;
  basicAccommodationCharge: number;
  extraPeopleCharge: number;
  email: string;
  customerKey: string;
  extraPetCharge: number;
  isBankPaymentAvailable: boolean;
  isOnlinePaymentAvailable: boolean;
  hash: string;

  paymentKey?: string;
  isLongTerm?: boolean;
  calculations?: any[];
}

export interface IExtendableDays {
  pre: number; // 예약 전으로 연장할 수 있는 일수
  post: number; // 예약 후로 연장할 수 있는 일수
  needToShow: boolean; // true면 전,후 연장 가능일수 보여주기
}

export interface ICalculateLog {
  isAccommodation: boolean;
  isChangeCharge: boolean;
  isOnSitePayment: boolean;
  isExtraPeopleCharge: boolean;
  charge: number;
  numbers: INumbers;
  isCoupon: boolean;
  startTimestamp: number;
  endTimestamp: number;
  type: string;
  coupon?: { id: string; masterCode: string; name: string };
  reason?: string;
}

export interface INumbers {
  adult: number | null;
  teen: number | null;
  child: number | null;
  car: number | null;
  quantity: number;
}

export interface IBooking {
  accommodationCharge: number;
  name: string;
  onBookingCharge: number;
  onBookingServices: IService[];
  onSiteCharge: number;
  onSiteServices: IService[];
  phone: string;
  servicePrice: number;
  status: string;
  totalCharge: number;
  extraCarCharge: number;
  isBankPaymentAvailable: boolean;
  isOnlinePaymentAvailable: boolean;
  basicAccommodationCharge: number; // 기본 숙박요금 (초과인원 제외)
  extraPeopleCharge: number; // 초과인원 요금
  isCarChargeOnBooking: boolean;
  campingCarCharge: number;
  trailerCharge: number;
  extraPetCharge: number; // 반려동물 추가 요금
  email: string; // 유저 이메일
  customerKey: string; // 내 상점에서 고객을 구분하기 위해 발급한 고객의 고유 ID
  calculationLogs?: any[];
}

export interface ISessionStorageBookingData {
  campId: string;
  zoneId: string;
  start: Date;
  end: Date;
}

export interface IService {
  service: IServiceType;
  name: string;
  isOnSitePayment: boolean;
  quantity: number;
  unitPrice: number;
  totalPrice: number;
  dailyBookingServices: { quantity: number; useTimestamp: number }[];
}

export interface IServiceType {
  id: string;
  type: string;
}
export interface ILocationTable {
  category: string;
  items: ILocationItem[];
}

export interface ILocationItem {
  city: ICity;
  subCity: ICity[];
}

export interface ICity {
  name: string;
  count?: number;
  search: string;
}

export interface ICancelCharge {
  refundAmount: number;
  calculations: ICalculation[];
  dayDiff: number;
  isFullRefundTime: boolean;
  refundPercent: number;
  isOnPlan: boolean;
  isLongTerm?: boolean;
  calculateLogs?: any[];
  onBookingCharge?: number;
}
export interface ICalculation {
  reason: string; // 숙박요금, 추가옵션명 등
  charge: number; // 결제한 금액
  refundAmount: number; // 환불될 금액
  refundPercent: number; // 환불비율
  isActualExpense: boolean;
  isAccommodation: boolean;
  couponDiscount: number;
  feeAmount: number;
}

export interface IMyReservation {
  id: string;
  status: string;
  code: string;
  checkInTimestamp: number;
  checkoutTimestamp: number;
  camp: ICampDetail;
  zoneName: string;
  siteName: string;
  name: string;
  paymentMethod: string;
  totalCharge: number;
  createdAt: string;
  bookedTimestamp: number;
  expireTimestamp: number;
  review: string;
  numOfAdults: number;
  numOfChildren: number;
  numOfTeens: number;
  isCancelledAndRefunded: boolean;
  isRePayment: boolean;
}

export interface IAnnounce {
  code: string;
  content: string;
  id: string;
  numOfViewed: number;
  title: string;
  displayAfterTimestamp: number;
}

export interface ICoupon {
  id: string;
  name: string;
  target: string; // 발급 대상 ('all' 전체 | 'zoneType' 캠핑존 타입(카라반, 펜션 등) | 'zone' 특정존(targetCodes는 적용가능한 존 코드) | 'camp' 특정 캠핑장(targetCodes는 적용 가능한 캠핑장 코드) | 'exhibition' 특정 기획전 (targetCodes 봐야됨))
  targetNames: ICouponTargetName[]; // target이 특정 존,캠핑장,기획전인 경우 해당 존,캠핑장,기획전 이름
  minApplyPrice: number; // 쿠폰 적용이 가능한 최소 가격 (예, 2만원 이상 구매시 적용 가능 쿠폰이면 20000)
  zoneTypes: string; // target이 zoneType인 경우 쿠폰 적용이 가능한 존 타입
  isDiscountPrice: boolean; // true면 할인되는 금액이 정액, false면 정률
  discountPrice: number; // 할인되는 금액
  discountPercent: number; // 할인되는 비율
  activeTimestamp: number; // 사용 시작일
  expireTimestamp: number; // 사용 만료일 (편집됨)
  maxDiscountAmount: number | null; // 최대 할인금액
  isAvailable: boolean;
  usageExpireTimestamp: number;

  availableZoneTypes?: TavailableZoneType[];
}

export interface ISelectiveCoupon extends ICoupon {
  isSelectable?: boolean;
}

export interface ICouponTargetName {
  camp: string;
  zone: string;
}

export interface IProfile {
  id?: string;
  thumbnail: IMedia;
  nickname: string;
  brief: string;
  instagram: string;
  blog: string;
  boardCount: number;
  reviewCount: number;
  equipments: IEquipment[];
  isUserBlocked: boolean;
  youtube: string;
}

export interface IPet {
  name: string;
  breed: string;
  weight: number;
  height: number;
}

export interface IReservationPet extends IPet {
  petInfoId: number;
  isRegistered?: boolean;
  isRestricted?: boolean;
  isBringPet?: boolean;
}

export interface IMyInfo extends IProfile {
  email: string;
  id: string;
  phone: string;
  username: string;
  numOfUnwrittenReviews: number; // 작성가능한 캠핑로그 수
  numOfCoupons: number; // 보유한 쿠폰 갯수
  numOfNewAlarms: number; // 안읽은 알림 갯수
  numOfBookings: number; // 결제대기 + 예약완료 수
  numOfVacancyAlarms: number; // 구독중인 빈자리 수
  numOfBookmarks: number; // 찜한 캠핑장 수
  numOfReviews: number; // 작성한 캠핑로그 수
  name: string;
  marketingAgreement: boolean; // 마케팅 수신 동의 여부
  marketingAgreementTimestamp: number; // 마케팅 수신 동의 여부 최종 수정일 ( 0인 경우 회원가입 후 미변경)
  recentActivities: IRecentActivity[];
  equipments: IEquipment[];
  thumbnail: IMedia;
  brief: string;
  carNumber: string;
  pets: IPet[];
  isCertified: boolean;
}

export interface ISellerInfo {
  id: string;
  vendor: {
    name: string;
  };

  thumbnails: IMedia[];
  normalPrice: number;

  isOnDiscount: boolean;
  discountPrice: number;

  hasDiscountPeriod: boolean;
  discountStartTimestamp: number;
  discountEndTimestamp: number;

  stock: number;

  hasOption: boolean;
  optionType: 'standalone' | 'combination' | 'input'; // 단독형 | 조합형 | 직접입력형
  optionTable: any; // [option];

  deliveryChargeType: 'free' | 'conditional' | 'charge'; // 무료 | 조건부 무료 | 유료
  deliveryFee: number;
  freeDeliveryConditionPrice: number;
}

export interface IEquipment {
  id: string;
  type: string;
  medias: IMedia[];
}

export interface IRecentActivity {
  id: string;
  medias: IMedia[];
  timestamp: number;
  type: 'review' | 'board';
}
export interface IBoard {
  id: string; // 게시글 ID
  user: IUser;
  title: string;
  brief: string;
  boardType: TSubjectType;
  mainTopic: TMainTopicType;
  medias: IMedia[];
  text: string;
  numOfViews: number;
  numOfLikes: number;
  numOfComments: number;
  hasLiked: boolean;
  lastModifiedTimestamp: number; // 수정한 경우 마지막 수정일자
  registerTimestamp: number;
  isBlocked: boolean;
  isUserBlocked: boolean;
  product: IProduct;
  topic: TTopic;
  isOwnerOnly: boolean;
}

export interface IToggleLikeBtnResponse extends IResponse {
  id: string;
  type: TBoardType;
  hasLiked: boolean;
  numOfLikes: number;
}

export interface ISelfAuthenticationResultResponse extends IResponse {
  certificationId: string;
}

export type TSubjectType = keyof typeof subjectTypes;
export type TSubjectValue = typeof subjectTypes[TSubjectType];

export type TBoardType = 'board' | 'comment' | 'thread';

export interface IComment {
  id: string;
  user: IUser;
  medias: IMedia[];
  text: string;
  numOfLikes: number;
  numOfThreads: number;
  hasLiked: boolean;
  isAuthor: boolean; // 게시글 작성자 여부
  headThreads: IThread[]; // 첫 대댓글 2개
  lastModifiedTimestamp: number; // 수정한 경우 마지막 수정일
  registerTimestamp: number; // 등록일
  isBlocked?: boolean;
  isUserBlocked?: boolean;
  isOwner?: boolean;
  isBest?: boolean;
  mentionedUserNickname?: string;
}

export interface IMyComment {
  id: string;
  type: 'comment' | 'thread';
  boardId: string | null;
  commentId: string | null;
  medias: IMedia[];
  text: string;
  parentText: string;
  numOfLikes: number;
  numOfThreads: number | undefined; // (type === 'thread'일 때)
  lastModifiedTimestamp: number;
  registerTimestamp: number;
  post: string;
}

export interface IThread extends IComment {
  commentId: string;
}

export interface IWriteCommentsResponse extends IResponse {
  comment: IComment;
  thread: IThread;
}

export type TSort = keyof typeof sortType;

export type TAvailableSites = {
  id: string;
  _id: string;
  name: string;
};
export interface IGetSitesAvailableForOneNightResponse extends IResponse {
  siteNames: string[];
  availableSites: TAvailableSites[];
}

export interface IReviseCampingLogResponse extends IResponse {
  reviewId: string;
}

export type TReportType = keyof typeof reportConstants;

export interface IMyAlarm {
  id: string;
  title: string;
  text: string;
  eventType:
    | 'newComment'
    | 'newThread'
    | 'vacancyAlarm'
    | 'commentReaction'
    | 'postReaction'
    | 'postBookmark';
  category: 'campSang' | 'vacancyAlarm';
  redirectUrl?: string;
  targetId: string; // newComment나 newThread면 commentId | vacancyAlarm이면 zoneId
  extraId: string; // newComment나 newThread면 boardId | vacancyAlarm이면 campId
  number: number; // 누적 알림 수 (댓글 여러개 달릴 동안 확인 안하면 수량 쌓임)
  isViewed: boolean; // 알림 확인 했는지 안했는지
  timestamp: number; // 마지막 업데이트 시간
  additionalQuery: string;
}

export interface IOpenSchedules {
  timestamp: number; // 수집 된 시간 ( 우측 상단 MM/dd HH:mm 기준 에 쓰는 용도 ),
  data: ICampOpenSchedule[];
}

export interface ICampOpenSchedule {
  campId: string;
  campName: string;
  campThumbnail: IMedia;
  city: string;
  major: string;
  openTimestamp: number; // (오픈하는 시간, '8/31 오후 04:00시 오픈'  )
  bookableUntilTimestamp: number; // (오픈 되는 기간, '10/31일까지 오픈' )
  grade: number | null;
}

export interface IVacancyAlarm {
  id: string;
  startTimestamp: number;
  endTimestamp: number;
  camp: {
    id: string;
    name: string;
    medias: IMedia[];
  };
  zone: { id: string; medias: IMedia[]; name: string };
}

export interface IBookResponse extends IResponse {
  bankInformation: IBankInfo | null;
  bookingId: string;
  needOnlinePayment: boolean;
  onBookingCharge: number;
  payment: string;
  token: string;
  isBlacklist: boolean;
  coupon?: string;
  couponDiscount?: number;
}

export interface IBankInfo {
  bank: string;
  amount: number;
  accountHolder: string;
  accountNumber: string;
  depositorName: string;
}

export interface IBookingResultFailureResponse extends IResponse {
  needLogin: boolean;
}
export interface IBestPhoto {
  nickname: string;
  media: IMedia;
  redirectPath: string;
  thumbnail: IMedia;
}

export interface ICampAnnounce {
  id: string;
  campName: string; // 캠핑장명
  title: string; // 공지 제목
  isMain: boolean; // 메인 공지 여부
  numOfViewed: number; // 조회수
  timestamp: number; // 공지 등록일
  type: 'none' | 'event' | 'mustRead';
}

export interface ISingleCampAnnounce {
  id: string;
  camp: ICamp;
  content: string;
  title: string; // 공지 제목
  isMain: boolean; // 메인 공지 여부
  numOfViewed: number; // 조회수
  timestamp: number; // 공지 등록일
  type: 'none' | 'event' | 'mustRead';
}

export interface IZoneReservationStatus {
  timestamp: number;
  data: IAvailableSiteByDate[];
  status: string;
}

export interface IAvailableSiteByDate {
  date: number; // 일자 (1,2,3, ...)
  holiday: string | null; // null이 아닌 경우에는 공휴일명 (크리스마스, 추석 등등)
  sites: {
    name: string; // 사이트명
    isAvailable: boolean; // 가능한지 여부
  }[];
}

export interface ICheckPaymentResponse extends IResponse {
  token: string;
  bookingId: string;
}

export interface IGetNonMemberBookingToken extends IResponse {
  bookingId: string;
  token: string;
}

export interface IArticle {
  id: string; // 아티클 ID
  user: IUser;
  thumbnail: IMedia;
  articleType: TArticleType;
  title: string;
  subtitle: string;
  numOfViews: number; // 조회수
  registerTimestamp: number;
}

export type TArticleType = keyof typeof articleTypes;

export interface ISingleArticle extends IArticle {
  numOfCards: number; // 전체 카드 갯수
  headingCards: IArticleContentCard[];
  lastUpdatedTimestamp: number; // 최종 수정일
}

export interface IArticleContentCard {
  id: string; // 카드 ID
  type: TArticleContentType;
  medias: IMedia[];
  youtubeLink: string; // 있는 경우에만 유튜브 영상 임베드
  text: string; // 제목, 소제목, 내용 텍스트
  caption: string;
  tags: ITag[];
}

export type TArticleContentType = 'heading' | 'subheading' | 'content'; // 제목 | 소제목 | 내용

export interface IChangableOnSiteService {
  periods: IReservationDateInfo[];
  services: (IOnSiteService & IAdditionalOpt)[];
}

export interface IReservationDateInfo {
  period: TSeason;
  timestamp: number;
}

export interface IOnSiteService {
  quantity: number; // 이전에 이미 예약한 갯수
  // 재고기능 사용하는 옵션만 들어옴
  dailyUsages: {
    useTimestamp: number; // 사용일
    dailyQuantity: number; // 이전에 예약한 갯수
    limit: number; // 최대 추가 가능 수량 (타 예약건 모두 고려된 값)
  }[];
}

export type TSeason = 'normal' | 'peak' | 'superPeak';

export interface IAppPushStatus extends IResponse {
  isAdDenied: boolean;
}

export interface IToggleMarketingAgreementResponse extends IResponse {
  marketingAgreement: boolean;
  marketingAgreementTimestamp: number;
}

export interface IPetsResponse extends IResponse {
  pets: IPet[];
}
export interface ICarNumberResponse {
  carNumber: string;
  status: string;
}

export interface IRegisterdAdditionalUserInfoResponse {
  carNumber: string;
  pets: IPet[];
  status: string;
  message?: string;
}

export type IMyReservationStatus = keyof typeof myReservationListLnb;

export interface IProduct {
  id: string;
  category: IProductCategory;
  brand: IProductBrand & { thumbnailUrl?: string };
  status: 'placed' | 'linked' | 'examed' | 'toExam'; // toExam(검수전)이면 creatorInfo 봐야 함
  name: string; // 상품명
  thumbnails: IMedia[];
  thumbnailUrl?: string;
  createrInfo: IProductCreaterInfo;
  averageRate: number;
  normalPrice?: number;
  priceStartFrom: number;
  majorCategory: IMajorCategory;
}

export interface IRelatedProduct {
  id: string; // relatedProductId, 추후 연관상품 클릭 추적용
  product: IProduct;
}

export interface IRelatedProductListProps {
  totalCount: number;
  relatedProductList: IRelatedProduct[];
  currentProductList: IProduct[];
  moreButtonClick: () => void;
  isLoading: boolean;
  SIX: number;
  hasMoreProduct: boolean;
}

export interface IRecommendProductListProps {
  isLoading: boolean;
  recommendProductList: IProduct[];
}

export interface IMajorCategory {
  id: string;
  name: string;
  icon: IMedia;
}

export interface IProductCreaterInfo {
  // toExam인 경우만 사용
  brand: string; // 유저가 등록한 브랜드
  name: string; // 유저가 등록한 상품명
  vendor: string; // 유저가 등록한 판매처
}

export interface IProductBrand {
  id: string;
  name: string;
  thumbnail: IMedia;
}

export interface IProductCategory {
  id: string;
  major: string;
  medium: string | null;
  minor: string | null;
  hierarchy: 'major' | 'medium' | 'minor';
}

export interface IRegisterProductResponse extends IResponse {
  product: {
    id: string;
    status: 'toExam';
    thumbnails: IMedia[];
    createrInfo: IProductCreaterInfo;
  };
}

export interface ICategoryName {
  id: string;
  name: string;
  major: string;
  medium: string;
  minor: string;
  hierarchy: 'major' | 'medium' | 'minor';
}

export interface ICategoryQuestion {
  id: string; // questionId
  title: string; // 질문
  hint: string | null; // 질문 상세설명
  answer1: string;
  answer2: string;
  answer3: string;
}

export interface IMyItem {
  id: string;
  product: IProduct;
  type: string; // 텐트, 의자 등등
  text: string; // 본문 내용
  pros: string;
  cons: string;
  tip: string;
  medias: IMedia[];
  registerTimestamp: number;
  tagPositionX: number;
  tagPositionY: number;
  answers: IAnswer[];
  rate: number;
  user?: IUser;
  isMine?: boolean; // true면 본인 장비
  isMyEquipment: boolean; // true 실사용자
  isUserBlocked: boolean;
  isHidden: boolean;
}

export interface IWritingReview {
  text: string; // 본문 내용
  pros: string;
  cons: string;
  tip: string;
  imageWidth: number;
  tagPositionX: number;
  tagPositionY: number;
  selectedAnswers: ISelectedAnswer[];
  uploadedImages: (File | IMedia)[];
  activeStarCnt: number;
  isWriting: boolean;
}
export interface IReview {
  id: string;
  text: string; // 본문 내용
  pros: string;
  cons: string;
  tip: string;
  tagPositionX: number;
  tagPositionY: number;
  selectedAnswers: ISelectedAnswer[];
  medias: IMedia[];
  rate: number;
  user: IUser;
  registerTimestamp: number;
}

export interface IAnswer {
  questionId: string;
  title: string;
  hint: string;
  answer1: string;
  answer2: string;
  answer3: string;
  answerIndex: number; // 유저가 선택한 답변 인덱스 1~3, 없으면 선택 안한거
}

export interface IPagination {
  wholeCount?: number;
  skip: number;
  limit: number;
}

export type TCategoryFieldsKey = typeof fields[number]['value'];

export type TCategoryFieldsValue = {
  [key in TCategoryFieldsKey]: number | string | boolean;
};

export interface ICategoryQuestionInStatistics {
  answer1: string;
  answer2: string;
  answer3: string;
  category: IProductCategory & {
    _id: string;
    categoryFields: string[]; // (유저에게 보여줄 field value; 전 DM의 fields 참고)
    icon: IMedia;
  };
  title: string; // 질문
  hint: string | null; // 질문 상세설명
  id: string;
  isPaused: boolean;
  _id: string;
}

export interface IProductStatistic {
  answerRate1: number; // 답변1 응답자 퍼센트
  answerRate2: number; // 답변2
  answerRate3: number; // 답변3
  categoryQuestion: ICategoryQuestionInStatistics;
  id: string;
  numOfAnswer1: number; // 답변1 응답자 수
  numOfAnswer2: number; // 답변2 응답자 수
  numOfAnswer3: number; // 답변3 응답자 수
  numOfAnswers: number; // 전체 응답자 수
}

export interface IProductReviewStatistics {
  numOfReviews?: number;
  averageRate: number | null;
  numOfRate5: number | null;
  numOfRate4: number | null;
  numOfRate3: number | null;
  numOfRate2: number | null;
  numOfRate1: number | null;
  statistics: IProductStatistic[];
  status: 'fail' | 'placed' | 'linked' | 'examed'; // toExam(검수전)은 상품 상세 페이지가 없으므로 status를 fail로 return함
}

export type TChangableProductDetail = {
  id: string;

  name: string;
  thumbnails: IMedia[];
  medias: IMedia[];
};

export interface IProductDetail extends TChangableProductDetail {
  brand: IProductBrand & {
    brief: string;
    banner: IMedia;
  };
  category: IProductCategory & {
    categoryFields: string[]; // (유저에게 보여줄 field value; 전 DM의 fields 참고)
  };
  status: 'fail' | 'placed' | 'linked' | 'examed'; // toExam(검수전)은 상품 상세 페이지가 없으므로 status를 fail로 return함
  introduction: string; // 상품 소개글. 글자 크기 / 색상 / 이미지 등 여러가지가 들어갈 수도 있어서 html 형태임
  majorCategoryId: string;
  mediumCategoryId: string;
  minorCategoryId?: string;
  purchaseLink: string; // 상품 구매 URL; status === 'linked' 인 경우에만 구매 사이트 링크를 위해 필요
  priceStartFrom: number | null; // 상품가
  normalPrice?: number | null;
  averageRate: number | null; // 평균 별점
  numOfRate1: number;
  numOfRate2: number;
  numOfRate3: number;
  numOfRate4: number;
  numOfRate5: number;

  numOfRatedReviews: number;
  statistics: IProductStatistic[];

  numOfReviews: number; // 리뷰 갯수
  sampleReviews: IReview[]; // 샘플 리뷰 3개
  numOfQuestions: number; // 질문 갯수
  sampleQuestions: any[]; // 샘플 질문 3개
  numOfReviewMedias: number; // 전체 리뷰의 사진 갯수
  sampleReviewMedias: IPagingMedia[];
  primeItem: IItemDetail | null; // 현재 상품 판매자 중에서 가장 저렴한 상품 판매자의 상품
  numOfItems: number; // 해당 상품 모든 판매자 수
}

export type TProductDeliveryChargeType = 'free' | 'conditional' | 'charge';

export type TProductDetail = IProductDetail & TCategoryFieldsValue;

export type TTopic = typeof topics[number];

export interface IProductQuestion extends IBoard {
  repliedUsers: IUser[];
}

export interface ICheckProductResponse extends IResponse {
  isRegistered: boolean;
  isMyEquipment: boolean;
  equipmentId: string;
  product?: IProduct;
}

export interface IProductMainBanners {
  mainBanners: IBanner[];
  subBanners: IBanner[];
}

export interface IProductExhibition extends IExhibition {
  subMedia: IMedia; // 1:1 사진
  numOfProducts: number; // 기획전에 등록된 상품 수
  products: IProduct[];
}
export interface ITag {
  id: string;
  positionX: number;
  positionY: number;
  product: IProduct;
}

export interface IProductMainCategoryData {
  // 카테고리 정보
  parentCategories: {
    // 상위 & 현재 카테고리 -> 뒤로 돌아가기 용도
    major: ICategoryName;
    medium: ICategoryName;
    minor: ICategoryName;
  };
  currentCategory: ICategoryName; // 현재 카테고리
  categorySelections: (ICategoryName & { icon: IMedia })[]; // 현재 페이지에서 선택할 수 있는 카테고리 목록

  // 상단 배너 정보
  banners: IBanner[]; // 비었으면 배너 area 미노출

  // 기획전 정보
  exhibitions: IProductExhibition[];

  // Note: api V1 -> V2 변경되면서 하단의 type 제거.
  // 아티클 부분 정보
  articleTitle: string; // 예) 텐트 가이드
  articleType: TArticleType; // 더보기 redirect 용도
  articleTopic: string; // 더보기 redirect 용도
  articles: IArticle[]; // 비었으면 아티클 area 미노출

  // 상단 노출 상품 정보
  topProductTitle: string; // 예) MD Pick, 오늘의 특가상품 // 참고로 null | undefined |  이면 title area 미노출
  topProducts: IProduct[]; // 비었으면 상단 노출 상품 area 미노출

  // 캠핑생활 부분 정보
  boardTitle: string; // 예) 텐트 궁금해요
  boardType: 'live' | 'diary' | 'question'; // 더보기 redirect 용도
  productType: TProductType; // 더보기 redirect 용도
  boards: IBoard[]; // 비었으면 캠핑생활 area 미노출

  // 필터링 & 정렬 부분 -> 추후 구현 예정
  availableFilters: [];
  availableSorters: [];
}

export interface ISearchedProducts {
  numOfProducts: number; // 검색된 상품수
  products: IProduct[]; // *주의* 네이버 클라우드 사용으로 product array를 바로 주는게 아니라 products 안에 들어가게 됨
}

export type TProductSortType = keyof typeof productSortConstants;

export interface IProductSearchData {
  categories: IProductCategory[];
  keywords: string[];
  banners: IBanner[];
}

export type TProductType = typeof items[number]['type'];

export type TMainTopicType = typeof communityQuestionTopics[number];

export type TCategory = Omit<typeof productCategory[number], 'blank'>;
export type TCuration = {
  id: string;
  imgSrc: string;
  label: string;
  path: string;
  badge?: string;
};
export interface IRealtimeReservationData {
  recommendKeywords: IRecommendKeywords[];
  banners: IBanner[];
  exhibitions: ICampExhibition[];
}
export interface IPostPhoneAuth {
  name: string; // 예약자명
  phone: string;
}

export interface IPostPhoneAuthResponse extends IResponse {
  expireTimestamp: number; // 휴대폰 인증 만료 기한
  verificationId: string;
}

export interface IGetNonReservList {
  code: string;
  verificationId: string;
}

export interface IGetNonReservListResponse extends IResponse {
  needRedirect: boolean;
  bookings: IGetNonReservBooking[];
}

export interface IGetNonReservBooking {
  id: string;
  code: string; // 예약번호
  campName: string;
  zoneName: string;
  siteName: string;
  bookedTimestamp: number;
  checkInTimestamp: number;
  checkoutTimestamp: number;
  expireTimestamp: number;
  status: 'pending' | 'paid' | 'cancelled';
  numOfAdults: number;
  numOfTeens: number;
  numOfChildren: number;
  token: string; // 비회원 예약 조회 시 아무나 조회할 수 없게 하기 위한 토큰
  isCancelledAndRefunded: boolean;
  camp: {
    id: string;
    thumbnail: IMedia;
  };
  isRePayment: boolean;
  paymentMethod: 'online' | 'bank';
}

export interface IGtmBasicEvent {
  event?: string;
}
export interface IGtmDataLayer extends IGtmBasicEvent {
  depth_category?: string;
  brand?: string;
  update_name?: string;
  search_keywords?: string;
  search_result?: boolean;
  real_user?: boolean;
  question_type?: string;
  payment_type?: string;
  question_product?: string;
  ecommerce?: {} | null;
  camping_name?: string;
  camping_site?: string;
  camping_members?: string;
  payments_type?: string;
  members_type?: string;
}

export type TCouponIssuance =
  | '직접 발급'
  | '유저 다운'
  | '후기 작성'
  | '코드 입력';

export interface ICouponGtmDataLayer extends IGtmBasicEvent {
  coupon_camp: string;
  coupon_name: string;
  coupon_type: string;
  coupon_code: string;
  coupon_issuance: TCouponIssuance;
  coupon_price: string;
}

export interface IAlgoliaGtmDataLayer {
  algoliaUserToken: string | null;
}

export interface IUserTrackingGtmDataLayer {
  user_id: string | undefined;
  crm_id: string | undefined;
}

export type TCampSortType = keyof typeof campSortConstants | undefined;

export type TUserEnv = '' | 'app' | 'mobile' | 'pc';

export interface IBookmark {
  isAppUser: boolean;
}

export interface ImageStyle {
  imageWidth: number;
  activated?: boolean;
  isClickable?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement | HTMLImageElement>;
}

export type TPlaceType =
  | { name: '이벤트'; value: 'event' }
  | { name: '캠핑장 검색창'; value: 'campSearch' }
  | { name: '예약 상세'; value: 'bookingDetail' }
  | { name: '장비 홈 메인'; value: 'product' }
  | { name: '장비 홈 서브'; value: 'productSub' }
  | { name: '장비 검색창'; value: 'productSearch' }
  | { name: '장비 카테고리'; value: 'productCategory' }
  | { name: '커뮤니티'; value: 'board' }
  | { name: '쇼핑'; value: 'shopping' }
  | { name: '마이'; value: 'myPage' }
  // 메인에서 원콜로 변경 예정
  | { name: '홈 배너'; value: 'main' }
  | { name: '인트로'; value: 'intro' };

export type ICommunityMainTypes = PropertyType<ICommunityMainPage>;

export interface ICommunityMainPage {
  bestPhotoTitle: string;
  bestPhotos: [
    // Best 캠핑 포토
    {
      nickname: string; // 유저 닉네임
      thumbnail: IMedia; // 유저 썸네일
      media: IMedia; // 사진
      redirectPath: string; // 클릭시 이동할 path
    },
  ];
  bestReviewTitle: string;
  bestReviews: [
    // 베스트 캠핑로그
    {
      nickname: string; // 유저 닉네임
      thumbnail: IMedia; // 유저 썸네일
      title: string; // 제목
      text: string; // 내용
      media: IMedia; // 사진
      redirectPath: string; // 클릭시 이동할 path
    },
  ];
  topBoardTitle: string;
  topBoards: [
    // 인기 게시글
    {
      id: string; // 게시글 아이디 (boardId)
      text: string; // 내용
      medias: IMedia[]; // 사진 Array
    },
  ];
  topPhotoTitle: string;
  topPhotos: [
    // 인기 사진
    {
      id: string; // 게시글 아이디 (boardId)
      nickname: string; // 유저 닉네임
      thumbnail: IMedia; // 유저 썸네일
      text: string; // 내용
      medias: IMedia[]; // 사진 Array
    },
  ];
  topQuestionTitle: string;
  topQuestions: [
    // 궁금해요
    {
      id: string; // 게시글 아이디 (boardId)
      text: string; // 내용
      medias: IMedia[]; // 사진 Array
    },
  ];
  articles: [IArticle]; // 캠핑 Magazine
}

export type TStoreValueState = '' | 'pending' | 'success' | 'error' | 'done';

export interface IMainResponsePayload {
  numOfAlarms?: number; // JWT 보내는 경우에만 존재

  mainBanners: IBanner[] | undefined; // 메인 최상단 배너
  subBanners: IBanner[]; // 메인 하단 광고 배너
  introBanners: IBanner[]; // 슬라이드업 모달 광고 배너

  reviews: ICampingLog[]; // 캠핑 log 목록
  article: IArticle; // 매거진

  camps: IMainNewCamp[]; // 신규 캠핑장 목록
  campExhibitions: ICampExhibition[]; // 캠핑 기획전
  additionalExhibitions: IAdditionalExhibitions[];
}

export type TDays =
  | 'mon'
  | 'tue'
  | 'wed'
  | 'thu'
  | 'fri'
  | 'sat'
  | 'sun'
  | 'onHoliday' // 공휴일 당일
  | 'beforeHoliday' // 공휴일 전날
  | 'onTheDay' // 당일예약
  | 'beforeTheDay'; // 전일예약

export type TCoupontTarget = 'camp' | 'zone' | 'all' | 'groups';

export type TavailableZoneType =
  | 'autoCamping'
  | 'glamping'
  | 'caravan'
  | 'pension'
  | 'bungalow'
  | 'carCamping';

export interface IV2Coupon {
  id: string; // couponId
  name: string; // 쿠폰명
  isIssued: boolean; // 유저가 이미 발급했는지 여부
  masterCode: string; // 마스터코드

  camp: {
    id: string;
    name: string;
    medias: IMedia[];
    thumbnail: IMedia;
  };

  target: TCoupontTarget; // camp면 전체 캠핑존에서 사용 가능, zone이면 일부 캠핑존만 사용 가능, all이면 전체 캠핑장에서 사용 가능
  zoneNames?: string[]; // target === 'zone'일 때만 보면 됨

  activeMinAfterIssue: number; // 발급 후 사용 가능한 기간 (분 단위)

  // 할인액 관련
  isDiscountPrice: boolean; // true면 정액할인, false면 정률할인
  discountPrice: number; // 정액할인인 경우 할인액
  discountPercent: number; // 정률할인인 경우 할인률
  maxDiscountAmount: number | null; // 정률할인인 경우 최대 할인금액 및 제한 없을 시

  // 적용 조건 관련
  minApplyPrice: number; // 최소 쿠폰 적용 금액

  minBookingDays: number; // 최소 예약 박수
  maxBookingDays: number; // 최대 예약 박수

  activeTimestamp: number; // 예약 가능 기간 시작
  expireTimestamp: number; // 예약 가능 기간 마감

  activeDays: TDays[]; // 가능한 입실 요일

  // 발급 수량 제한 관련
  maxNumToDailyIssue: number; // 일일 최대 발급 수량
  numOfDailyIssued: number; // 오늘 발급된 쿠폰 수량 (maxNumToDailyIssue === numOfDailyIssued 이면 오늘은 추가 발급 불가)

  maxNumToIssue: number; // 최대 발급 수량
  numOfIssued: number; // 전체 발급된 쿠폰 수량 (maxNumToIssue === numOfIssued 이면 추가 발급 불가)

  maxNumToIssueByUser: number; // 유저별 최대 발급 수량
  numOfUserIssued: number; // 유저가 이미 발급한 동일 쿠폰의 수량

  terms: IV2CouponTerms[];

  availableZoneTypes?: TavailableZoneType[];
  isRegisteredByManager: boolean; // true: 캠핏 발급, false: 캠지기 발급
}

export interface IV2CouponTerms {
  id: string;
  coupon: string;
  title: string;
  content: string;
}

export interface IDownloadableCoupon extends IV2Coupon {
  isDownloadable?: boolean;
}

export interface IV2MyCoupon {
  id: string; // userCouponId
  coupon: {
    id: string; // couponId
    masterCode: string; // 마스터코드
    name: string; // 쿠폰명
    target: TCoupontTarget; // camp면 전체 캠핑존에서 사용 가능, zone이면 일부 캠핑존만 사용 가능
    zoneNames: string[]; // target === 'zone'인 경우 쿠폰 사용가능한 존 이름
    // 할인액 관련
    isDiscountPrice: boolean; // true면 정액할인, false면 정률할인
    discountPrice: number; // 정액할인인 경우 할인액
    discountPercent: number; // 정률할인인 경우 할인률
    maxDiscountAmount: number | null; // 정률할인인 경우 최대 할인금액

    availableZoneTypes?: TavailableZoneType[];
    targetLink?: string; // 내 쿠폰에서 쉐브론 클릭시 기획전 등 다른 곳으로 이동을 위해 사용.
  };
  camp: {
    id: string;
    name: string;
    medias: IMedia[];
    thumbnail: IMedia;
  };
  // 적용 조건 관련
  minApplyPrice: number; // 최소 쿠폰 적용 금액

  minBookingDays: number; // 최소 예약 박수
  maxBookingDays: number; // 최대 예약 박수

  activeTimestamp: number; // 예약 가능 기간 시작
  expireTimestamp: number; // 예약 가능 기간 마감
  activeDays: TDays[]; // 가능한 입실 요일
  usageExpireTimestamp: number; // 쿠폰 사용 마감 기한
}

export interface IV2CouponIssueResponse extends IResponse {
  campName: string;
  couponId: string;
  couponName: string;
  couponTarget: 'camp' | 'zone';
  discountPercent: number;
  discountPrice: number;
  isDiscountPrice: boolean;
  masterCode: string;
  userCouponeId?: string;
}

export interface IV2CouponDetail {
  name: string;
  campName: string;
  target: string;
  zoneNames: string[];
  minApplyPrice: number;
  conditions: {
    expirationPeriod?: string;
    checkinPeriod: string;
    checkinDays: string;
    numOfDaysLimit: string;
    maxDiscountAmount: string;
    masterCode: string;
  };

  availableZoneTypes?: TavailableZoneType[];
}

export interface IUserTrackingInfo {
  trackingId: string;

  // 채널톡 정보
  channelTalk: {
    memberId: string;
    profile: {
      name: string;
      username: string;
      mobileNumber: string;
      landlineNumber?: string;
    };
  };
}

export interface IAlgoliaProduct {
  objectID: string;
  name: string;
  brandName: string;
  normalPrice: number;
  priceStartFrom: number;
  majorCategoryName: string;
  mediumCategoryName: string | null;
  minorCategoryName: string | null;
  categoryId: string;
  brandId: string;
  displayScore: number;
  brandAlias1: string | null;
  brandAlias2: string | null;
  brandAlias3: string | null;
  brandThumbnailUrl: string;
  majorCategoryId: string;
  mediumCategoryId: string | null;
  minorCategoryId: string | null;
  categoryKeyword: string | null;
  status: string;
  introduction: null;
  keywords: null;
  thumbnailUrl: string;
  numOfViews: number;
  averageRate: number;
  numOfRatedReviews: number;
}

export interface IAlgoliaStoreFacet {
  majorCategoryList: IAlgoliaFacetHitValue[];
  mediumCategoryList: IAlgoliaFacetHitValue[];
  minorCategoryList: IAlgoliaFacetHitValue[];
  brandList: IAlgoliaFacetHitValue[];
  priceMinValue: number;
  priceMaxValue: number;
}

export interface IAlgoliaFacetHitValue {
  value: string;
  highlighted: string;
  count: number;
}

export interface IAlgoliaCategoryList {
  majorCategoryList: IAlgoliaFacetHitValue[];
  mediumCategoryList: IAlgoliaFacetHitValue[];
  minorCategoryList: IAlgoliaFacetHitValue[];
}

export interface IAlgoliaRelatedProductData {
  products: any;
  skip: number;
  lastViewedProduct: string;
}

export interface IAlgoliaTrendingProductData {
  products: any;
  skip: number;
}

export interface ICheckedCategoryObject {
  checkedMajorCategoryList: Set<string | unknown>;
  checkedMediumCategoryList: Set<string | unknown>;
  checkedMinorCategoryList: Set<string | unknown>;
}

export interface ISetCheckedCategoryObject {
  setCheckedMajorCategoryList: React.Dispatch<
    React.SetStateAction<Set<string | unknown>>
  >;
  setCheckedMediumCategoryList: React.Dispatch<
    React.SetStateAction<Set<string | unknown>>
  >;
  setCheckedMinorCategoryList: React.Dispatch<
    React.SetStateAction<Set<string | unknown>>
  >;
}

export interface IPriceSliderAndInputValueObject {
  sliderValue: number[];
  priceStartInputValue: string;
  priceEndInputValue: string;
}

export interface ISetPriceSliderAndInputValueObject {
  setSliderValue: React.Dispatch<React.SetStateAction<number[]>>;
  setPriceStartInputValue: React.Dispatch<React.SetStateAction<string>>;
  setPriceEndInputValue: React.Dispatch<React.SetStateAction<string>>;
}

export interface IItemDetail extends IShopbyItemDetail {
  product: TProductDetail | null;
}

export type TDeliveryConditionType =
  | 'FREE' // FREE: 무료
  | 'CONDITIONAL' // CONDITIONAL: 조건부 무료
  | 'FIXED_FEE' // FIXED_FEE: 유료(고정 배송비)
  | 'QUANTITY_PROPOSITIONAL_FEE' // QUANTITY_PROPOSITIONAL_FEE: 수량 비례
  | 'PRICE_FEE' // PRICE_FEE: 금액별 차등
  | 'QUANTITY_FEE'; // QUANTITY_FEE: 수량별 차등
/*
 * Store / Shopby Start
 */
export interface IShopbyPaging {
  pageNumber: number;
  pageSize: number; // 한 페이지당 노출 수
}

export interface IShopbyHasMorePaing extends IShopbyPaging {
  hasMore: boolean;
}

export interface ITShopbyErrorRes {
  code: string;
  detail: {
    time: string; // 예: 2023-04-04 12:44:27
    extra: null;
  };
  error: string; // 예: Bad Request
  message: string; // 예: ~~ 상품은 최소 2개 이상 구매 가능한 상품입니다. 수량을 다시 확인해 주세요.
  path: string; // API path, 예: /shop/order-sheets
  status: number;
}

// 구매자 작성형 정보(텍스트 옵션 내 기입문장)
export interface IShopbyOptionInputBase {
  inputLabel: string; // 텍스트 옵션 입력 문구
  inputValue: string; // 구매자 입력형 옵션 - 필수여부 (true: 필수, false: 선택) (nullable)
}

export interface IShopbyOptionInput extends IShopbyOptionInputBase {
  required: boolean; // 필수 여부 (true: 필수, false: 필수 아님)
}

export interface IShopbyInputOption
  extends Omit<IShopbyOptionInput, 'inputValue'> {
  inputMatchingType: 'PRODUCT' | 'OPTION' | 'AMOUNT'; // [ OPTION: 옵션별, PRODUCT: 상품별, AMOUNT: 수량별 ]

  inputNo: number; // 텍스트 옵션 번호
}

export type IShopbyOptionInputParam = IShopbyOptionInput;

export type TShopbyImage = {
  main: boolean; // 메인이미지 여부

  url: string;
};

export type TOptionDefault = {
  label: string; // 옵션명
  value: string; // 옵션값
};

export interface IShopbyOption extends TOptionDefault {
  buyPrice: number; // 할인적용가
  images: TShopbyImage[];
  saleType: 'AVAILABLE' | 'SOLD_OUT'; // [ AVAILABLE: 판매가능, SOLD_OUT: 품절 ]
  main: boolean; // 대표 옵션 여부 (true: 대표옵션, false: 대표옵션아님)
  addPrice: number; // 추가금액
  saleCnt: number; // 판매수량 (재고 미노출의 경우 -999 재고 미노출 설정일때 실재고가 없는 경우, 0으로 표기)
  stockCnt: number; // 재고수량 (재고 미노출의 경우 -999 재고 미노출 설정일때 실재고가 없는 경우, 0으로 표기)
  optionManagementCd: string; // 옵션 판매자 관리 코드
  optionNo: number; // 옵션번호
  forcedSoldOut: boolean; // 임시 품절 여부 (true: 임시품절, false: 임시품절 아님)
  // reservationStockCnt: number; // 예약재고수량 (재고 미노출의 경우 -999 재고 미노출 설정일때 실재고가 없는 경우, 0으로 표기)
  // rentalInfo: { // 렌탈료 정보
  //   monthlyRentalAmount: number;
  //   // 월 렌탈 금액
  //   rentalPeriod: number; // 렌탈 기간
  //   creditRating: number; // 서비스 가능 최저 신용 등급
  // }
}

// 분리형 옵션
export interface IShopbyMultiLevelOption extends TOptionDefault {
  children: IShopbyMultiLevelOption[] | IShopbyOption[] | null; // 자식 옵션 목록;
}

export type TShopbyOptionType =
  | 'DEFAULT'
  | 'STANDARD'
  | 'COMBINATION'
  | 'MAPPING';

export type TShopbySelectType = 'FLAT' | 'MULTI';
export type TShippingAreaType = 'PARTNER_SHIPPING_AREA' | 'MALL_SHIPPING_AREA';
export interface IShopbyItemOptionPayload {
  type: TShopbyOptionType; // [ DEFAULT: 옵션 사용안함, STANDARD: 단독형 옵션, COMBINATION: 조합형 옵션, , MAPPING: 맵핑 ]
  selectType: TShopbySelectType; // [ MULTI: 분리형, FLAT: 일체형 ]
  labels: string[]; // 옵션명 목록, DEFAULT type일 경우, ['색상'].
  multiLevelOptions: IShopbyMultiLevelOption[]; // 분리형 옵션
  flatOptions: IShopbyOption[]; // 일체형 옵션
  inputs: IShopbyInputOption[]; // 구매자 작성형 정보(텍스트 옵션 내 기입문장)
  // displayableStock: boolean; // 재고 노출 여부
}

export type YNType = 'Y' | 'N';

export type TShopbyDeliveryType = 'PARCEL_DELIVERY' | 'DIRECT_DELIVERY';
// PARCEL_DELIVERY: 택배/등기/소포 | DIRECT_DELIVERY: 직접배달(화물배송)

export interface IShopbyItemDetailStatus {
  // 상품 상태
  display: boolean; // 전시여부
  soldout: boolean; // 품절여부
  saleStatusType: typeof SHOPBY_ITEM_STATUS_TYPE_LIST[number]['value']; // READY: 판매대기, ONSALE: 판매중, FINISHED: 판매종료, STOP: 판매중지, PROHIBITION: 판매금지
}

export interface IShopbyItemDetailStock {
  // 재고정보
  saleCnt: number; // 구매 수량 (재고 미노출의 경우 -999 재고 미노출 설정일때 실재고가 없는 경우, 0으로 표기)
  mainStockCnt: number; // 대표 남은 수량(옵션의 추가금이 0인 재고의 합/재고 미노출의 경우 -999 재고 미노출 설정일때 실재고가 없는 경우, 0으로 표기)
  stockCnt: number; // 남은 수량 (재고 미노출의 경우 -999 재고 미노출 설정일때 실재고가 없는 경우, 0으로 표기)
}

export interface IShopbyItemDetail {
  baseInfo: {
    // 상품 기본 정보

    productManagementCd: string; // 판매자관리코드, PRDC10001
    placeOriginEtcLabel: string; // 원산지(기타 직접입력, 수입사등 표기)
    dutyInfo: string; // 상품정보고시, JSON.parse 필수
    contentHeader: string; // 상품설명 HTML 상단
    optionImageViewable: boolean; // 옵션이미지상세보기여부
    content: string; // 상품설명 HTML 본문
    productName: string; // 상품명, 상품명
    deliveryCustomerInfo: string; // 판매자 특이사항/고객안내사항
    productNo: number; // 상품번호, 10000001
    customPropertise: // 상품항목추가 관리 정보
    {
      propValueNo: number; // 항목 값 번호
      multipleSelectionYn: YNType; // 항목 복수선택여부 (Y: 복수개 선택가능, N: 1개만 선택가능)
      propValue: string; // 항목 값, 2022
      propName: string; // 항목명, 시즌
      propNo: number; // 항목 번호
    }[];
    expirationYmdt: string; // 유효 일자, 2024-03-28 11:45:28
    registerYmdt: string; // 상품 등록일, 2023-01-28 11:45:28
    contentFooter: string; // 상품설명 HTML 하단
    certificationType: 'TARGET' | ' NOT_TARGET' | 'DETAIL_PAGE'; // 인증 타입, TARGET: 인증대상, NOT_TARGET: 인증대상아님, DETAIL_PAGE: 상세페이지 별도표기
    manufactureYmdt: string; // 제조일자 2022-03-28 11:45:28
    accumulationUseYn: YNType; // 적립금 사용 여부 (Y:사용가능 , N:사용불가능)
    certifications: {
      date: string; // 인증일자, 2021-12-01
      no: number; // 인증유형번호, 자세한 번호 docs 확인
      code: string; // 인증번호, AAB-82KD
      organization: string; // 인증기관, 인증 기관명
      type: string; // 인증유형, 어린이제품 안전확인
      target: string; // 인증상호, 상호명
    }[];
    saleEndYmdt: string; // 판매 종료 일시, 2025-03-28 11:45:28
    salePeriodType: 'REGULAR' | 'PERIOD'; // 판매 기간 타입, REGULAR: 상시 판매, PERIOD: 기간지정 판매
    hsCode: string; // HS CODE, HSCODE001
    productGroup: 'DELIVERY' | 'SERVICE'; // 상품군, DELIVERY: 배송상품군, SERVICE: 서비스상품군
    saleStartYmdt: string; // 판매시작일시 , 2023-03-28 11:45:28
    imageUrls: string[]; // 상품 이미지URL, [//rlyfaazj0.cdn.toastcloud.com/SERVICE/20191212/XYkrzmG.jpg]
    placeOriginLabel: string; // 원산지
    productNameEn: string; // 영문 상품명
    purchaseGuide: string | null; // 구매 안내, 구입 안내 사항
  };

  reservationData: {
    // 예약판매정보

    reservationDeliveryYmdt: string; // 2023-07-21 11:09:06, 예약판매 배송시작일

    reservationStockCnt: number; // 예약판매 재고수량 (재고 미노출의 경우 -999 재고 미노출 설정일때 실재고가 없는 경우, 0으로 표기)

    reservationEndYmdt: string; // 2023-06-21 11:09:06, 예약판매 종료일

    reservationStartYmdt: string; // 예약판매 시작일
  };
  groupManagementCodeName: string | null; // 그룹관리코드 노출명
  shippingInfo: {
    //  배송 관련 정보
    shippingConfig: {
      // 배송 설정
      templateNo: number; // 배송 템플릿 번호
      shippingAreaPartnerNo: number; // 배송지 파트너 번호
      shippingAreaType: TShippingAreaType; // 출고 유형(배송 구분), PARTNER_SHIPPING_AREA: 파트너물류센터 출고, MALL_SHIPPING_AREA: 쇼핑몰물류센터 출고
      internationalShippingAvailable: boolean; // 해외 배송 여부 (true: 해외 배송, false:국내 배송)
      combinable: boolean; // 묶음배송 가능여부 (true: 묶음 배송 가능, false: 묶음 배송 불가능)
    };
    shippingAvailable: boolean; // 배송 가능 여부
  };
  deliveryGuide: string | null; // 배송 안내 (nullable), <p>템플릿 내용입니다...</p>
  groupManagementCode: string | null; // 그룹관리 코드
  relatedProductNos: number[]; // 관련 상품 번호
  liked: boolean; // 좋아요 여부(accessToken 없을 시 false)
  saleMethodType: 'PURCHASE' | 'CONSIGNMENT'; // 사입 위탁 구분 값, PURCHASE: 사입, CONSIGNMENT: 위탁
  exchangeGuide: string | null; // 환불 안내 (nullable), <p>템플릿 내용입니다...</p>
  refundGuide: string | null; // AS 안내 (nullable), <p>템플릿 내용입니다...</p>
  price: {
    // 가격 정보
    accumulationAmtWhenBuyConfirm: number; // 구매 확정시 예상 적립금
    accumulationRate: number; // 적립률(%)
    accumulationRateOfMember: number; // 회원등급에 따른 적립률(%)
    additionDiscountAmt: number | null; // 추가상품할인가
    additionDiscountUnitType: 'WON' | 'RATE'; // 추가상품할인 타입, WON: 정액, RATE: 정률
    additionDiscountValue: number | null; // 추가상품 할인 ( 원 / % ) additionDiscountUnitType 에따라 달라진다.
    contentsIfPausing: string; // 판매중지 시 가격대체문구, 잠시 판매중지 중입니다.
    couponDiscountAmt: number; // 상품 기본옵션 가격기준으로 적용 가능한 최대 쿠폰 할인가
    immediateDiscountAmt: number; // 즉시할인가
    immediateDiscountEndYmdt: string; // 즉시할인 종료일자
    immediateDiscountStartYmdt: string; // 즉시할인 시작일자
    immediateDiscountUnitType: 'WON' | 'RATE'; // 즉시할인 타입,  WON: 정액, RATE: 정률
    maxAdditionDiscountAmt: number; // 최대 추가할인 금액
    maxCouponAmt: number; // 해당 상품의 옵션을 여러개 구매할 경우 받을 수 있는 최대한의 쿠폰할인 금액
    maxDiscountAmount: number; // 추가할인 정률 최대 할인 금액
    maxSalePrice: number; // 추가할인 최대 기준금액
    minSalePrice: number; // 추가할인 최소 기준금액
    photoReviewAccumulationAmt: number; // 포토리뷰적립금
    salePrice: number; // 상품판매가
    unitName: string | null; // 단위명
    unitNameType: string | null; // 단위유형, 개, 대, 마리, 매, 발, 봉, 쌍, 알, 입, 장, 정, 켤레, 포, a, ah, box, cc, cm, ea, g, gal, gb, inch, kg, L, lb, m, mah, mb, mg, ml, mm, oz, pack, pc, pg, psi, R, T, tb, v, w, 캡슐, Vcap, soft gel, 구미, P, 권
    unitPrice: number | null; // 단위가격
  };
  reviewAvailable: boolean; // 리뷰 작성 가능 여부
  reviewRate: number; // 상품평 평균점
  categories: {
    // 카테고리 목록
    categories: {
      // 세부 카테고리 정보
      depth: number; // 뎁스
      categoryNo: number; // 카테고리 번호
      label: string; // 카테고리 명, 신규 카테고리1
    }[];
    fullCategoryLabel: string; // 전체 카테고리 명, 신규 카테고리1>신규 카테고리1-1
  }[];
  stock: IShopbyItemDetailStock;
  deliveryDate: {
    // 배송 기간
    period: {
      // 기간
      startYmdt: string; // 시작일, 2019-01-01 12:00:00
      endYmdt: string; // 종료일 , 2020-09-09 12:59:59
    };
    daysOfWeek: 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT' | 'SUN'; // 요일, example : [MON, TUE] , MON: 월요일, TUE: 화요일, WED: 수요일, THU: 목요일, FRI: 금요일, SAT: 토요일, SUN: 일요일
    daysAfterPurchase: number; // 주문일 기준
  } | null;
  brand: {
    // 브랜드 정보
    nameType: 'NAME_KO' | ' NAME_EN' | 'NONE'; // 브랜드 유형, NAME_KO: 한글, NAME_EN: 영문, NONE: 없음
    name: string; // 브랜드명, 나이키
    nameEn: string; // 영문 브랜드명, NIKE
    brandNo: number; // 브랜드 번호
    logoImageUrl: string; // 브랜드 로고 URL, https:www.nhn.com
  };
  limitations: {
    // 구매제한
    minBuyCnt: number; // 최소구매수량
    maxBuyTimeCnt: number; // 1회최대구매수량
    maxBuyDays: number; // 최대구매기간(일)
    maxBuyPeriodCnt: number; // 최대구매기간(수량)
    maxBuyPersonCnt: number; // 1인최대구매수량
    refundable: boolean; // 환불가능여부
    naverPayHandling: boolean; // 네이버페이 결제 가능여부
    memberOnly: boolean; // 비회원구매여부(true : 가입한 회원만 구매 가능)
    canAddToCart: boolean; // 장바구니 가능 여부
  };
  mainBestProductYn: boolean; // 메인 베스트 상품 여부
  counter: {
    // 상품 카운트 정보
    myInquiryCnt: number; // 내 상품문의 카운트 (accessToken 없을 시, 0)
    inquiryCnt: number; // 상품문의 카운트
    likeCnt: number; // 좋아요 수
    reviewCnt: number; // 상품평 카운트
  };
  partnerNotice: {
    // 파트너사 공지
    title: string; // 제목
    content: string; // 내용
  } | null;
  afterServiceGuide: string | null; // 교환 및 반품안내 (nullable), <p>템플릿 내용입니다...</p>
  deliveryFee: {
    // 배송 정보
    deliveryConditionType: TDeliveryConditionType; // 배송비 타입, FREE: 무료, CONDITIONAL: 조건부 무료, FIXED_FEE: 유료(고정 배송비), QUANTITY_PROPOSITIONAL_FEE: 수량 비례, PRICE_FEE: 금액별 차등, QUANTITY_FEE: 수량별 차등
    aboveDeliveryAmt: number; // 조건부 배송비의 기준값(9,800원 이상인경우 배송비 2,500원일때 aboveDeliveryAmt는 9800)
    deliveryCompanyTypeLabel: string; // 택배사명
    deliveryType: TShopbyDeliveryType; // 배송유형, PARCEL_DELIVERY: 택배/등기/소포, DIRECT_DELIVERY: 직접배달(화물배송)
    deliveryCompanyType: string; // 택배사
    defaultDeliveryConditionLabel: string; // 배송비템플릿 요약, 배송비 2500원
    remoteDeliveryAreaFees: {
      // 지역별 추가 배송비 상세
      address: string; //   추가 배송비 지역, 제주도
      extraDeliveryAmt: number; // 추가 배송비
    }[];
    deliveryAmtLabels: string[] | null; // 구간 배송비인경우 배송비에대한 요약
    deliveryAmt: number; // 배송비 (고정배송비, 조건부 배송비인 경우), 2500
    deliveryPrePayment: boolean; // 배송비 선/착불 여부 (기본값 true, 배송비 유형이 무료인 것에 대해서 값이 무엇이든 상관없음)
    deliveryConditionDetails: {
      // 배송비 조건 상세
      deliveryAmt: number; // 배송비
      below: number; // 가격 미만
      aboveOrEqual: number; // 가격 이상
    }[];
    perOrderCnt: number; // 수량 비례인 경우 개당 개수 (2개당 배송비 2,500원인 경우 perOrderCnt는 2
    deliveryCustomerInfo: string; // 판매자 특이사항/고객안내사항
    returnDeliveryAmt: number; // 반품배송비, 2500
    returnWarehouse: {
      // 반품/교환지
      warehouseAddressType: 'ADDRESS' | 'SUBSTITUTION'; // 해외창고 국가 구분, ADDRESS: 한국, SUBSTITUTION: 대체문구
      partnerNo: number; // 해당 반품지 파트너 번호
      addressStr: string; // 주소, 경기도 용인시 기흥구 청덕동 50 11 110호
      address: string; // 해당 반품지 주소 , 경기도 용인시 기흥구 청덕동 50
      defaultReleaseWarehouseYn: YNType; // 기본출고지
      countryCd: string | null; // 국가코드, KR
      defaultReturnWarehouseYn: YNType; // 기본반품지
      detailAddress: string; // 해당 반품지 상세주소, 110호
      zipCd: string; // 우편번호, 12345
      warehouseName: string; // 반품/교환지 이름
      warehouseNo: number; // 반품/교환지 번호
    };
  };
  partner: {
    // 판매자 정보
    ownerName: string; // 대표자명, 호날두
    faxNo: string; // FAX번호, 031-000-9876
    partnerName: string; // 판매자명, 다팔아
    officeAddressLabel: string; // 사업장 주소, 13487 경기도 성남시 대왕판교로 645번길 16 플레이뮤지엄<br/>경기도 성남시 분당구 삼평동 629번지 플레이뮤지엄
    companyName: string; // 상호명, 다팔아 코리아
    businessRegistrationNo: string; // 사업자번호, 2111112340
    onlineMarketingBusinessDeclarationNo: string; // 통신판매신고번호, 제 218-1234호
    email: string; // 대표 이메일, info@nhnent.com
    phoneNo: string; // 대표번호, 010-0000-1234
  };
  liquorDelegationGuide: string | null; // 주류 통신판매 명령 위임고시 (nullable), <p>주류 통신판매 위임고시 내용입니다...</p>
  status: IShopbyItemDetailStatus;

  /*
    사용하지 않는 파라미터 List
    rentalInfos: []; // 렌탈정보 X
    basicInfo : {
      couponUseYn: YNType; // 쿠폰 적용 가능 여부 (Y : 쿠폰 적용 가능, N : 쿠폰 적용 불가)
      minorPurchaseYn: Y | N; //미성년자 구매 가능 여부 (Y : 미성년자 구매 가능, N : 미성년자 구매 불가)
      productType: DEFAULT | EVENT | OFFLINE | RENTAL; //  DEFAULT: 일반상품, EVENT: 이벤트상품, OFFLINE: 오프라인상품, RENTAL: 렌탈상품 X
      usableRestockNoti: boolean; // 재입고 알림 사용 여부
      stickerLabels:string[]; // 스티커 라벨 X
      promotionText: string; // 홍보 문구
      stickerInfos: //스티커 정보 X
    }
    regularDelivery: {} | null;// 정기 결제 정보
    displayableStock: boolean; // 재고 노출 여부 (false:재고 미노출 / true:재고 노출) 사용시 API docs 확인 필요
    status: {
      productClassType: DEFAULT | EVENT | OFFLINE | RENTAL; // DEFAULT: 일반상품, EVENT: 이벤트상품, OFFLINE: 오프라인상품, RENTAL: 렌탈상품
    }
    */
}

export interface IShopbyOrderBaseInfo {
  productNo: number; // 상품번호, 10000001
  saleStartYmdt: string; // 판매시작일시 , 2023-03-28 11:45:28
  saleEndYmdt: string; // 판매 종료 일시, 2025-03-28 11:45:28
  salePeriodType: 'REGULAR' | 'PERIOD'; // 판매 기간 타입, REGULAR: 상시 판매, PERIOD: 기간지정 판매
  registerYmdt: string; // 상품 등록일, 2023-01-28 11:45:28
  productName: string; // 상품명, 상품명
  productNameEn: string; // 영문 상품명
  imageUrls: string[]; // 상품 이미지URL, [//rlyfaazj0.cdn.toastcloud.com/SERVICE/20191212/XYkrzmG.jpg]  brandName: string; // 브랜드명, 나이키
  displayCategoryNos: string; // 전시카테고리 번호 정보
  hsCode: string; // HS CODE, HSCODE001
  groupManagementCode: string | null; // 그룹관리 코드
  groupManagementCodeName: string | null; // 그룹관리코드 노출명
  accumulationInfo: {
    // 적립금 정보
    amount: number; // 적립금
    rewardRateOfProduct: number; // 상품개별적립률
    rewardRateOfMemberBenefit: number; // 회원등급적립률
  };
  accumulationAmtWhenBuyConfirm: number; // 구매 확정시 예상 적립금
  enableCoupons: boolean; // 사용가능쿠폰 존재 여부
  mainBestProductYn: boolean; // 메인 베스트 상품 여부
}

export interface IShopbyItemBaseInfo {
  productNo: number; // 상품번호, 10000001

  reservationData: {
    // 예약판매정보

    reservationDeliveryYmdt: string; // 2023-07-21 11:09:06, 예약판매 배송시작일

    reservationStockCnt: number; // 예약판매 재고수량 (재고 미노출의 경우 -999 재고 미노출 설정일때 실재고가 없는 경우, 0으로 표기)

    reservationEndYmdt: string; // 2023-06-21 11:09:06, 예약판매 종료일

    reservationStartYmdt: string; // 예약판매 시작일
  };
  saleStartYmdt: string; // 판매시작일시 , 2023-03-28 11:45:28
  saleEndYmdt: string; // 판매 종료 일시, 2025-03-28 11:45:28
  salePeriodType: 'REGULAR' | 'PERIOD'; // 판매 기간 타입, REGULAR: 상시 판매, PERIOD: 기간지정 판매
  registerYmdt: string; // 상품 등록일, 2023-01-28 11:45:28
  productName: string; // 상품명, 상품명
  productNameEn: string; // 영문 상품명
  imageUrls: string[]; // 상품 이미지URL, [//rlyfaazj0.cdn.toastcloud.com/SERVICE/20191212/XYkrzmG.jpg]
  partnerName: string; // 판매자명, 다팔아
  liked: boolean; // 좋아요 여부(accessToken 없을 시 false)
  saleCnt: number; // 구매 수량 (재고 미노출의 경우 -999 재고 미노출 설정일때 실재고가 없는 경우, 0으로 표기)
  stockCnt: number; // 남은 수량 (재고 미노출의 경우 -999 재고 미노출 설정일때 실재고가 없는 경우, 0으로 표기)
  mainStockCnt: number; // 표 남은 수량(옵션의 추가금이 0인 재고의 합/재고 미노출의 경우 -999 재고 미노출 설정일때 실재고가 없는 경우, 0으로 표기)
  brandNo: number; // 브랜드 번호
  brandName: string; // 브랜드명, 나이키
  brandNameKo: string; // 국문 브랜드명, NIKE
  brandNameEn: string; // 영문 브랜드명, NIKE
  nameType: 'NAME_KO' | ' NAME_EN' | 'NONE'; // 브랜드 유형, NAME_KO: 한글, NAME_EN: 영문, NONE: 없음
  adult: boolean; // 성인 상품 여부
  listImageUrls: string[]; // 리스트 이미지 URL
  hasCoupons: {
    // 쿠폰여부
    product: boolean; // 상품쿠폰 태그
    brand: boolean; // 브랜드쿠폰 태그
    category: boolean; // 카테고리쿠폰 태그
    partner: boolean; // 파트너쿠폰 태그
    event: boolean; // 기획전쿠폰 태그
  };
  maxCouponAmt: number; // 해당 상품의 옵션을 여러개 구매할 경우 받을 수 있는 최대한의 쿠폰할인 금액
  couponDiscountAmt: number; // 상품 기본옵션 가격기준으로 적용 가능한 최대 쿠폰 할인가
  contentsIfPausing: string; // 판매중지 시 가격대체문구, 잠시 판매중지 중입니다.
  displayCategoryNos: string; // 전시카테고리 번호 정보
  urlDirectDisplayYn: boolean; // 상품조회화면 노출 여부
  productManagementCd: string; // 판매자 관리코드
  hsCode: string; // HS CODE, HSCODE001
  groupManagementCode: string | null; // 그룹관리 코드
  groupManagementCodeName: string | null; // 그룹관리코드 노출명
  accumulationInfo: {
    // 적립금 정보
    amount: number; // 적립금
    rewardRateOfProduct: number; // 상품개별적립률
    rewardRateOfMemberBenefit: number; // 회원등급적립률
  };
  accumulationAmtWhenBuyConfirm: number; // 구매 확정시 예상 적립금
  enableCoupons: boolean; // 사용가능쿠폰 존재 여부
  mainBestProductYn: boolean; // 메인 베스트 상품 여부
}

export interface IAnotherPartnerItemInfo {
  baseInfo: IShopbyItemBaseInfo;
  deliveryFee: {
    // 배송 정보
    deliveryConditionType: TDeliveryConditionType;
    deliveryAmt: number; // 배송비 (고정배송비, 조건부 배송비인 경우), 2500
  };
  price: {
    // 가격 정보
    salePrice: number; // 상품판매가
    immediateDiscountAmt: number; // 즉시할인가
    immediateDiscountUnitType: 'WON' | 'RATE'; // 즉시할인 타입,  WON: 정액, RATE: 정률
    additionDiscountAmt: number | null; // 추가상품할인가
    additionDiscountUnitType: 'WON' | 'RATE'; // 추가상품할인 타입, WON: 정액, RATE: 정률
    additionDiscountValue: number | null; // 추가상품 할인 ( 원 / % ) additionDiscountUnitType 에따라 달라진다.
    minSalePrice: number; // 추가할인 최소 기준금액
    maxSalePrice: number; // 추가할인 최대 기준금액
    maxAdditionDiscountAmt: number; // 최대 추가할인 금액
  };
  status: {
    // 상품 상태
    display: boolean; // 전시여부
    soldout: boolean; // 품절여부
    saleStatusType: 'READY' | 'ONSALE' | 'FINISHED' | 'STOP' | 'PROHIBITION'; // READY: 판매대기, ONSALE: 판매중, FINISHED: 판매종료, STOP: 판매중지, PROHIBITION: 판매금지
  };
  optionValues: {
    productNo: number; // 상품번호
    optionValue: string; // 옵션명
    stockCnt: number; // 재고수
  }[];
  shippingArea: TShippingAreaType; // 출고 유형(배송 구분), PARTNER_SHIPPING_AREA: 파트너물류센터 출고, MALL_SHIPPING_AREA: 쇼핑몰물류센터 출고
  customProperties: {
    // 상품 항목 추가 정보
    isMultipleSelection: boolean; // 항목 복수선택여부 (true: 복수개 선택가능, false: 1개만 선택가능)
    propValueNo: number; // 상품 항목 값 번호
    propValue: string; // 상품 항목 값
    propName: string; // 상품 항목명
    propNo: number; // 상품 항목명 번호
  }[];
}

export interface IShopbyOrderSheetProduct {
  productNo: number;
  optionNo: number;
  orderCnt: number;
  optionInputs: IShopbyOptionInput[] | null | string; // 소비자 입력형 옵션

  // recurringPaymentDelivery: null; // 정기결제 배송 정보 = 미사용
  // rentalInfos: // 렌탈 정보 = 미사용
  //   | [
  //       {
  //         rentalPeriod: number; // 렌탈 기간
  //         monthlyRentalAmount: number; // 월 렌탈료
  //       },
  //     ]
  //   | null;
}
export interface IShopbyPostOrderSheet {
  products: IShopbyOrderSheetProduct[];
  cartNos: number[] | null; // 장바구니 번호 리스트 (장바구니 통해서 구매한 경우 - 구매 완료 시 해당 장바구니를 삭제합니다.)
  trackingKey: null; // 쇼핑채널링-추적키
  channelType: null; // 예: NAVER_EP, 쇼핑채널링-채널타입 (nullable)

  // 상품 쿠폰
  productCoupons?:
    | {
        couponIssueNo: number; // 쿠폰 발급 번호

        mallProductNo: number; // 상품번호
      }[]
    | null;
}

export interface IShopbyPostOrderSheetPayload {
  orderSheetNo: string;
}

export interface IShopbyCartItem {
  productNo: number;
  orderCnt: number;
  optionNo: number;
  inputs: IShopbyOptionInput[];
  finalSelectedFlatOption: IShopbyOption;
}

// 내 주문
export type TShopbyOrderRequestTypes =
  | keyof typeof SHOPBY_ORDER_REQUEST_TYPE
  | null; // 전체

export interface IShopbyGetOrderListRequest extends IShopbyPaging {
  orderRequestTypes?: TShopbyOrderRequestTypes | string; // 'TShopbyOrderRequestTypes,TShopbyOrderRequestTypes' string type

  hasTotalCount: boolean; // 목록 카운트 포함 여부
  startYmd?: null | string; // 조회 시작일 (yyyy-MM-dd), null인 경우 3개월 전 날짜로 조회
  endYmd?: null | string; // 조회 종료일 (yyyy-MM-dd), null인 경우 오늘 날짜로 조회
}

export interface IShopbyGetOrderListRequestParams {
  forceUpdate?: boolean;
  params: IShopbyGetOrderListRequest;
}

export type TShopbyGetOrderListMoreRequest = Pick<
  IShopbyGetOrderListRequest,
  'orderRequestTypes' | 'startYmd' | 'endYmd'
>;

export type TShobpyOrderGroupType = 'ALL' | 'NORMAL' | 'CLAIM';

// 다음에 할 수 있는 작업
export interface IShopbyNextAction {
  nextActionType: keyof typeof SHOPBY_NEXT_ACTION_TYPE;
  uri: string; // uri
  actionGroupType: TShobpyOrderGroupType;
}

interface IShopbyProductDiscountAmt {
  immediateDiscountAmt: number; // 즉시할인금액
  additionalDiscountAmt: number; // 추가할인금액
}

interface IShopbyProductPriceAmt extends IShopbyProductDiscountAmt {
  standardPrice: number; // 정상가(상품판매가 + 옵션추가금액)
  immediateDiscountedPrice: number; // 즉시할인적용가
}

// 주문 금액 정보
interface IShopbyOrderAmount {
  payAmt: number; // 결제금액
  subPayAmt: number; // 보조결제금액(적립금)
  standardAmt: number; // 총 상품정상금액(즉시할인, 추가할인 제외)
  deliveryAmt: number; // 총 배송비
  remoteDeliveryAmt: number; // 총 지역추가배송비
  immediateDiscountAmt: number; // 총 즉시할인 금액
  additionalDiscountAmt: number; // 총 추가할인 금액
  cartCouponDiscountAmt: number; // 주문쿠폰할인 금액
  productCouponDiscountAmt: number; // 총 상품쿠폰할인 금액
  totalProductAmt: number; // 총 상품금액 (즉시할인, 추가할인 포함) [총 상품정상금액(standardAmt) - 총 추가할인 금액(additionalDiscountAmt) - 총 즉시할인 금액(immediateDiscountAmt)]
  chargeAmt: number; // 사용자 결제 금액 (적립금 제외) [payAmt - subPayAmt]
  deliveryCouponDiscountAmt?: number; // 배송비 할인쿠폰 (?)
}

interface IShopbySetOption {
  mallProductNo: number; // 상품번호
  productManagementCd?: null | string; // 상품관리코드 (nullable)
  productName: string; // 상품명
  mallOptionNo: number; // 옵션번호
  optionManagementCd?: null | string; // 옵션관리코드 (nullable)
  optionName: string; // 옵션명
  optionValue: string; // 옵션값
  usesOption: boolean; // 옵션사용여부
  count: number; // 구매수
  optionPrice: number; // 옵션가격
  stockNo: number; // 재고번호
  sku: null | string; // sku
  optionNameForDisplay?: null | string; // 표기명
}

export interface IShopbyOrderOption extends IShopbyOrderOptionBase {
  orderNo: string; // 주문번호
  productNo: number; // 상품번호
  partnerName: null | string; // 파트너명 (nullable)
  optionNo: number; // 옵션번호
  additionalProductNo: number; // 추가상품번호
  imageUrl: string; // 상품 이미지 URL
  brandNo?: null | number; // 브랜드 번호 (nullable)
  brandName: null | string; // 브랜드명
  brandNameEn: null | string; // 브랜드영문명
  productName: string; // 상품명
  productNameEn: null | string; // 상품영문명

  // orderStatusType: keyof typeof SHOPBY_ORDER_STATUS_TYPE;
  claimStatusType: null | keyof typeof SHOPBY_CLAIM_STATUS_TYPE;
  // 주문 상태 일자
  orderStatusDate: {
    registerYmdt: null | string; // 상품평작성기한
    buyConfirmYmdt: null | string; // 구매확정일자
    reviewableYmdt: null | string; // 상품평작성기한
    payYmdt: null | string; // 결제일시
    deliveryCompleteYmdt: null | string; // 배송완료일시
  };
  claimNo: null | number; // 클레임 번호 (nullable)
  accumulationAmt: number; // 적립금
  refundable: boolean; // 환불가능여부
  cancelable: boolean; // 취소가능여부
  exchangeable: boolean; // 교환가능여부
  returnable: boolean; // 반품가능여부
  deliveryInternationalYn: boolean; // 해외배송여부
  reservationDeliveryYmdt?: null | string; // 예약배송시작일 (nullable), example: YYYY-MM-DD hh:mm:ss
  exchangeYn: 'y' | 'n'; // 교환여부
  member: boolean; // 회원여부
  deliverable: boolean; // 배송여부
  nextActions: IShopbyNextAction[]; // 다음에 할 수 있는 작업
  optionManagementCd: null | string; // 옵션관리코드
  // 배송정보
  delivery: {
    invoiceNo: null | string; // 송장번호
    deliveryCompanyType?: null | string; // 택배사타입
    retrieveInvoiceUrl?: null | string; // 송장추적 URL
    deliveryType: null | TShopbyDeliveryType | 'NONE'; // 배송타입 [ PARCEL_DELIVERY: 택배/등기/소포, DIRECT_DELIVERY: 직접배송(화물배달), NONE: 없음 ]
    usesShippingInfoLaterInput: boolean; // 배송지 나중입력 여부
    deliveryCompanyTypeLabel: null | string; // 택배사
  };
  // 가격정보
  price: {
    standardPrice: number; // 정상가(상품판매가 + 옵션추가금액)
    immediateDiscountedPrice: number; // 즉시할인적용가
    buyPrice: number; // 구매가(즉시할인 + 추가할인 적용)
    standardAmt: number; // 정상금액(상품판매가 + 옵션추가금액) * 주문수량
    immediateDiscountedAmt: number; // 즉시할인적용가 * 주문수량
    buyAmt: number; // 구매금액(구매가 * 주문수량)
    salePrice: number; // 상품판매가
    addPrice: number; // 옵션추가금액
    immediateDiscountAmt: number; // 즉시할인금액
    additionalDiscountAmt: number; // 추가할인금액
    accumulationRate: number; // 적립율
  };
  reservation: boolean; // 예약여부
  isFreeGift: boolean; // 사은품 여부
  // 세트옵션
  setOptions: IShopbySetOption[];
  isRecurringPayment?: null | boolean; // 정기배송 상품여부 (nullable)
  holdDelivery?: null | boolean; // 배송보류 여부
  orderStatusTypeLabel?: null | string; // 주문상태 (nullable); example: 결제완료
  claimStatusTypeLabel?: null | string; // 클레임상태 (nullable); example: 취소신청
}

export interface IShopbyClaimOrderOption
  extends Omit<
    IShopbyOrderOption,
    // 아래의 4개는 기존 타입에서 빠진 부분이 있어 재선언
    | 'orderStatusDate'
    | 'delivery'
    | 'nextActions'
    | 'price'
    // 위의 4개는 기존 타입에서 빠진 부분이 있어 재선언
    | 'setOptions'
    | 'partnerName'
  > {
  // 클레임 대상 상품
  orderNo: string; // 주문번호
  productNo: number;
  optionNo: number;
  additionalProductNo: number; // 추가상품번호
  imageUrl: string;
  // member: boolean; // 회원여부, 비회원 구매불가하므로 무조건 true임
  // reservation: boolean; // 예약주문여부
  nextActions: {
    nextActionType: string;
    uri: string;
  }[];
  productName: string;
  delivery: {
    invoiceNo: string;
    deliveryCompanyType: null | string;
    retrieveInvoiceUrl: string;
    deliveryCompanyTypeLabel: string;
  };
  orderStatusDate: {
    registerYmdt: null | string;
    buyConfirmYmdt: null | string;
    reviewableYmdt: null | string;
  };
  price: {
    // 금액정보
    buyPrice: number; // 구매가(즉시할인 + 추가할인 적용)
    buyAmt: number; // 구매금액 합
    immediateDiscountedAmt: number; // 즉시할인적용가 * 주문수량
    immediateDiscountAmt: number; // 즉시할인금액
    additionalDiscountAmt: number; // 추가할인금액
    salePrice: number; // 상품판매가
    standardAmt: number; // 정상금액(상품판매가 + 옵션추가금액) * 주문수량
    immediateDiscountedPrice: number; // 즉시할인적용가
    standardPrice: number; // 정상가(상품판매가 + 옵션추가금액)
    addPrice: number; // 옵션추가금액
    accumulationRate: number; // 적립률;
  };
  pgType: keyof typeof SHOPBY_PG_TYPE;
  payType: keyof typeof SHOPBY_PAY_TYPE;
  claimReasonType: null | keyof typeof SHOPBY_CLAIM_REASON_TYPE;
  claimReasonDetail: null | string;
  isQuantityDiscount: boolean;
  shippingAreaType: TShippingAreaType;
}

interface IShopbyPayTypes {
  payType: keyof typeof SHOPBY_PAY_TYPE; // 결제수단
  payTypeLabel: string; // 결제수단라벨, example: 신용카드
}

export interface IShopbyOrderItem extends IShopbyPayTypes {
  orderYmdt: string; // 주문일자, example: YYYY-MM-DD hh:mm:ss
  orderNo: string; // 주문번호
  pgType: keyof typeof SHOPBY_PG_TYPE; // 외부 PG사
  pgMallKey?: null | string; // PG사 결제키 (nullable)
  pgOrderNo?: null | string; // PG사 주문번호 - 매출전표등 확인용 (nullable)
  escrow: boolean; // 에스크로 여부
  member: boolean; // 회원 여부
  nextActions: IShopbyNextAction[]; // 다음에 할 수 있는 작업
  firstOrderAmt: IShopbyOrderAmount; // 최초주문금액정보
  lastOrderAmt: IShopbyOrderAmount; // 최종주문금액정보
  orderOptions: IShopbyOrderOption[]; // 주문 상품 옵션
}

export interface IShopbyGetOrdersPayload {
  totalCount: number; // 총 수량

  items: IShopbyOrderItem[];
}

export interface IShopbyGetOrderDetailRequest {
  orderNo: string; // path
  orderRequestType: TShobpyOrderGroupType; // query
}

export interface IShopbyCardInfo {
  cardApprovalNumber: string; // 결제승인번호
  cardName: string; // 카드사명
  installmentPeriod: number; // 할부기간
  cardCode: string; // PG 카드사 코드(PG별로 다름)

  approveYmdt: string; // 결제승인시간, ex)YYYY-MM-DD hh:mm:ss

  cardAmt: number; // 신용카드 결제금액

  cardNo: string; // 카드번호

  cardCompany: keyof typeof SHOPBY_CARD_COMPANY_TYPE; // 카드사(발급사)
  noInterest: boolean; // 무이자여부(true: 무이자, false: 이자)
}

export interface IShopbyBankInfo {
  bank: string; // 은행명 key (enum)
  bankCode: string; // PG 은행코드 (PG별로 다름)
  bankName: string; // 은행명
  account: string; // 계좌번호
  bankAmt: number; // 입금해야할 금액
  depositAmt: number; // 실제 입금금액
  depositYmdt: string; // 입금 마감일 ex) YYYY-MM-DD hh:mm:ss
  remitterName: string; // 입금자명
  depositorName: string; // 예금주명
  paymentExpirationYmdt: string; // 입금 마감일
}

interface IShopbyProductAmtInfo extends IShopbyProductPriceAmt {
  discountAmt: number; // 할인금액
  productCouponDiscountAmt: number; // 상품쿠폰할인가
  exchangeImmediateDiscountedPrice: number; // 교환상품금액
  returnImmediateDiscountedPrice?: number; // 반품상품금액
  exchangeDiscountAmt: number; // 교환할인금액
  exchangeAdjustAmt: number; // 교환조정금액
  totalAmt: number; // 총환불상품금액
  freeGiftDiscountAmt?: number;
}

interface IShopbySubtractionAmtInfo {
  // 환불차감금액
  cartCouponAmt: number; // 장바구니쿠폰 변경금액
  deliveryCouponAmt: number; // 배송비쿠폰 변경금액 (?)
  refundAdjustAmt: number; // 환불금액조정
  refundAdjustReason: string; // 환불금액조정사유
  totalAmt: number; // 총주문차감금액
}

interface IShopbyDeliveryAmtInfo {
  beforeDeliveryAmt: number; // 변경 전 배송비(지역별배송비포함)
  afterDeliveryAmt: number; // 변경 후 배송비(지역별배송비포함)
  refundDeliveryAmt: number; // 환불배송비(마이너스 시 고객에게 배송비 부과)
  payOnDelivery: boolean; // 착불여부(true: 착불, false: 선불) * true일 경우 refundDeliveryAmt 0
  sellerFault: boolean; // 판매자부담여부(true: 판매자부담, false: 구매자부담) * true일 경우 refundDeliveryAmt 0
  returnDeliveryAmt: number; // 환불배송비(마이너스 시 고객에게 배송비 부과)
  returnAdjustAmt: number; // 반품배송비 조정금액
  buyerReturn: boolean; // 구매자직접배송여부(true: 구매자직접반품, false: 판매자수거요청
  exchangeDeliveryAmt: number; // 교환재발송배송비(지역별배송비포함)
  exchangeAdjustAmt: number; // 교환재발송배송비 조정금액
  totalAmt: number; // 총배송비
}

interface IShopbyClaimDeliveryAmtInfo extends IShopbyDeliveryAmtInfo {
  sellerFault: boolean; // 판매자부담여부(true시 refundDeliveryAmt 0)
  afterRemoteDeliveryAmt: number;
}

export interface IShopbyPayInfo {
  claimNo: number; // 클레임번호
  productAmtInfo: IShopbyProductAmtInfo;
  deliveryAmtInfo: IShopbyDeliveryAmtInfo;
  subtractionAmtInfo: IShopbySubtractionAmtInfo; // 환불차감금액
  returnWayType: 'SELLER_COLLECT' | null;
  returnAddress: null | IShopbyAddressInfo;
  returnInvoiceNo: null | string; // 반품 송장번호 (nullable)
  returnDeliveryCompanyTypeLabel: null | string; // 반품 택배사명 (nullable)
  exchangeAddress: IShopbyAddressInfo;
  claimImageUrls: [];
  exchangeOrderOption:
    | null
    | {
        userInputText: null | string; // 텍스트옵션 (nullable), example: 텍스트옵션

        imageUrl: null | string; // 상품 이미지 (nullable), example: http://image.url

        optionValue: null | string; // example: 105 옵션 값 (nullable)

        orderCnt: null | number; // 교환 상품 수량 (nullable), example: 1

        optionName: null | string; // 옵션명 (nullable), example: 사이즈

        productName: null | string; // 상품명 (nullable), example: 테스트 상품
      }[]; // 교환상품정보 (nullable)
  claimClassType: keyof typeof SHOPBY_CLAIM_CLASS_TYPE | null; // 클레임 타입 (nullable)
}

export interface IShopbyBank {
  bank: null | keyof typeof SHOPBY_CARD_COMPANY_TYPE;
  bankAccount: null | string;
  bankDepositorName: null | string;
  bankName: null | string;
}

export interface IShopbyRefundPayInfo extends IShopbyPayInfo {
  refundBankAccount: IShopbyBank;
  refundPayAmt: number; //
  refundSubPayAmt: number; //
  refundType: keyof typeof SHOPBY_REFUND_TYPE;
  refundPayType: string; // 환불결제방법
  refundTypeLabel: string; // 환불방법(노출용)
  refundMainPayAmt: number; //
  refundOrderOptions: [];
}

export interface IShopbyExchangePayInfo {
  bankAccount: IShopbyBank;
  remitter: string; // 송금인
  payType: keyof typeof SHOPBY_PAY_TYPE; // 처리금 결제방법 : 샵바이에 예시가 올바르지 않은데, 우선 라벨 이용을 위해 기존 pay type 사용.
  exchangePayAmt: number; // 교환처리금액
}
export type TShopbyAdditionalPayInfo = IShopbyPayInfo & IShopbyExchangePayInfo;

interface IShopbyBaseAddressInfo {
  address: string; // 배송지 도로명 주소 (nullable)
  jibunAddress: null | string; // 배송지 지번 주소
  detailAddress: null | string; // 배송지 도로명 상세 주소 (nullable)
  zipCd: string; // 배송지 우편 번호
}

interface IShopbyAddressInfo extends IShopbyBaseAddressInfo {
  name: string; // 수령자 명
  contact1: string; // (nullable) '010-8038-0000';
  contact2: null | string; // (nullable) '031-8038-0000';
  note: string;
  addressStr: string; // ex) (12345) 이매동 사매동
  customsIdNumber: null | string;
}

// 회원에 저장된 주소
export interface IShopbyMemberAddressInfo extends IShopbyBaseAddressInfo {
  jibunDetailAddress: null | string; // 배송지 지번 상세 주소 (nullable)
}

interface IShopbyReceiverAddressInfo {
  receiverName: string;
  receiverContact1: string;
  receiverContact2: null | string;
  receiverAddress: string; // 수령지
  receiverZipCd: string; // 배송지 우편 번호
  receiverDetailAddress: null | string; // 배송지 상세 주소 (nullable)
  receiverJibunAddress: null | string; // 배송지 지번 (지역추가배송비계산 시 사용)
}

export interface IShopbyReceiverAddressBase extends IShopbyReceiverAddressInfo {
  addressNo: number; // 배송지 번호(0:신규, 0이상:이전배송지)
  addressName: null | string;
  customsIdNumber: null | string; // 개인통관고유부호(해외배송상품인 경우 필수)
  countryCd: string | null; // 'KR'
}

export interface IShopbyClaimAddressBase extends IShopbyReceiverAddressInfo {
  customsIdNumber: null | string; // 개인통관고유부호(해외배송상품인 경우 필수)
  countryCd?: string | null; // 'KR'
  deliveryMemo: string;
  addressView?: string; // (zip코드 포함) 수령자 상세 주소^|(08393) 서울특별시 구로구 디지털로26길 72 3층 (구로동, NHN한국사이버결제)
}

export interface IShopbyWareHouseForClaim {
  // 반품할 물건을 보낼 주소 (nullable)
  zipCd: null | string; // 우편번호, 12345
  address: null | string; // 해당 반품지 주소,  서울특별시 구로구 디지털로26길 72
  jibunAddress: string; // 지번 주소, 서울특별시 구로구 구로동 222-22
  detailAddress: null | string; // 해당 반품지 상세주소, NHN한국사이버결제 3층
  receiverName: null | string;
  contact: null | string; // 01012341234
  substitutionText: null | string; // 대체문구, 물류센터
  summary: string; // 요약정보, 12345 서울특별시 구로구 디지털로26길 72 NHN한국사이버결제 3층
}

export interface IShopbyClaimReturnAddress {
  note: null | string; // 배송메모 (nullable)
  addressStr: string; // ex) 12345 서울시 용산구 후암동 123-45
  address: string; // ex) 서울시 용산구 신창동
  name: null | string; // 수령자명

  jibunAddress: string; // 지번 주소, 서울특별시 구로구 구로동 222-22
  detailAddress: null | string; // 해당 반품지 상세주소, NHN한국사이버결제 3층

  zipCd: null | string; // 우편번호, 12345
  contact1: null | string; // (nullable) '010-8038-0000';
  contact2: null | string; // (nullable) '031-8038-0000';
}

export interface IShopbyOrderOptionGroup extends IShopbyReceiverAddressInfo {
  deliveryNo: number; // 배송번호
  deliveryAmt: number; // 배송비
  remoteDeliveryAmt: number; // 지역별 추가 배송비
  returnDeliveryAmt: number; // 반품 배송비
  deliveryType: TShopbyDeliveryType | 'NONE'; // [ PARCEL_DELIVERY: 택배/등기/소포, DIRECT_DELIVERY: 직접배송(화물배달), NONE: 없음 ]
  deliveryCompanyType: null | string; // 택배사타입 (nullable) 고유키 값, ex) CJ, 한글명 사용시엔 deliveryCompanyTypeLabel 사용
  deliveryCompanyTypeLabel: string; // 택배사 한글명
  invoiceNo: string; // 송장번호
  deliveryPayType: 'PREPAID_DELIVERY' | 'PAY_ON_DELIVERY'; // 선/착불타입 [ PREPAID_DELIVERY: 배송비 선불, PAY_ON_DELIVERY: 배송비 착불 ]
  deliveryMemo: null | string; // 배송지 메모 (nullable)
  retrieveInvoiceUrl: null | string; // 송장추적 URL (nullable)
  orderOptions: IShopbyOrderOption[]; // 주문상품옵션
  requestShippingDate: string | null; // 배송지정일 (nullable), example: YYYY-MM-DD
  usesShippingInfoLaterInput: null | boolean; // 배송지 나중입력 여부 (true: 나중입력, false: 바로입력) (nullable)
  shippingAreaType: TShippingAreaType; // 배송구분 [ PARTNER_SHIPPING_AREA: 파트너사배송, MALL_SHIPPING_AREA: 쇼핑몰배송 ]
  partnerNo: number; // 파트너번호 - 쇼핑몰 배송일 경우 0 ***
  partnerName: string; // 파트너명 - 쇼핑몰 배송일 경우 '쇼핑몰 배송' ***
  shippingMethodType: null | string; // 배송서비스타입(for US) (nullable)
  shippingMethodLabel: null | string; // 배송서비스타입라벨(for US) (nullable)
  frontDisplayText: null | string; // 배송조건노출문구(for US) (nullable)
}

interface IShopbyValidInfo {
  errorCode: {
    code: string;
    simpleCode: string;
  };
  message: string; // 유효성 실패 사유 메세지
  orderCntChangeable: boolean; // 주문수량변경 가능 여부 (true:변경가능 false:변경불가능)
  valid: boolean; // 유효 여부(true: 유효, false: 유효하지 않음)
}

export interface IOrderProductOptions extends IShopbyDefaultOption {
  cartNo: number;
  stockCnt: number;
  accumulationAmtWhenBuyConfirm: number; // 구매확정시 예상 적립금
  validInfo: null | IShopbyValidInfo; // 유효성 정보
  imageUrl: string;
  orderCnt: number; // 주문수량
  productNo: number; // 상품번호
  optionNo: number; // 옵션번호
  optionInputs: IShopbyOptionInput[] | null;
  optionManagementCd: null | string; // 세트옵션 옵션관리코드 (nullable)
  price: {
    salePrice: number; // 상품판매가
    addPrice: number; // 옵션가격(추가금액)
    immediateDiscountAmt: number; // 즉시할인 금액
    additionalDiscountAmt: number; // 추가할인 금액
    standardAmt: number; // 정상금액(상품판매가 + 옵션추가금액) * 주문수량
    buyAmt: number; // 구매금액(구매가 * 주문수량)
  };
  soldOut: boolean;
  setOptions: IShopbySetOption[];
  // reservation: boolean; // 예약주문여부 (true: 예약주문상품, false: 일반상품)
  // recurringPaymentDelivery: null; // 정기결제
  // reservationDeliveryYmdt: null; // 예약주문 상품 배송시작예정일
}

export interface IShopbyOrdererBase {
  ordererName: string;
  ordererContact1: string;
  ordererContact2: string | null;
  ordererEmail: string;
}

export interface IShopbyDeliveryGroup {
  orderProducts: {
    productNo: number;
    imageUrl: string;
    brandNo: number;
    brandName: string;
    productName: string;
    productNameEn: string;
    liked: boolean; // 찜상품 여부 (true: 찜상품, false: 일반상품)
    optionUsed: boolean; // 옵션사용여부
    deliverable: boolean; // 배송가능여부
    deliveryInternational: boolean;
    refundable: boolean;
    cancelable: boolean;
    exchangeable: boolean;
    returnable: boolean;
    orderProductOptions: IOrderProductOptions[];
    brandNameEn: string;
    deliveryDate: {
      daysAfterPurchase: number;
      daysOfWeek: [];
      period: {
        startYmdt: string;
        endYmdt: string;
      };
    };
    shippingAreaType: TShippingAreaType;
    accumulationUsable: boolean; // 적립금 사용 가능 여부
    couponUsable: boolean; // 쿠폰 사용 가능 여부
    categoryNos: (string | number)[]; // 카테고리 번호
    buyAmt: number; // 구매금액(구매가 * 주문수량)
    additionalProducts: (string | number)[]; // 추가상품 (?)
  }[];
  deliveryAmt: number;
  deliveryPayType: 'PREPAID_DELIVERY' | 'PAY_ON_DELIVERY'; // 배송비 착불 여부
  deliveryCondition: {
    deliveryAmt: number;
    remoteDeliveryAmt: number; // 추가배송비(조건에 의해 계산되어진)
    returnDeliveryAmt: number;
    aboveDeliveryAmt: number; // 조건부 배송비의 기준값(9,800원 미만 배송비 2,500원일때 aboveDeliveryAmt는 9800)
    baseDeliveryAmt: number; // 조건부 배송비 미달 시 배송비(9,800원 미만 배송비 2,500원일때 baseDeliveryAmt는 2,500)
    deliveryConditionType: TDeliveryConditionType; // 배송조건
    groupDeliveryAmtType: 'MAXIMUM_SELECTED' | 'MINIMUM_SELECTED'; // 묶음 배송 조건 [ MAXIMUM_SELECTED: 최대부과, MINIMUM_SELECTED: 최소부과 ]
    chargesRemoteDeliveryAmt: boolean; // 지역별추가배송비사용여부 (true: 사용, false: 미사용)
  };
  partnerName: string;
  partnerNo: number;
}

export interface IShopbyGetOrderSheetInfoPayload {
  deliveryGroups: IShopbyDeliveryGroup[];
  orderSheetPromotionSummary: null | {
    // 프로모션 정보 요약 (nullable)
    usableCouponCnt: number; // 사용가능한 내 쿠폰 개수
    myCouponCnt: number; // 내 쿠폰 개수
    usableDeliveryCouponCnt: number; // 사용가능한 배송비 쿠폰
    myDeliveryCouponCnt: number; // 내 배송비 쿠폰
    myAccumulationAmt: number; // 사용가능한 적립금
  };
  orderSheetAddress: {
    mainAddress: IShopbyReceiverAddressBase;
    recentAddresses: IShopbyReceiverAddressBase[];
    memberAddress: IShopbyMemberAddressInfo | null; // 회원에 저장된 주소
    recentDeliveryMemo: String[]; // 최근 배송 메시지 (nullable)
  };
  ordererContact: IShopbyOrdererBase;
  lastPayType: keyof typeof SHOPBY_PAY_TYPE; // 최근 사용한 결제수단
  paymentInfo: {
    // 결제 정보
    accumulationAmt: number; // 보유한 적립금
    accumulationAmtWhenBuyConfirm: number; // 구매확정시 예상 적립금
    availableMaxAccumulationAmt: number; // 최대 사용가능한 적립금
    cartAmt: number; // buyAmt[장바구니 금액] = totalStandardAmt[최종상품금액] - totalImmediateDiscountAmt[즉시할인가] - totalAdditionalDiscountAmt[추가할인가] - productCouponAmt[상품쿠폰할인금액]
    cartCouponAmt: number; // 장바구니쿠폰 변경금액
    customsDuty: number; // customs Duty [관세] (미국 몰 전용)
    deliveryAmt: number; // 배송비
    deliveryAmtOnDelivery: number; // 착불 배송비
    deliveryCouponAmt: number; // 배송비쿠폰 변경금액
    isAvailableAccumulation: boolean; // 적립금 사용 가능 여부 (true: 가능, false: 불가능)
    minAccumulationLimit: number; // 적립금을 사용할 수 있는 최소 적립금 기준
    minPriceLimit: number; // 적립금을 사용할 수 있는 최소 결제 금액 기준
    paymentAmt: number; // paymentAmt[결제예정금액] = buyAmt[장바구니 금액] - cartCouponAmt[장바구니 쿠폰할인금액] + deliveryAmt[배송비] + remoteDeliveryAmt[지역별추가배송비] + salesTaxAmt (- usedAccumulationAmt[사용한적립금]:OrderSheet시점에는 hidden)
    productAmt: number; // productAmt[상품금액] = totalStandardAmt[최종상품금액] - totalImmediateDiscountAmt[즉시할인가] - totalAdditionalDiscountAmt[추가할인가]
    productCouponAmt: number; // 상품쿠폰 할인금액(상품쿠폰 + 플러스쿠폰)
    remoteDeliveryAmt: number; // 추가배송비(조건에 의해 계산되어진)
    remoteDeliveryAmtOnDelivery: number; // 착불 지역별 추가 배송비
    totalAdditionalDiscountAmt: number; // additionalDiscountAmt[추가할인가] * orderCnt[구매수량]
    totalImmediateDiscountAmt: number; // immediateDiscountAmt[즉시할인가] * orderCnt[구매수량]
    totalStandardAmt: number; // standardPrice[최종상품금액] (salePrice[판매가] + addPrice[옵션추가금액]) * orderCnt[구매수량]
    usedAccumulationAmt: number; // 사용한 적립금
    // salesTaxAmt: number; // sales Tax (미국 몰 전용)
  };
  tradeBankAccountInfos: ({ bankCode: string } & Omit<IShopbyBank, 'bank'>)[]; // 거래은행 정보
  availablePayTypes: ({
    pgTypes: (keyof typeof SHOPBY_PG_TYPE)[];
  } & IShopbyPayTypes)[];
  sellerPrivacyUsagePartners: [
    {
      partnerName: string;
    },
  ];
  agreementTypes: (keyof typeof SHOPBY_AGREEMENT)[]; // 필수동의항목
  requireCustomsIdNumber: boolean; // 개인통관고유부호필요여부 (true: 필요, false: 불필요)
  invalidProducts: null | any[]; // 유효하지 않은 상품 (nullable)

  // freeGiftInfos: [];
  // rentalInfos: [];
  // foreignPartners: any[]; // 해외 파트너
  // applyCashReceiptForAccount: boolean; // 무통장입금 거래 시 현금영수증 사용 여부 (true: 사용, false: 미사용)
}

export interface IShopbyGetOrderDetailPayload {
  orderNo: string;
  orderYmdt: string;
  payType: keyof typeof SHOPBY_PAY_TYPE;
  payInfo: {
    payType: keyof typeof SHOPBY_PAY_TYPE;
    cardInfo: null | IShopbyCardInfo; // 신용카드 정보
    bankInfo: IShopbyBankInfo; // 가상계좌/계좌이체/무통장 정보
    naverPayInfo: null; // 네이버 페이 결제정보 (아직 미사용으로 자세한 타입 생략)
    cashAuthNo: null | string; // 현금영수증 승인번호 (nullable)
    cashNo: null | string; // 현금영수증 거래번호 (nullable)
    tradeNo: null | string; // 거래번호 (nullable)
    escrowYn: YNType; // 에스크로 결제 여부
    payAmt: null | number; // PG결제 금액 (nullable)
    sellerCouponAmt: null | number; // 가맹점 발행쿠폰 (nullable)
    pgCouponAmt: null | number; // PG 쿠폰 금액 (nullable)
    cardCouponAmt: null | number; // 카드사 쿠폰 금액 (nullable)
    pointAmt: null | number; // PG 포인트 (nullable)
    taxType: null | 'DUTY' | 'DUTYFREE' | 'SMALL'; // 몰의 과세 타입 (nullable) [ DUTY: 과세, DUTYFREE: 면세, SMALL: 영세 ]
    mobileInfo: null | {
      // 휴대폰결제 정보
      mobileCompany: string; // 통신사, example: SKT
      mobileNo: string; // 휴대폰번호, example: 010-1111-1111
    };
    // 렌탈료 정보
    // rentalInfo: null | {
    //   monthlyRentalAmount: number; // 월 렌탈 금액
    //   rentalPeriod: number; // 렌탈 기간
    // };
  };
  pgType: keyof typeof SHOPBY_PG_TYPE;
  pgMallKey: string; // PG사 결제키
  pgOrderNo: string; // PG사 주문번호
  escrow: boolean; // 에스크로 여부
  orderMemo?: null | string; // 주문메모 (nullable)
  orderOptionsGroupByPartner: [
    // 파트너별주문리스트
    {
      partnerNo: number; // 파트너번호
      partnerName: string; // 파트너명
      // 배송그룹별 옵션
      orderOptionsGroupByDelivery: IShopbyOrderOptionGroup[];
    },
  ];
  // guestToken: null | string; // 비회원-인증토큰 (nullable)
  refundInfos: IShopbyRefundPayInfo[]; // 환불정보 (nullable)
  additionalPayInfos: TShopbyAdditionalPayInfo[];
  exchangePayInfos: IShopbyExchangePayInfo[];
  member: boolean; // 회원여부(true: 회원, false: 비회원)
  firstOrderAmount: IShopbyOrderAmount;
  lastOrderAmount: IShopbyOrderAmount;
  shippingAddress: IShopbyReceiverAddressBase; // 배송지정보
  orderer: IShopbyOrdererBase;
  billingAddress: null;
  nextActions: IShopbyNextAction[];
  receiptInfos: {
    receiptType: 'CASH_RECEIPT' | 'SALE_STATEMENT' | string; // 영수증 타입
    url: string;
  }[];
  claimReasonTypes: {
    // 선택가능한 사유 목록
    claimReasonType: null | keyof typeof SHOPBY_CLAIM_REASON_TYPE; // 클레임 사유타입 [ CHANGE_MIND: 단순변심(색상,사이즈 등), DEFECTIVE_PRODUCT: 상품불량/파손, WRONG_DELIVERY: 배송누락/오배송, OUT_OF_STOCK_SYSTEM: 재고부족(품절취소), CANCEL_BEFORE_PAY: 입금전취소, WRONG_PRODUCT_DETAIL: 상품상세 정보와 다름, DELAY_DELIVERY: 판매자 배송 지연, OUT_OF_STOCK: 상품 품절/재고 없음, OTHERS_SELLER: 기타(판매자 귀책), OTHERS_BUYER: 기타(구매자 귀책) ]
    label: string; // 클레임 사유 한글명
    responsibleObjectType: 'BUYER' | 'SELLER'; // 귀책타입 [ BUYER: 구매자귀책, SELLER: 판매자귀책 ]
  }[];
  refundType: keyof typeof SHOPBY_REFUND_TYPE; // 환불(예상)방법(PG)
  refundPayType: string; // 환불결제방법, ex) CREDIT_CARD
  refundTypeLabel: string; // 환불결제방법 한글 라벨, ex) 신용카드
  memo: string; // 배송지 메모 (?)
  deliveryMemo: null | string; // 배송지 메모 (nullable)
  extraData: { [key: string | number]: any }; // 추가 정보 (nullable)
  availableBanks: {
    bank: keyof typeof SHOPBY_BANK;
    label: PropertyType<typeof SHOPBY_BANK>;
  }[];
  requireCustomsIdNumber: boolean;
  defaultOrderStatusType: keyof typeof SHOPBY_ORDER_STATUS_TYPE; // 기본 주문 상태
  accumulationAmtWhenBuyConfirm: number; // 구매확정 시 적립예정 적립금
  payTypeLabel: string;
  // 미제공 항목 = deprecated
  // insurance?: {
  //   no: null;
  //   type: null;
  //   url: null;
  // };
}

export interface IShopbyGetOrderSheetCalculatedInfoRequest {
  accumulationUseAmt: number; // 적립금 사용액
  addressRequest: {
    addressType: TShopbyAddressType;
    defaultYn: YNType;
  } & Omit<IShopbyReceiverAddressBase, 'addressNo'>;
  shippingAddresses: {
    addressNo: number; // 배송지 번호(0:신규, 0이상: 이전배송지)
    addressName: null | string;
    useDefaultAddress: boolean; // 기본 주소지 설정 여부 (true 이면 선택한 배송지 정보가 기본주소지로 설정되어 저장된다) (true: 설정, false: 미설정)
    usesShippingInfoLaterInput: null | boolean; // 배송지 나중입력 여부 (true: 나중입력, false: 바로입력) (nullable)
    shippingInfoLaterInputContact: null | string; // 배송지 나중입력 연락처
    requestShippingDate: string | null; // 배송지정일 (nullable), example: YYYY-MM-DD
    shippingAddress: {
      deliveryMemo: null | string;
      defaultYn: YNType;
    } & Omit<IShopbyReceiverAddressBase, 'addressNo' | 'addressName'>;
    payProductParams: {
      productNo: number;
      optionNo: number;
      orderCnt: number;
      optionInputs: IShopbyOptionInput[];
      // rentalInfos: [];
      // recurringPaymentDelivery: null; // 정기결제 배송 정보 = 미사용;
    }[];
  }[];

  // couponRequest: {
  //   cartCouponIssueNo: number; // 장바구니 쿠폰 발행 번호
  //   channelType: null; // 쇼핑채널링-채널타입 (nullable)
  //   promotionCode: string; // 쿠폰 할인 코드
  //   productCoupons: // 상품 쿠폰 (nullable)
  //     | null
  //     | {
  //         productNo: number;
  //         couponIssueNo: number;
  //       }[];
  // };
}

export interface IShopbyGetOrderSheetCalculatedInfoRequestParams {
  orderSheetNo: string;
  data: IShopbyGetOrderSheetCalculatedInfoRequest;
}

export interface IShopbyCalculatedInfoDeliveryGroup
  extends Omit<IShopbyDeliveryGroup, 'orderProducts'> {
  orderProducts: Pick<IShopbyDeliveryGroup, 'orderProducts'> & {
    orderProductOptions: (IOrderProductOptions & {
      optionType: Pick<IShopbyDefaultOption, 'optionType'>;
      optionTitle: Pick<IShopbyDefaultOption, 'optionTitle'>;
    })[];
  };
}

export interface IShopbyGetOrderSheetCalculatedInfoPayload {
  paymentInfo: IShopbyGetOrderSheetInfoPayload['paymentInfo'];
  appliedCoupons: {
    cartCouponIssueNo: number;
    cartCouponApplied: false;
    promotionCode: string;
    productCoupons: [
      {
        productNo: number;
        couponIssueNo: number;
        couponApplied: false;
      },
    ];
  };
  availablePayTypes: Pick<IShopbyGetOrderSheetInfoPayload, 'availablePayTypes'>;
  deliveryGroups: IShopbyCalculatedInfoDeliveryGroup;
}
export type TShopbyAddressType = 'BOOK' | 'RECENT' | 'RECURRING_PAYMENT'; // BOOK: 기본주소, RECENT: 최근주소, RECURRING_PAYMENT: 정기결제 배송주소

export interface IShopByUserAddress {
  addressNo: number; // 배송지 번호
  memberNo: number; // 회원 번호
  mallNo: number; // 쇼핑몰 번호
  registerYmdt: string | null; // 배송지 등록일
  addressType: TShopbyAddressType; // 배송지타입
  defaultYn: YNType; // 기본 배송지 여부
  addressName: string; // 주소록명, api 명세는 nullable 이나, 기획상 필수 값.
  receiverName: string; // 수령자 명
  receiverZipCd: string; // 배송지 우편번호
  receiverAddress: string; // 배송지 주소
  receiverJibunAddress: string | null; // 배송지 지번
  receiverDetailAddress: string | null; // 배송지 상세 주소
  receiverContact1: string; // 연락처 1
  receiverContact2: string | null; // 연락처 2
  customsIdNumber: string | null; // 개인통관고유부호
  countryCd: string | null; // 국가 코드
  lastUseYmdt: string | null; // 배송지 마지막 사용일
  externalMemberNo: string | null; // 외부회원 번호
  state: string | null; // 주/지역
  city: string | null; // 도시
  firstName: string | null; // 이름
  lastName: string | null; // 성
}

export interface IShopbyDefaultOption {
  optionType: string; // 옵션 종류, NORMAL_OPTION: 일반 옵션, ADDITIONAL_PRODUCT: 추가 옵션, PRODUCT_ONLY: 옵션없음
  optionTitle: string; // 옵션 권장 출력값
  optionValue: string; // 옵션값
  optionName: string; // 옵션명
}

export interface IShopbyOrderOptionBase extends IShopbyDefaultOption {
  orderCnt: number; // 주문수량

  inputs: Omit<IShopbyOptionInput, 'required'>[];
  orderStatusType: keyof typeof SHOPBY_ORDER_STATUS_TYPE; // 주문 상태
  optionUsed: boolean; // 옵션사용여부
  orderOptionNo: number; // 주문 옵션 번호
}

export interface IShopbyOrderedOption extends IShopbyOrderOptionBase {
  addPrice: number; // 옵션 추가 금액
}

export interface IShopbyReviewDetail {
  productTotalCount: number; // 상품평 총 개수
  myReview: boolean; // 본인 여부
  platformType: string; // 작성 플랫폼 , PC: PC, MOBILE_WEB: 모바일 웹, MOBILE_APP: 모바일 앱, RESPONSIVE: 반응형
  memberName: string; // 작성자 이름
  content: string; // 상품평 내용
  providerType: TShopbyProviderType; // 공급자 유형
  productName: string; // 상품명
  updateYmdt: string; // 수정일
  bestReviewYn: string; // 베스트 상품평 여부 ( 우수상품평:Y , 일반상품평:N )
  rate: number; // 평점
  imageUrl: string; // 상품 대표 이미지
  reviewNo: number; // 리뷰 번호
  nickname: string; // 작성자 닉네임
  productNo: number; // 상품 번호
  registerYmdt: string; // 등록일
  memberId: string; // 회원 id
  brandName: string; // 브랜드 명
  recommendable: boolean; // 추천가능여부
  blindReportCnt: number; // 블라인드 수
  expelled: boolean; // 휴면 회원 여부
  reportCnt: number; // 신고 수
  fileUrls: string[]; // 첨부파일 url 리스트
  reportable: boolean; // 신고가능여부
  externalReview: boolean; // 외부 작성 여부
  orderedOption: IShopbyOrderedOption;
  recommendCnt: number; // 추천 수
  commentCount: number; // 상품평의 댓글 개수
  isDeletedProductReview: boolean; // 상품 삭제 여부
  productDiscountPrice: number; // 상품 할인 가격
  brandNameEn: string; // 브랜드 명(영문)
  productRate: number; // 상품 평점
  givenAccumulationYn: string; // 적립금 지급 여부
  extraJson: string; // 상품평 작성 리뷰
  registerName: string; // 작성자 명
}

export interface IShopbyRevewListResponse {
  totalCount: number; // 총개수
  rate: number; // 리뷰평점
  reviewRatingResponses: {
    rating: number; // 평점 별 개수
    countOfRating: number; // 평점
  }[];
  items: IShopbyReviewDetail[]; // 리뷰 리스트
}

export type TDirection = 'ASC' | 'DESC';

export interface IShopbyReviewListRequest {
  productNo: number;
  parameters: {
    pageNumber: number;
    pageSize: number;
    hasTotalCount: boolean;
    bestReviewYn: 'Y' | 'N';
    orderDirertion: TDirection; // 오름차순: ASC, 내림차순: DESC
    orderBy: 'RECOMMEND' | 'REGISTER_YMDT' | 'RATING' | 'BEST_REVIEW'; // 추천순: RECOMMEND, 등록일순: REGISTER_YMDT, 평점순: RATING, 베스트리뷰순: BEST_REVIEW
    hasAttachmentFile: boolean;
  };
}

export interface IShopbyPhotoReviewContent {
  attachedFileCount: number; // 첨부파일 갯수
  urls: string[]; // 이미지 URL
  reviewNo: number; // 리뷰 넘버
  recommendCnt: number; // 추천수
  registerNo: number; // 작성자 번호
}

export interface IShopbyOrderOptionCountPayload {
  // depositWaitCnt: number;
  payDoneCnt: number;
  productPrepareCnt: number;
  deliveryPrepareCnt: number;
  deliveryIngCnt: number;
  deliveryDoneCnt: number;
  buyConfirmCnt: number;
  cancelDoneCnt: number;
  returnDoneCnt: number;
  exchangeDoneCnt: number;
  cancelProcessingCnt: number;
  returnProcessingCnt: number;
  exchangeProcessingCnt: number;
}

export type IShopbyUpdateOrderAddressRequstPayload = Omit<
  IShopbyReceiverAddressBase,
  'addressNo' | 'addressName'
> & { deliveryMemo: string };

export interface IShopbyUpdateOrderAddressRequest {
  orderNo: string;
  data: IShopbyUpdateOrderAddressRequstPayload;
}

/* 클레임 - 주문 취소/교환/반품 */
export interface IShopbyGetOrderClaimInfoQuery {
  claimType: keyof typeof SHOPBY_CLAIM_TYPE;
}

export interface IShopbyGetOrderClaimInfoRequest {
  orderOptionNo: number;
  queryData: IShopbyGetOrderClaimInfoQuery;
}

export interface IShopbyGetInfoForClaimResponsePayload {
  originalOption: IShopbyClaimOrderOption; // 클레임 대상 상품
  claimableOptions: IShopbyClaimOrderOption[]; // 함께 클레임 가능한 대상 상품들
  claimReasonTypes: {
    claimReasonType: null | keyof typeof SHOPBY_CLAIM_REASON_TYPE;
    responsibleObjectType: null | 'BUYER' | 'SELLER';
    label: string; // 클레임 사유 한글명
  }[];
  returnWayType: 'SELLER_COLLECT' | null; // 판매자수거요청, 구매자직접반품 케이스 현재 프로덕트상 미존재 (23.06.29)
  orderProductOptionNo: number; // 주문옵션번호
  claimYmdt: string; // 클레임신청일시
  exchangePayInfo: null | {
    // 교환 추가금액 결제 정보 (nullable)
    exchangePayAmt: number; // 교환금액
    bankAccount: IShopbyBank;
    payType: string; // keyof typeof SHOPBY_PAY_TYPE; // 결제수단
    remitter: string | null; // 입금자 (nullable)
  };
  claimImageUrls: string[] | null;
  responsibleObjectTypes: ('BUYER' | 'SELLER')[]; // 귀책 대상
  // responsibleObjectType이 null이면 ClaimReasonType에 매핑되는 귀책 적용
  payType: keyof typeof SHOPBY_PAY_TYPE;
  refundAccount: null | string;
  availableBanks: {
    // 환불 가능한 은행
    bank: keyof typeof SHOPBY_BANK;
    label: PropertyType<typeof SHOPBY_BANK>;
  }[];
  returnAddress: IShopbyClaimAddressBase;
  returnWarehouse: null | IShopbyWareHouseForClaim; // 반품/교환지
  exchangeAddress: null | IShopbyClaimAddressBase;
  deliveryCompanyTypeWithLabels: {
    deliveryCompanyType: string;
    label: string;
  }[];
  deliveryCompanyTypes: string[];
}

export interface IEstimateShopbyClaimPriceRequestPayload {
  claimType: keyof typeof SHOPBY_CLAIM_TYPE;
  productCnt: number; // 취소/반품할 제품수량
  returnWayType: 'SELLER_COLLECT' | 'BUYER_DIRECT_RETURN'; // 판매자수거요청 | 구매자직접반품
  claimedProductOptions?: {
    orderProductOptionNo: number;
    productCnt: number;
  }[];

  claimReasonType: keyof typeof SHOPBY_CLAIM_REASON_TYPE;
  responsibleObjectType: null | 'BUYER' | 'SELLER'; // responsibleObjectType이 null이면 ClaimReasonType에 매핑되는 귀책 적용
}

export interface IEstimateShopbyClaimPriceForOneRequestPayload {
  orderOptionNo: number;
  claimType: keyof typeof SHOPBY_CLAIM_TYPE;
  productCnt: number; // 취소/반품할 제품수량
  exchangeCnt: null | number;
  returnWayType: 'SELLER_COLLECT' | 'BUYER_DIRECT_RETURN'; // 판매자수거요청 | 구매자직접반품
  exchangeOptionNo?: number;
  exchangeProductNo: number;

  claimReasonType: keyof typeof SHOPBY_CLAIM_REASON_TYPE;
  responsibleObjectType: null | 'BUYER' | 'SELLER'; // responsibleObjectType이 null이면 ClaimReasonType에 매핑되는 귀책 적용
}

export interface IEstimateShopbyClaimPriceResponsePayload {
  productAmtInfo: IShopbyProductAmtInfo; // 상품금액정보
  deliveryAmtInfo: IShopbyClaimDeliveryAmtInfo; // 배송비정보
  subtractionAmtInfo: IShopbySubtractionAmtInfo; // 차감금액정보
  refundPayAmt: number; // 환불금액(적립금포함)
  refundSubPayAmt: number; // 적립금환불금액
  refundType: null | keyof typeof SHOPBY_REFUND_TYPE; // 환불결제방법
  refundPayType: null | keyof typeof SHOPBY_PAY_TYPE; // 환불방법 (PG)
  refundTypeLabel: string; // 환불방법(노출용)
  additionalPayAmt: number; // 추가결제금액
  refundMainPayAmt: number; // 환불금액(적립금제외)
}

export interface IShopbyClaimedProductOption {
  orderProductOptionNo: number;
  productCnt: number; // 취소 제품수량 - 현재는 그대로 전체 수량 입력, 추후 수량 선택입력 기능 추가
}

export interface ICreateShopbyCancelClaimsRequestPayload {
  claimReasonDetail: string;
  responsibleObjectType: null | 'BUYER' | 'SELLER'; // responsibleObjectType이 null이면 ClaimReasonType에 매핑되는 귀책 적용
  claimType: keyof typeof SHOPBY_CLAIM_TYPE;
  claimReasonType: null | keyof typeof SHOPBY_CLAIM_REASON_TYPE;

  claimedProductOptions: IShopbyClaimedProductOption[];
  refundsImmediately: true; // 즉시환불여부, 기본값 true
}

export interface ICreateShopbyReturnClaimsRequestPayload
  extends ICreateShopbyCancelClaimsRequestPayload {
  claimImageUrls: string[];
  returnAddress: IShopbyClaimAddressBase;
  deliveryCompanyType: null | string;
  returnWayType: 'SELLER_COLLECT';
  productCnt: number; // 취소/반품할 제품수량
  // 완료 페이지에서 활용하는 배송사 한국어 라벨
  deliveryCompanyTypeLabel: string;
  // invoiceNo: null; // 반품 송장번호
}

type TShopbyAdditionalPayType = 'CASH' | 'ACCUMULATION' | 'NAVER_PAY';
// CASH: 무통장입금, ACCUMULATION: 적립금 전액 사용, NAVER_PAY: 네이버페이 주문형

export interface IShopbyExchangeOption {
  // 교환할 옵션
  inputTexts: IShopbyOptionInputBase[];
  optionNo: number; // 교환상품의 선택된 몰 옵션번호
  orderCnt: number; // 교환상품수량
  productNo: number; // 교환상품의 몰상품번호
  additionalProductNo: number; // 교환상품의 추가상품 번호
}

export interface ICreateShopbyExchangeClaimsRequestPayload
  extends ICreateShopbyReturnClaimsRequestPayload {
  productCnt: number;
  orderOptionNo: number;
  additionalPayRemitter: string; // 입금자명
  additionalPayType: TShopbyAdditionalPayType; // 추가 결제 방법 - 07.06 무통장으로 우선 구현 후, 적립금 선택 방식 추가
  exchangeAddress: IShopbyClaimAddressBase; // 교환출고지주소
  exchangeOption: IShopbyExchangeOption;
  bankAccountInfo?: IShopbyBank;
  saveBankAccountInfo?: boolean; // 환불계좌정보 저장 여부(true일 경우 bankAccountInfo 필수)
}

export interface IShopbyGetClaimDetailResponsePayload {
  claimNo: string;
  claimReasonType: keyof typeof SHOPBY_CLAIM_REASON_TYPE;
  claimReasonDetail: null | string;
  claimImageUrls: null | string[];
  returnDelivery: {
    // 반품배송정보 (nullable)
    deliveryCompanyTypeLabel: null | string;
    deliveryCompanyType: null | string;
    invoiceNo: null | string; // 송장번호 (nullable)
    evadesReturnProcess: boolean; // 무적반품(임의반품) 여부
  };
  claimedOption: IShopbyClaimOrderOption;
  claimedOptions: IShopbyClaimOrderOption[];
  returnAddress: null | IShopbyClaimReturnAddress; // 반품수거지 (nullable)
  refundType: null | keyof typeof SHOPBY_REFUND_TYPE; // 환불방법 (PG)
  claimType: keyof typeof SHOPBY_CLAIM_TYPE;
  exchangeAddress: null | IShopbyClaimReturnAddress; // 교환출고배송지 (nullable)
  claimClassType: keyof typeof SHOPBY_CLAIM_CLASS_TYPE | null; // 클레임 타입 (nullable)
  claimPriceInfo: IEstimateShopbyClaimPriceResponsePayload; // NOTE: 금액 정보
  exchangePayInfo: null | IShopbyExchangePayInfo;
  exchangedOption: IShopbyClaimOrderOption;
  refundBankAccount: IShopbyBank;
}

export interface IShopbySaveImagePayload {
  imageUrl: string;
  originName: string;
}

export interface ICreateShopbyUserAddressRequestPayload {
  addressName: string; // 주소록명, api 명세는 nullable 이나, 기획상 필수 값.
  defaultYn: 'Y' | 'N'; // 기본 배송지 여부
  receiverName: string; // 수령자 명
  receiverZipCd: string; // 배송지 우편번호
  receiverAddress: string; // 배송지 주소
  receiverJibunAddress: string; // 배송지 지번
  receiverDetailAddress?: string | null; // 배송지 상세 주소
  receiverContact1: string; // 연락처 1
  // addressType: TShopbyAddressType; // 배송지 타입
  // receiverContact2?: string | null; // 연락처 2
  // customsIdNumber?: string | null; // 개인통관고유부호
  // countryCd?: string | null; // 국가 코드
}

export type IUpdateShopbyOrderAddressRequstPayload = Omit<
  IShopbyReceiverAddressBase,
  'addressNo' | 'addressName'
> & { deliveryMemo: string };

export interface IUpdateShopbyOrderAddressRequst {
  orderNo: string;
  data: IUpdateShopbyOrderAddressRequstPayload;
}

export interface IShopbyZipCodeBaseAddress {
  address: string;
  roadAddress: string;
  jibunAddress: string;
  zipCode: string;
}

export interface IShopbyZipCodeRequestPayload {
  keyword: string;
  pageNumber: number;
  pageSize: number;
}

export interface IShopbyCheckClaimWithdrawablePayload {
  claimNo: number;
  validationType: keyof typeof SHOPBY_WITHDRAWABLE_STATE_FROM_VALIDATION_TYPE;
  afterClaimNos: number[]; // 이후 클레임 번호
  shippingNos: number[];
}

export type TShopbyProviderType =
  | 'PAYCO' // 페이코
  | 'NAVER' // 네이버
  | 'KAKAO' // 카카오
  | 'FACEBOOK' // 페이스북
  | 'IAMSCHOOL' // 아이엠스쿨
  | 'LIIVMATE' // 리브메이트
  | 'NHNENT' // 엔에이치엔엔터
  | 'UNIONE' // 유니원
  | 'LINE' // 라인
  | 'NCPSTORE' // 엔씨피스토어
  | 'KAKAO_SYNC'; // 카카오싱크

export type TShopbyInquiryAnswerAdminType =
  | 'PLATFORM' // 플랫폼
  | 'SERVICE' // 서비스
  | 'PARTNER' // 파트너
  | 'SHOPBY'; // 서비스

export type TShopbyInquiryAnswer = {
  inquiryNo: number; // 상품문의 답변 번호
  title: string; // 상품문의 답변 제목
  content: string; // 상품문의 답변 내용
  administrator: boolean; // 상품문의 답변 관리자 여부
  secreted: boolean; // 상품문의 답변 비밀글 여부
  memberId: string; // 상품문의 답변 작성자 아이디
  adminType: TShopbyInquiryAnswerAdminType;
  partnerName: string; // 상품문의 답변자 파트너인 경우, 파트너명
  nickName: string; // 상품문의 답변자 닉네임
  registerYmdt: string; // 상품문의 답변 등록 일자
  updateYmdt: string; // 상품문의 답변 수정 일
};

export type TShopbyItemInquiryType =
  typeof SHOPBY_ITEM_INQUIRY_TYPE_LIST[number]['value']; // 상품문의 타입 | PRODUCT: 상품, DELIVERY: 배송, CANCEL: 취소, RETURN: 반품, EXCHANGE: 교환, REFUND: 환불, OTHER: 기타

export interface IShopbyInquiry {
  inquiryNo: number; // 상품문의 번호
  blocked: boolean; // 차단여부
  title: string; // 문의 제목
  content: string; // 문의 내용
  replied: boolean; // 답변 여부
  administrator: boolean; // 관리자 여부
  secreted: boolean; // 비밀글 여부
  memberId: string; // 작성자 아이디
  registerNo: number; // 회원번호
  registerName: string; // 작성자 명
  providerType: TShopbyProviderType; // provider 유형
  expelled: boolean; // 작성자 탈퇴 여부
  nickName: string; // 작성자 닉네임
  gradeLabel: string; // 작성자 등급
  registerYmdt: string; // 상품문의 등록 일자
  updateYmdt: string; // 상품문의 수정 일자
  modifiable: boolean; // 상품문의 수정 가능 여부
  myInquiry: boolean; // 내 상품문의 여부
  type: TShopbyItemInquiryType;
  productNo: number; // 상품번호
  productName: string; // 상품명
  productManagementCd: string; // 상품관리코드
  orderNo: string; // 주문번호
  brandName: string; // 상품 브랜드명
  brandNameEn: string; // 상품 브랜드 영문명
  imageUrl: string; // 상품 이미지 url
  displayStatusType: 'DISPLAY' | 'BLIND'; // DISPLAY: 전시중, BLIND: 전시안함
  answers?: TShopbyInquiryAnswer[];
}

export interface IGetShopbyMyInquiryRequestPayload {
  pageNumber: number;
  pageSize: number;
  hasTotalCount: boolean;
  // startYmd: string; // 값 없으면 최근 3개월
  // endYmd: string;
}

export interface IPostShopbyItemInquiryRequestPayload {
  parentInquiryNo?: number;
  productNo: number;
  title: string;
  content: string;
  secreted: boolean;
  type: TShopbyItemInquiryType;
  email: string | null;
}

export interface IEditShopbyItemInquiryRequestPayload {
  title: string;
  content: string;
  secreted: boolean;
  type: TShopbyItemInquiryType;
}

export interface IAccumulationConfig {
  useExpireNotification: boolean; // 적립금 만료 알림 사용여부
  useSignUpAccumulation: boolean; // 회원가입 적립금 사용여부
  adminMemo: string; // 운영자 메모
  accumulationUnit: string; // 적립금 단위, 예) P
  accumulationGivePoint: Record<
    keyof typeof SHOPBY_ACCUMULATION_CONFIG,
    PropertyType<typeof SHOPBY_ACCUMULATION_CONFIG>
  >; // 적립금 지급 시점
  accumulationRate: number; // 적립금 기본 적립률
  limitMaxRate: boolean; // 적립금 사용 최대 적립금 제한 여부
  limitMinProductPrice: boolean; // 적립금 사용 최소 상품금액 제한 여부
  accumulationUseMinPrice: number; // 적립금 사용 최소 적립금
  accumulationDisplayFormatType: string; // 적립금 노출 설정
  // Enum: [ FIXED_AMT: 정액 단일표시, FIXED_RATE: 정률(%) 단일표시 (ex 2%), FIRST_FIXED_RATE: 정률 정액 동시표시, FIRST_FIXED_AMT: 정액 정률 동시표시 ]
  reviewsAccumulationDetail: {
    photoReviewsAccumulation: number; // 포토 상품평 적립금
    photoReviewsLength: number; // 포토 상품평 글자수
    reviewsLength: number; // 상품평 글자수
    reviewsAccumulation: number; // 상품평 적립금
  };
  expireNotificationPoint: number; // 적립금 만료 알림 시점 (day 기준)
  accumulationValidPeriod: number; // 적립금 유효기간 (month 기준)
  useProductAccumulation: boolean; // 상품 적립 사용여부
  excludingReservePayAccumulation: boolean; // 적립금 사용 최대비율
  limitMinPrice: boolean; // 적립금 사용 최소 적립금 제한 여부
  accumulationUseMinProductPrice: number; // 적립금 사용 최소 상품금액
  signUpAccumulation: number; // 회원가입 적립금
  accumulationUseMaxRate: number; // 적립금 사용 최대비율
  useReviewsAccumulation: boolean; // 상품평 적립금 사용여부
  productAccumulationBasisType: string; // 상품 금액 기준 설정
  // Enum: [ SALE_PRICE: 판매가, SALE_STANDARD_PRICE: 판매가 ± 옵션가, SALE_PROMOTION_PRICE: 판매가 ± 옵션가 - 프로모션할인, DISCOUNTED_PRICE: 할인적용가, DISCOUNTED_STANDARD_PRICE: 할인적용가 ± 옵션가, DISCOUNTED_PROMOTION_PRICE: 할인적용가 ± 옵션가 - 프로모션할인 ]
  accumulationName: string; // 적립금명, 예) 스토어 포인트
  excludingReservePayCoupon: boolean; // 쿠폰할인 결제시 적립금 지급 제외여부
  useMemberAccumulation: boolean; // 회원 적립 사용여부
}

export interface IGetShopbyMallInfoResponsePayload {
  settingTimestamp: number;
  accumulationConfig: IAccumulationConfig;
}

export interface IShopbyReviewableItem {
  orderOptionNo: number; // 주문 상품 옵션 번호
  optionUsed: boolean; // 옵션사용여부
  optionName: string; // 옵션명
  optionValue: string; // 옵션값
  optionNo: number; // 옵션 번호
  optionType: 'NORMAL_OPTION' | 'ADDITIONAL_PRODUCT'; // 옵션형태 NORMAL_OPTION: 일반 옵션, ADDITIONAL_PRODUCT: 추가 옵션
  optionTitle: string; // 옵션 권장 출력값
  optionManagementCd: string; // 옵션 관리 코드
  orderCnt: number; // 주문수량
  price: {
    standardPrice: number; // 정상가 (상품판매가 + 옵션추가금액)
    immediateDiscountedPrice: number; // 즉시할인적용가
    buyPrice: number; // 구매가 (즉시할인 + 추가할인 적용)
    standardAmt: number; // 정상금액 (상품판매가 + 옵션추가금액) * 주문수량
    immediateDiscountedAmt: number; // 즉시할인적용가 * 주문수량
    buyAmt: number; // 구매금액 (구매가 * 주문수량)
    salePrice: number; // 상품판매가
    addPrice: number; // 옵션추가금액
    immediateDiscountAmt: number; // 즉시할인금액
    additionalDiscountAmt: number; // 추가할인금액
    accumulationRate: number; // 적립율
  };
  brandName: string; // 브랜드 명
  brandNameEn: string; // 브랜드 명(영문)
  inputs: [
    {
      inputValue: string; // 구매자 작성 값
      inputLabel: string; // 구매자 작성 이름
    },
  ];
  orderNo: string; // 주문번호
  productNo: number; // 상품 번호
  imageUrl: string; // 상품 이미지 URL
  productName: string; // 상품명
  productNameEn: string; // 상품 영문명
  reservationDeliveryYmdt: string | null; // 예약 배송 시작일
  claimNo: number; // 클레임 번호
  deliverable: boolean; // 배송상품여부
  claimStatusType: string; // 클레임 상태
  additionalProductNo: number; // 추가 상품 번호
  orderStatusDate: {
    registerYmdt: string; // 등록일자
    buyConfirmYmdt: string; // 구매확정일자
    reviewableYmdt: string; // 상품평작성기한(구매확정일로부터 90일)
  };
  reservation: boolean; // 예약 주문 여부
  orderStatusType: keyof typeof SHOPBY_ORDER_STATUS_TYPE; // 주문 상태
  refundable: boolean; // 환불 가능 여부
  accumulationAmt: number; // 적립금
  photoReviewAccumulationAmt: number; // 포토 리뷰 적립금
  nextActions: {
    nextActionType: string;
    uri: string;
  }[];
  delivery: {
    invoiceNo: string; // 송장 번호
    deliveryCompanyType: null | string; // 택배사 타입
    deliveryCompanyTypeLabel: string; // 택배사 명
    retrieveInvoiceUrl: string; // 송장추적 URL
  };
  deliveryInternationalYn: boolean; // 해외 배송가능 여부
  exchangeYn: 'y' | 'n'; // 교환여부
}

// 리뷰 (상품평) 작성과 수정 동시에 사용하기 위해 만든 interface
export interface IShopbyReviewWriteAndEditBaseInfo {
  imageUrl: string; // 상품 이미지 URL
  productNo: number; // 상품 번호
  productName: string; // 상품명
  optionNo?: number; // 옵션 번호
  orderOptionNo: number; // 주문 상품 옵션 번호
  optionValue: string; // 옵션값
  inputs: [
    {
      inputValue: string; // 구매자 작성 값
      inputLabel: string; // 구매자 작성 이름
    },
  ];
  optionUsed: boolean; // 옵션사용여부
  isEdit?: boolean;
  reviewNo?: string;
  fileUrls?: string[];
  rate?: number;
  content?: string;
}

export interface IShopbyMyReview {
  reviewNo: number; // 상품평 번호
  productNo: number; // 상품 번호
  productName: string; // 상품 명
  brandName: string; // 브랜드 명
  imageUrl: string; // 상품 대표 이미지 URL
  rate: number; // 평점
  orderedOption: IShopbyOrderedOption;
  registerYmdt: string; // 등록일
  updateYmdt: string; // 수정일
  recommendCnt: number; // 추천 수
  reportCnt: number; // 신고 수
  blindReportCnt: number; // 블라인드 신고 수
  orderNo: string; // 주문 번호
  brandNameEn: string; // 브랜드 영문 명
  productManagementCd: string; // 판매자 관리 코드
  latformType: string; // 작성 플랫폼 , PC: PC, MOBILE_WEB: 모바일 웹, MOBILE_APP: 모바일 앱, RESPONSIVE: 반응형
  isDeletedProductReview: boolean; // 상품 삭제 여부
  extraJson: string; // 상품평 작성 리뷰
  bestReviewYn: YNType; // 상품평 구문
  fileUrls: string[]; // 첨부 파일 url 리스트 (최대 5개)
  externalReview: boolean; // 외부 리뷰 작성 여부
  content: string; // 리뷰 내용
}

export interface IShopbyGetAccumulationsRequestPayload extends IShopbyPaging {
  accumulationReason: keyof typeof SHOPBY_ACCUMULATION_REASON_TYPE | null;
  startYmd?: null | string; // 조회 시작일 (yyyy-MM-dd), null인 경우 3개월로 조회
  endYmd?: null | string; // 조회 종료일 (yyyy-MM-dd), null인 경우 3개월로 조회
  direction?: TDirection; // default: DESC
}

export interface IShopbyGetAccumulationsResponsePayload {
  items: {
    accumulationNo: number; // 적립금 번호
    accumulationAmt: number; // 적립금액
    registerYmdt: string; // 등록일, ex) 2023-07-27T10:38:40.576688
    startYmdt: string; // 시작일, ex) 2023-07-27T10:38:40.576688
    expireYmdt: string; // 만료일, ex) 2023-07-27T10:38:40.576688
    accumulationReserveReason: keyof typeof SHOPBY_ACCUMULATION_RESERVE_REASON_TYPE; // 적립사유 코드
    accumulationReserveReasonDisplay: string; // 적립사유 코드 표시명
    reasonDetail: string; // 적립사유 상세
    accumulationStatus: keyof typeof SHOPBY_ACCUMULATION_STATUS_TYPE; // enum : [ GIVE: 지급, GIVE_BY_CANCELED: 사용 취소로 인한 재지급, SUBTRACTION: 차감, SUBTRACTION_BY_CANCELED: 지급 취소로 인한 재차감 ]
    accumulationStatusGroupType: keyof typeof SHOPBY_ACCUMULATION_STATUS_GROUP_TYPE; // 적립 지급/차감 구분 코드 (PAYMENT: 지급, DEDUCTION: 차감)
    orderNo: string; // 주문번호
    totalAvailableAmt: number; // 적립금 총액
    accumulationRestAmt: number; // 잔여 적립금
  }[];
  memberNo: number; // 회원 번호
  totalAmt: number; // 적립 총액
  totalCount: number; // 전체 카운트
}

export interface IShopbyGetAccumulationsSummaryRequestPayload {
  expireStartYmdt?: null | string; // 조회 시작일 (yyyy-MM-dd), null인 경우 1개월로 조회
  expireEndYmdt?: null | string; // 조회 종료일 (yyyy-MM-dd), null인 경우 1개월로 조회
}

export interface IShopbyGetAccumulationsSummaryResponsePayload {
  totalAvailableAmt: number; // 사용가능한 총 적립금액
  totalExpireAmt: number; // 만료조회 총 적립금액
}

export type TShopbyPolicy = {
  title: string;
  paragraphList: {
    firstDepth: string;
    hasDot?: boolean;
    secondDepth?: string[];
  }[];
};

export type TShopbyItemExhibitionUrlType = 'EVENT_NUMBER' | 'DIRECT'; // EVENT_NUMBER: 기획전 번호 사용, DIRECT: URL 직접입력
export type TShopbyItemExhibitionDisplayPeriodType = 'REGULAR' | 'PERIOD'; // REGULAR: 상시, PERIOD: 기간설정
export type TShopbyItemExhibitionProgressStatus =
  | 'ALL'
  | 'READY'
  | 'ING'
  | 'END';
// ALL: 전체, READY: 진행대기, ING: 진행중, END: 진행종료

export interface IShopbyItemExhibitionContentsInfo {
  eventNo: number; // 기획전 번호
  label: string; // 기획전 명
  url: string; // 기획전 url
  urlType: TShopbyItemExhibitionUrlType; // URL 타입, EVENT_NUMBER: 기획전 번호 사용, DIRECT: URL 직접입력
  id: string; // 기획전 ID
  displayPeriodType: TShopbyItemExhibitionDisplayPeriodType; // 전시기간 타입, REGULAR: 상시, PERIOD: 기간설정
  startYmdt: string; // 전시 시작일
  endYmdt: string; // 전시 종료일
  pcImageUrl: string; // 기획전 이미지 URL(PC)
  mobileimageUrl: string; // 기획전 이미지 URL(MOBILE)
  promotionText: string; // 홍보문구
  tag: string; // 검색용 태그값
  eventYn: YNType; // 이벤트 여부
  progressStatus: TShopbyItemExhibitionProgressStatus; // 진행 상태, ALL: 전체, READY: 진행대기, ING: 진행중, END: 진행종료
}

/*
NOTE :
샵바이 기획전 작업 진행하면서 쿠폰 타입 우선 추가해 두었습니다.
쿠폰 기능 도입 시 scheme이 동일하다면 interface 명 변경해서 사용하면 될 것 같습니다.
2023.09.12 / 이양우
*/

export interface IShopbyExhibitionCouponInfo {
  couponNo: number; // 쿠폰 번호
  couponName: string; // 쿠폰 이름
  couponType: 'PRODUCT' | 'PRODUCT_PLUS' | 'CART' | 'CART_DELIVERY'; // 쿠폰 종류, PRODUCT: 상품 금액 할인, PRODUCT_PLUS: 상품 금액 할인(플러스), CART: 장바구니 금액 할인, CART_DELIVERY: 장바구니 배송비 할인
  couponTargetType:
    | 'ALL_PRODUCT'
    | 'PRODUCT'
    | 'BRAND'
    | 'CATEGORY'
    | 'PARTNER'
    | 'EVENT'; // 쿠폰 대상 종류, ALL_PRODUCT: 전상품, PRODUCT: 개별상품, BRAND: 브랜드, CATEGORY: 카테고리, PARTNER: 파트너사, EVENT: 찬스이벤트
  allianceRefererType: 'DIRECT' | 'NAVER_KNOWLEDGE_SHOPPING'; // 제휴 방문처 타입, DIRECT: 직방문, NAVER_KNOWLEDGE_SHOPPING: 네이버 지식쇼핑
  downloadable: boolean; // 다운로드가능여부
  imageUrl: string; // 이미지 URL
  discountInfo: {
    // 쿠폰 할인 정보
    discountAmt: number; // 쿠폰 할인액
    discountRate: number; // 쿠폰 할인율
    fixedAmt: boolean; // 정액여부
    freeDelivery: boolean; // 배송비 무료 여부(배송비쿠폰인경우)
    maxDiscountAmt: number; // 최대 할인액
    skippedAccumulationAmt: boolean; // 적립급 지금 불가 여부
    useOtherCoupon: boolean; // 타 쿠폰과 함께 사용가능 여부
  };
  dateInfo: {
    // 쿠폰 날짜 정보
    issueStartYmdt: string; // 발급 시작 일
    issueEndYmdt: string; // 발급 제한 일
    issueStartHour: number; // 발급 시작 시간
    issueEndHour: number; // 발급 종료 시간
    issueDaysOfWeek: string; // 발급 가능 요일
  };
  useConstraint: {
    // 쿠폰 사용 조건 정보
    limitPayType: string; // 쿠폰 사용조건 결제수단
    minSalePrice: number; // 쿠폰 사용조건 최소 구매액
    maxSalePrice: number; // 쿠폰 사용조건 최대 구매액
    minDeliveryAmt: number; // 쿠폰 사용조건 최소 배송비
    usablePlatformTypes: string; // PC: PC, MOBILE_WEB: 모바일 웹, MOBILE_APP: 모바일 앱, RESPONSIVE: 반응형
    useEndYmdt: string; // 사용 사용종료 일
    useDays: number; // 사용가능 기간 - 쿠폰을 발급받은 날부터 (31은 월말까지, -1은 제한없음)
  };
  issueConstraint: {
    // 	쿠폰 발행 조건 정보
    dailyIssueLimit: boolean; // 1일내 발급 수량 제한 여부 (true: 1일내 발급 수량 제한, false: 1일내 발급 수량 비제한)
    dailyIssueLimitCnt: number; // 1일내 발급 제한 수량
    dailyIssuePerPersonLimitCnt: number; // 1인당 발급 제한 수량(1일간)
    issuablePlatformTypes: string; // PC: PC, MOBILE_WEB: 모바일 웹, MOBILE_APP: 모바일 앱, RESPONSIVE: 반응형
    issuePerPersonLimit: boolean; // 1인당 발급수량제한여부 (true: 1인당 발급 수량 제한, false: 1인당 발급 수량 비제한)
    issuePerPersonLimitCnt: number; // 1인당 발급 제한 수량(총기간)
    memberGradeName: string; // 발급대상 회원 등급
  };
  couponStatus: {
    // 쿠폰 상태 정보
    issuableCnt: number; // 발급가능수량
    myIssuedCnt: number; // 내가 발급 받은 개수
    myIssuedCntToday: number; // 내가 발급 받은 개수(오늘)
    totalIssuableCnt: number; // 쿠폰 발행가능 수량
    totalIssuedCnt: number; // 총 발행수량
    totalIssuedCntToday: number; // 총 발행수량(오늘)
  };
}

export interface IShopbyExhibitionItemInfo {
  productNo: number; // 상품번호
  productName: string; // 상품명
  productNameEn: string; // 영문 상품명
  promotionText: string; // 홍보문구
  salePrice: number; // 상품판매
  immediateDiscountAmt: number; // 즉시할인가
  immediateDiscountUnitType: 'WON' | 'RATE'; // 즉시할인 타입,  WON: 정액, RATE: 정률
  immediateDiscountStartYmdt: string; // 즉시할인 시작일자
  immediateDiscountEndYmdt: string; // 즉시할인 종료일자
  additionDiscountAmt: number; // 추가상품할인가
  additionDiscountUnitType: 'WON' | 'RATE'; // 추가상품할인 타입, WON: 원화, RATE: 비율
  minSalePrice: number; // 추가할인 최소 기준금액
  maxSalePrice: number; // 추가할인 최대 기준금액
  maxDiscountAmount: number; // 추가할인 정률 최대 할인 금액
  liked: boolean; // 좋아요 여부
  likeCount: number; // 좋아요 카운트
  partnerName: string; // 파트너명
  reviewRating: number; // 상품평 평균점수
  totalReviewCount: number; // 총 상품평 수
  deliveryConditionType: TDeliveryConditionType; // 배송비 타입, FREE: 무료, CONDITIONAL: 조건부 무료, FIXED_FEE: 유료(고정 배송비), QUANTITY_PROPOSITIONAL_FEE: 수량 비례, PRICE_FEE: 금액별 차등, QUANTITY_FEE: 수량별 차등
  saleCnt: number; // 구매 수량 (재고 미노출의 경우 -999 재고 미노출 설정일때 실재고가 없는 경우, 0으로 표기)
  stockCnt: number; // 재고 (재고 미노출의 경우 -999 재고 미노출 설정일때 실재고가 없는 경우, 0으로 표기)
  mainStockCnt: number; // 대표 옵션 재고 (재고 미노출의 경우 -999 재고 미노출 설정일때 실재고가 없는 경우, 0으로 표기)
  brandNo: number; // 브랜드 번호
  brandName: string; // 브랜드 명
  brandNameEn: string; // 브랜드 영문 명
  brandNameKo: string; // 브랜드 한글 명
  brandNameType: 'NAME_KO' | 'NAME_EN' | 'NONE'; // 브랜드명 타입 (한글: NAME_KO, 영문: NAME_EN, 알 수 없음: NONE)
  stickerInfos: // 스티커 정보
  {
    type: 'TEXT' | 'IMAGE'; // 스티커 타입, TEXT: 텍스트, IMAGE: 이미지
    label: string; // 스티커 라벨
  }[];
  stickerLabels: string[]; // 스티커 라벨(배열)
  adult: boolean; // 성인 상품 여부 (true: 성인 상품, false: 비성인 상품)
  salePeriodType: 'REGULAR' | 'PERIOD'; // 판매 기간 타입, REGULAR: 상시 판매, PERIOD: 기간지정 판매
  saleStartYmdt: string; // 판매시작일시
  saleEndYmdt: string; // 판매종료일시
  saleStatusType: typeof SHOPBY_ITEM_STATUS_TYPE_LIST[number]['value']; // READY: 판매대기, ONSALE: 판매중, FINISHED: 판매종료, STOP: 판매중지, PROHIBITION: 판매금지
  reservationData: {
    reservationStartYmdt: string; // 예약판매 시작일
    reservationEndYmdt: string; // 예약판매 종료일
    reservationDeliveryYmdt: string; // 예약판매 배송시작일
    reservationStockCnt: number; // 예약판매 재고수량 (재고 미노출의 경우 -999 재고 미노출 설정일때 실재고가 없는 경우, 0으로 표기)
  };
  imageUrls: string[]; // 상품 이미지 URL
  listImageUrls: string[]; // 리스트 이미지 URL
  hasCoupons: {
    product: boolean; // 상품 쿠폰 여부
    brand: boolean; // 브랜드 쿠폰 여부
    category: boolean; // 카테고리 쿠폰 여부
    partner: boolean; // 파트너 쿠폰 여부
    event: boolean; // 이벤트 쿠폰 여부
  };
  couponTag: string; // 쿠폰 태그
  maxCouponAmt: number; // 최대 쿠폰 적용 가격(default: 0)
  couponDiscountAmt: number; // 상품 기본옵션 가격기준으로 적용 가능한 최대 쿠폰 할인가
  registerYmdt: string; // 상품 등록일
  contentsIfPausing: string; // 판매중지 시 가격대체문구
  optionValues: {
    // 상품 조합형 옵션정보
    mallProductNo: number; // 상품번호
    stockCnt: number; // 재고 (재고 미노출의 경우 -999 재고 미노출 설정일때 실재고가 없는 경우, 0으로 표기)
    optionValue: string; // 옵션명 ( | 라인으로 구분 )
  }[];
  displayCategoryNos: string; // 전시 카테고리 번호
  searchProductId: string; // NHNEnt Search ID(상품검색 API응답에만 존재함)
  frontDisplayYn: boolean; // 화면 노출 여부
  urlDirectDisplayYn: boolean; // 주소 노출 여부
  productManagementCd: string; // 판매자 관리코드
  hsCode: string; // hsCode
  isSoldOut: boolean; // 품절 여부
  accumulationInfo: {
    // 적립금 정보
    amount: number; // 적립금
    rewardRateOfProduct: number; // 상품개별적립률
    rewardRateOfMemberBenefit: number; // 회원등급적립률
  };
  groupManagementCode: string; // 그룹관리코드
  groupManagementCodeName: string; // 그룹관리코드 노출명
  canAddToCart: boolean; // 장바구니 사용 여부
  productSalePeriodType?: 'REGULAR' | 'PERIOD'; // REGULAR: 상시 판매, PERIOD: 기간지정 판매
  sectionProductStartYmdt?: string; // 상품섹션에서 설정한 시작일
  sectionProductEndYmdt?: string; // 상품섹션에서 설정한 종료일
  enableCoupons: boolean; // 사용가능쿠폰 존재 여부
}

export interface IShopbyExhibitionSectionInfo {
  label: string; // 섹션 명
  imageUrl: string; // 이미지 URL
  pcPerRow: number; // 한 행에 디스플레이 되는 이미지 개수(PC), 프론트 정책에 따라 사용하지 않아도 됨
  mobilePerRow: number; // 한 행에 디스플레이 되는 이미지 개수(MOBILE), 프론트 정책에 따라 사용하지 않아도 됨
  displayOrder: number; // 섹션의 전시 순서
  displayableStock: boolean; // 재고 노출 여부 (false:재고 미노출 / true:재고 노출)
  products: IShopbyExhibitionItemInfo[];
  productNos: string[];
}

export interface IShopbyItemExhibitionDetailInfo {
  eventNo: number; // 기획전 번호
  label: string; // 기획전 명
  url: string; // 기획전 url
  urlType: TShopbyItemExhibitionUrlType; // EVENT_NUMBER: 기획전 번호 사용, DIRECT: URL 직접입력
  id: string; // 기획전 ID
  displayPeriodType: TShopbyItemExhibitionDisplayPeriodType; // 전시기간 타입, REGULAR: 상시, PERIOD: 기간설정
  startYmdt: string; // 전시 시작일
  endYmdt: string; // 전시 종료일
  pcImageUrl: string; // 기획전 이미지 URL(PC)
  mobileimageUrl: string; // 기획전 이미지 URL(MOBILE)
  tag: string; // 검색용 태그값
  eventYn: YNType; // 이벤트 여부
  categoryNos: number[]; // 카테고리 번호 Array
  section: IShopbyExhibitionSectionInfo[];
  coupon: {
    alreadyIssuedImageUrl: string; // 기발급완료 이미지
    beforeIssueImageUrl: string; // 쿠폰 발급전 이미지
    dateExpiredImageUrl: string; // 발급기간 만료 이미지
    guideImageUrl: string; // 기획전 사용안내 이미지
    issuedImageUrl: string; // 발급완료 이미지
    soldOutImageUrl: string; // 남은수량 부족 이미지
    coupons: IShopbyExhibitionCouponInfo[]; // 쿠폰 목록
  };
  top: {
    pc: {
      url: string; // 상단 이미지 URL or HTML string
      type: 'HTML' | 'FILE';
    };
    mobile: {
      url: string; // 상단 이미지 URL or HTML string
      type: 'HTML' | 'FILE';
    };
  };
  orders: ['TOP', 'SECTIONS', 'COUPONS']; // 쿠폰, 상단 노출 정보 정렬순서(배열 순서대로 프론트에서 노출, 몰마다 정책이 다를수 있음)
  promotionText: string; // 홍보문구
}

export interface IShopbyBestReview {
  reviewNo: number; // 상품평 번
  content: string; // 리뷰 내용
  images: string[]; // 첨부된 이미지파일 (없는 경우 빈 배열)
  register: {
    no: number; // 등록자 회원 정보/ 비회원의 경우 0
    name: string; // 등록자 이름
  };
  productInfo: {
    no: number; // 상품 번호
    name: string; // 상품명
    thumbnailImage: string; // 상품 썸네일 이미지
    totalReviewCount: number; // 상품 리뷰 개수
  };
  isBestReview: boolean; // 베스트 리뷰 여부
}

type BrandNameType = 'NAME_KO' | 'NAME_EN' | 'NONE';

type PeriodType = 'REGULAR' | 'PERIOD';

type ProductType = 'DEFAULT' | 'EVENT' | 'OFFLINE' | 'RENTAL';

type StickerType = 'TEXT' | 'IMAGE';

type UrlType = 'IMAGE_URL' | 'VIDEO_URL';

type SaleStatus = 'READY' | 'ONSALE' | 'FINISHED' | 'STOP' | 'PROHIBITION';

type DiscountType = 'WON' | 'RATE';

type DeliveryType = 'FREE' | 'CONDITIONAL' | 'FIXED_FEE';

/**
 * @param GT - 초과 - GREATER THAN
 * @param LTE - 미만 -LESS GREATER THAN
 * @param GTE - 이상 - GREATER THAN or EQUAL
 * @param EQ - 동등 - EQUAL
 * @param BETWEEN - 사이의
 */
type DiscountedComparisonType = 'GT' | 'LTE' | 'GTE' | 'EQ' | 'BETWEEN';

/**
 * @param POPULAR - 판매인기순(검색엔진 도입)
 * @param SALE_YMD - 판매일자
 * @param SALE_END_YMD - 판매종료일자
 * @param DISCOUNTED_PRICE - 가격순
 * @param REVIEW - 상품평
 * @param SALE_CNT - 총판매량순
 * @param RECENT_PRODUCT - 최근상품순
 * @param MD_RECOMMEND - MD추천순
 * @param LIKE_CNT - 좋아요
 */
export type OrderBy =
  | 'POPULAR'
  | 'SALE_YMD'
  | 'SALE_END_YMD'
  | 'DISCOUNTED_PRICE'
  | 'REVIEW'
  | 'SALE_CNT'
  | 'RECENT_PRODUCT'
  | 'MD_RECOMMEND'
  | 'LIKE_CNT';

type OrderDirection = 'DESC' | 'ASC';

/**
 * @param ALL_CONDITIONS - 전체 판매 상태 조회
 * @param READY_ONSALE - 판매대기와 판매중 상품 조회
 * @param ONSALE - 판매중 상품만 조회 (default)
 * @param RESERVATION_AND_ONSALE - 예약판매중인 상품과 판매중인 상품만 조회
 */
type SaleStatusType =
  | 'ALL_CONDITIONS'
  | 'READY_ONSALE'
  | 'ONSALE'
  | 'RESERVATION_AND_ONSALE';

type ShippingAreaType = 'PARTNER' | 'MALL';

interface IBrand {
  brandName: string;
  brandNameKo: string;
  brandNameEn: string;
  /**
   * 브랜드 상품 수
   */
  count: number;
  /**
   * 브랜드명 타입
   */
  brandNameType: BrandNameType;
  /**
   * 브랜드번호
   */
  brandNo: number;
}

interface IBrandCategory {
  /**
   * 부모 카테고리 번호
   */
  parentCategoryNo: number;
  /**
   * 전시 순서
   */
  displayOrder: number;
  /**
   * 카테고리 상품 개수
   */
  count: number;
  /**
   * 카테고리번호
   */
  categoryNo: number;
  /**
   * 카테고리 명
   */
  label: string;
}

interface IMultiLevelCategories {
  /**
   * 부모 카테고리 번호
   */
  parentCategoryNo: number;
  /**
   * 전시 순서
   */
  displayOrder: number;
  /**
   * 카테고리 상품 개수
   */
  count: number;
  /**
   * 카테고리번호
   */
  categoryNo: number;
  /**
   * 하위 카테고리
   */
  childCategories: IBrandCategory[];
  /**
   * 카테고리 명
   */
  label: string;
}

export interface IShopbyItems {
  /**
   * 그룹관리코드 노출명
   */
  groupManagementCodeName: string;
  /**
   * 추가할인 최소 기준금액
   */
  minSalePrice: number;
  /**
   * 그룹관리코드
   */
  groupManagementCode: string;
  /**
   * 좋아요 수
   */
  likeCount: number;
  /**
   * 장바구니 사용 여부
   */
  canAddToCart: boolean;
  /**
   * 총 리뷰 수
   */
  totalReviewCount: number;
  /**
   * 상품평 평균점
   */
  reviewRating: number;
  /**
   * 좋아요 여부
   */
  liked: boolean;
  /**
   * 상품명
   */
  productName: string;
  /**
   * 최대 쿠폰 적용 가격
   * @default 0
   */
  couponDiscountAmt: number;
  /**
   * 추가상품할인가
   */
  additionDiscountAmt: number;
  /**
   * 상품 이미지 정보
   * @param {UrlType} type - 상품 이미지 url 타입
   * @param {string} url - 상품 이미지 url
   */
  imageUrlInfo: {
    type: UrlType;
    url: string;
  }[];
  /**
   * 브랜드 번호
   */
  brandNo: number;
  /**
   * 메인 베스트 상품 여부
   * @deprecated (더 이상 제공하지 않는 개체항목입니다)
   */
  mainBestProductYn: boolean;
  /**
   * 브랜드 명
   */
  brandName: string;
  /**
   * 리스트 이미지 URL
   */
  listImageUrls: string[];
  /**
   * 즉시할인가
   */
  immediateDiscountAmt: number;
  /**
   * 스티커 라벨(배열)
   */
  stickerLabels: string[];
  /**
   * 쿠폰여부
   * @param {boolean} product - 상품쿠폰 태그
   * @param {boolean} partner - 파트너쿠폰 태그
   * @param {boolean} event - 기획전쿠폰 태그
   * @param {boolean} category - 카테고리쿠폰 태그
   * @param {boolean} brand - 브랜드쿠폰 태그
   */
  hasCoupons: {
    product: boolean;
    partner: boolean;
    event: boolean;
    category: boolean;
    brand: boolean;
  };
  /**
   * 즉시할인 타입
   */
  immediateDiscountUnitType: DiscountType;
  /**
   * 추가상품할인 타입
   */
  additionDiscountUnitType: DiscountType;
  /**
   * 전시 여부
   */
  frontDisplayYn: boolean;
  /**
   * 판매 수량 (재고 미노출의 경우 -999 재고 미노출 설정일때 실재고가 없는 경우, 0으로 표기)
   */
  saleCnt: number;
  /**
   * 상품 항목 추가 정보
   * @param {boolean} isMultipleSelection - 항목 복수선택여부 (true: 복수개 선택가능, false: 1개만 선택가능)
   * @param {number} propValueNo - 상품 항목 값 번호
   * @param {string} propValue - 상품 항목 값
   * @param {string} propName - 상품 항목명
   * @param {number} propNo - 상품 항목명 번호
   */
  customProperties: {
    isMultipleSelection: boolean;
    propValueNo: number;
    propValue: string;
    propName: string;
    propNo: number;
  }[];
  /**
   * 판매시작일시
   */
  saleStartYmdt: string;
  /**
   * 브랜드 한글명
   */
  brandNameKo: string;
  /**
   * 상품섹션에서 설정한 종료일
   * @deprecated (더 이상 제공하지 않는 개체항목입니다)
   */
  sectionProductEndYmdt: string;
  /**
   * 품절여부 (true-품절, false-미품절)
   */
  isSoldOut: boolean;
  /**
   * 성인 상품 여부
   */
  adult: boolean;
  /**
   * 상품 조합형 옵션정보 ( 옵션명은 | 라인으로 구분 )
   * @param {string} optionValue - 옵션명
   * @param {number} stockCnt - 재고 (재고 미노출의 경우 -999 재고 미노출 설정일때 실재고가 없는 경우, 0으로 표기)
   * @param {number} mallProductNo - 상품번호
   */
  optionValues: {
    optionValue: string;
    stockCnt: number;
    mallProductNo: number;
  }[];
  /**
   * 전시카테고리 번호 정보
   */
  displayCategoryNos: string;
  /**
   * 판매자 관리코드
   */
  productManagementCd: string;
  /**
   * 예약판매정보
   * @param {string} reservationDeliveryYmdt - 예약판매 배송시작일
   * @param {number} reservationStockCnt - 예약판매 재고수량 (재고 미노출의 경우 -999 재고 미노출 설정일때 실재고가 없는 경우, 0으로 표기)
   * @param {string} reservationEndYmdt - 예약판매 종료일
   * @param {string} reservationStartYmdt - 예약판매 시작일
   */
  reservationData: {
    reservationDeliveryYmdt: string;
    reservationStockCnt: number;
    reservationEndYmdt: string;
    reservationStartYmdt: string;
  };
  /**
   * 배송비 타입
   */
  deliveryConditionType: DeliveryType;
  /**
   * 상품섹션에서 설정한 시작일
   * @deprecated (더 이상 제공하지 않는 개체항목입니다)
   */
  sectionProductStartYmdt: string;
  /**
   * 적립금
   */
  accumulationAmtWhenBuyConfirm: number;
  /**
   * 배송비 조건
   * @param {string} summary - 배송비 요약
   * @param {number} perOrderCnt - 수량 비례 조건에서 수량 (nullable)
   * @param {number} criteria - 무료배송을 위한 최소 금액 (nullable)
   */
  deliveryConditionInfo: {
    summary: string;
    perOrderCnt: number;
    criteria: number;
    /**
     * 차등 조건 구간 (nullable)
     * @param {number} deliveryFeeRanges - 해당 구간에서의 배송비
     * @param {number} below - ~미만 (nullable)
     * @param {number} aboveOrEqual - ~이상 (nullable)
     */
    deliveryFeeRanges: {
      deliveryAmt: number;
      below: number;
      aboveOrEqual: number;
    }[];
    /**
     * 차등 조건 구간 요약 (nullable)
     */
    rangeSummaries: any;
  };
  /**
   * 브랜드명 타입 (NAME_KO: Korean, NAME_EN: English, NONE: none)
   */
  brandNameType: BrandNameType;
  /**
   * 쿠폰 태그
   * @deprecated (더 이상 제공하지 않는 개체항목입니다)
   */
  couponTag: string;
  /**
   * 최대 쿠폰 적용 가격
   * @default 0
   */
  maxCouponAmt: number;
  /**
   * 상품의 상품 노출 타입
   */
  productSalePeriodType: PeriodType;
  /**
   * 추가할인 정률 최대 할인 금액
   */
  maxDiscountAmount: number;
  /**
   * 사용가능쿠폰 존재 여부
   */
  enableCoupons: boolean;
  /**
   * 상품유형
   */
  productType: ProductType;
  /**
   * 상품번호
   */
  productNo: number;
  /**
   * 상품 유효기간 (nullable)
   */
  expirationDate: string;
  /**
   * 상품 등록일
   */
  registerYmdt: string;
  /**
   * 상품판매가
   */
  salePrice: number;
  /**
   * 파트너명
   */
  partnerName: string;
  /**
   * 즉시할인 시작일자
   */
  immediateDiscountStartYmdt: string;
  /**
   * 상품조회화면 노출 여부
   */
  urlDirectDisplayYn: boolean;
  /**
   * 판매중지 시 가격대체문구
   */
  contentsIfPausing: string;
  /**
   * 배송 구분
   */
  shippingArea: TShippingAreaType;
  /**
   * 판매종료일시
   */
  saleEndYmdt: string;
  /**
   * 판매기간유형
   * @deprecated (더 이상 제공하지 않는 개체항목입니다)
   */
  salePeriodType: string;
  /**
   * 추가할인 최대 기준금액
   */
  maxSalePrice: number;
  /**
   * 홍보문구
   */
  promotionText: string;
  /**
   * 스티커 정보
   * @param {string} name - 스티커 이름
   * @param {string} label - 스티커 라벨
   * @param {enum} type - 스티커 타입
   */
  stickerInfos: {
    name: string;
    label: string;
    type: StickerType;
  }[];
  /**
   * 상품 리스트 이미지 정보
   * @param {UrlType} type - 상품 리스트 이미지 url 타입
   * @param {string} url - 상품 리스트 이미지 url
   */
  listImageUrlInfo: {
    type: UrlType;
    url: string;
  }[];
  /**
   * HS CODE
   */
  hsCode: string;
  /**
   * 즉시할인 종료일자
   */
  immediateDiscountEndYmdt: string;
  /**
   * 상품 이미지 URL
   */
  imageUrls: string[];
  /**
   * 브랜드 영문 명
   */
  brandNameEn: string;
  /**
   * 적립금 정보
   * @param {number} rewardRateOfMemberBenefit - 회원등급적립률
   * @param {number} amount - 적립금
   * @param {number} rewardRateOfProduct - 상품개별적립률
   */
  accumulationInfo: {
    rewardRateOfMemberBenefit: number;
    amount: number;
    rewardRateOfProduct: number;
  };
  /**
   * 대표 옵션 재고 (재고 미노출의 경우 -999 재고 미노출 설정일때 실재고가 없는 경우, 0으로 표기)
   */
  mainStockCnt: number;
  /**
   * 영문 상품명
   */
  productNameEn: string;
  /**
   * 검색상품번호
   * @deprecated (더 이상 제공하지 않는 개체항목입니다)
   */
  searchProductId: string;
  /**
   * 재고 (재고 미노출의 경우 -999 재고 미노출 설정일때 실재고가 없는 경우, 0으로 표기)
   */
  stockCnt: number;
  /**
   * 판매상태
   */
  saleStatusType: SaleStatus;
}

export interface IShopbyProductSearchResponse {
  /**
   * 페이지 수
   */
  pageCount: number;

  brands: IBrand[];
  depth1Categories: IBrandCategory[];
  depth2Categories: IBrandCategory[];
  depth3Categories: IBrandCategory[];
  depth4Categories: IBrandCategory[];
  depth5Categories: IBrandCategory[];
  totalCount: number;

  multiLevelCategories: IMultiLevelCategories[];
  /**
   * 최소 가격
   */
  minPrice: number;
  /**
   * 재고 노출 여부 (false:재고 미노출 / true:재고 노출)
   * @example false로 재고를 숨김처리 한 경우,
   * 재고 관련 필드는(실제 재고가 있더라도) -999로 고정으로 리턴하며 실재고 값은 따로 내려주지 않아 조회 불가합니다.
   * 실재고가 0인 경우에만 0으로 응답합니다.
   * 만약 재고 숨김처리 시, front에서 [-999]로 표시되도록 처리되고 있는게 있다면 재고노출여부(displayableStock)를 기준으로 수정 작업이 필요합니다.
   * 만약 재고 숨김처리 시, front에서 [품절]로 표시되도록 처리되고 있는게 있다면 재고/예약재고값을 기준이 아닌, 품절상태(isSoldOut)값을 기준으로 처리되도록 수정 작업이 필요합니다.
   */
  displayableStock: boolean;
  /**
   * 최대 가격
   */
  maxPrice: number;

  clickUrlPrefix: {
    /**
     * 인자 값
     */
    param: string;
    url: string;
  };
  items: IShopbyItems[];
}

export interface IShopbyProductSearchRequest {
  /**
   * 판매가 - 즉시할인 - 추가상품할인이 적용된 "최종 할인가격", between검색일 경우 입력값 2개 필요(다수 정보는 항목 추가 필요
   */
  discountedPrices?: number;
  /**
   * 검색어(여러 검색어일 경우 space 로 구분 AND 연산)
   */
  keywords?: string;
  /**
   * 결과내 검색(결과 내 검색의 검색어 space 구분 AND 연산)
   */
  keywordInResult?: string;
  /**
   * 최종 할인가격 검색 조건
   */
  discountedComparison?: DiscountedComparisonType;
  /**
   * 배송비 타입
   */
  deliveryConditionType?: DeliveryType;
  /**
   * 판매 상태
   */
  saleStatus?: SaleStatusType;
  /**
   * 품절 상품 포함 여부 (default: false)
   */
  soldout?: boolean;
  /**
   * 총 상품평 수 포함 여부 (default: false, false 설정 시 무조건 0)
   */
  totalReviewCount?: boolean;
  /**
   * 서비스에 계약된 모든 쇼핑몰 조회 여부 (default: false)
   */
  familyMalls?: boolean;
  /**
   * 판매자관리코드 같은 상품 검색
   */
  productManagementCd?: string;
  /**
   * 조회시 제외할 상품번호
   */
  excludeMallProductNo?: number;
  /**
   * 조회할 상품번호
   */
  includeMallProductNo?: number;
  /**
   * 조회할 상품항목추가정보 번호
   */
  propNos?: string;
  /**
   * 조회할 상품항목추가정보 값
   */
  propValueNos?: string;

  by?: OrderBy;

  /**
   * 정렬기준 (default: DESC)
   */
  direction?: OrderDirection;
  /**
   * 품절상품 뒤로 배치 여부(default = false)
   */
  soldoutPlaceEnd?: boolean;

  /**
   * 전시 카테고리 번호(여러개 일 경우 항목 추가)
   */
  categoryNos?: string;
  /**
   * 제외할 전시 카테고리 번호(여러개 일 경우 항목 추가, 번호에 속한 모든 하위 카테고리 제외)
   */
  excludeCategoryNos?: string;
  /**
   * 전시카테고리 검색 조건
   */
  categoryOperator?: 'AND' | 'OR';
  /**
   * 브랜드 번호(여러개 일 경우 항목 추가)
   */
  brandNos?: string;
  /**
   * 파트너 번호(상품 공급업체 번호)
   */
  partnerNo?: number;
  /**
   * 클라이언트 키
   */
  clientKey?: number;
  /**
   * 페이지 번호
   */
  pageNumber: number;
  /**
   * 한 페이지당 노출 수 (최대 요청 가능: 500개)
   */
  pageSize?: number;
  /**
   * 세일 상품만 조회 여부(default: false)
   */
  onlySaleProduct?: boolean;
  /**
   * 목록에 최대 할인 쿠폰 가격 포함 여부(default: false)
   */
  hasMaxCouponAmt?: boolean;
  /**
   * 목록 카운트 포함 여부(default: false)
   */
  hasTotalCount?: boolean;
  /**
   * 목록에 옵션 value 포함 여부(default: false)
   */
  hasOptionValues?: boolean;
  /**
   * summary 정보 포함 여부(default: true)
   */
  includeSummaryInfo?: boolean;
  /**
   * 배송 구분
   */
  shippingAreaType?: ShippingAreaType;
  /**
   * 유효일자
   */
  expirationDate?: string;
  /**
   * 상품 그룹관리코드
   */
  groupManagementCode?: string;
  /**
   * 회원 엑세스 토큰 (nullable)
   */
  accessToken?: string;
}

export interface IShopbyCategory {
  categoryNo: number; // 카테고리 번호
  label: string; // 카테고리 명
  depth: number; // 카테고리 뎁스
  icon: string; // 카테고리 아이콘
  content: string; // 카테고리 상세 HTML
  children: IShopbyCategory[]; // 자식 카테고리
}

export interface IShopbyFlatCategory {
  fullCategoryName: string; // 1~5뎁스 카테고리 명

  depth1CategoryNo: number; // 1 뎁스 카테고리 번호
  depth1Label: string; // 1 뎁스 카테고리 명
  depth1DisplayOrder: number; // 순서
  depth1Icon: string; // 1 뎁스 아이콘
  depth1Content: string; // 1 뎁스 카테고리 상세 HTML

  depth2CategoryNo: number; // 2 뎁스 카테고리 번호
  depth2Label: string; // 2 뎁스 카테고리 명
  depth2DisplayOrder: number; // 순서
  depth2Icon: string; // 2 뎁스 아이콘
  depth2Content: string; // 2 뎁스 카테고리 상세 HTML

  depth3CategoryNo: number; // 3 뎁스 카테고리 번호
  depth3Label: string; // 3 뎁스 카테고리 명
  depth3DisplayOrder: number; // 순서
  depth3Icon: string; // 3 뎁스 아이콘
  depth3Content: string; // 3 뎁스 카테고리 상세 HTML

  depth4CategoryNo: number; // 4 뎁스 카테고리 번호
  depth4Label: string; // 4 뎁스 카테고리 명
  depth4DisplayOrder: number; // 순서
  depth4Icon: string; // 4 뎁스 아이콘
  depth4Content: string; // 4 뎁스 카테고리 상세 HTML

  depth5CategoryNo: number; // 4 뎁스 카테고리 번호
  depth5Label: string; // 4 뎁스 카테고리 명
  depth5DisplayOrder: number; // 순서
  depth5Icon: string; // 4 뎁스 아이콘
  depth5Content: string; // 4 뎁스 카테고리 상세 HTML
}

/*
 * Store / Shopby End
 */

/*
 * TOSSPAYMENY Start
 */

/**
 * 결제 UI 3개중 1개
 * 참고 https://dashboard.tosspayments.com/payment-widget-service/tm/665432/ui-setting-list
 */
export type TTossUIVariantKey =
  | 'DEFAULT' // 계좌이체 + 카드결제
  | 'bankPaymentOnly' // 계좌이체
  | 'onlinePaymentOnly'; // 카드결제

/**
 * 결제 처리 과정에서 발생할 수 있는 에러를 나타내는 유니온 타입.
 * - 'PAY_PROCESS_CANCELED': 구매자에 의해 결제가 취소되면 발생. 결제 과정이 중단된 것이라서 failUrl로 orderId가 전달되지 않아요.
 * - 'PAY_PROCESS_ABORTED': 결제가 실패하면 발생합니다. 오류 메시지를 확인.
 * - 'REJECT_CARD_COMPANY': 구매자가 입력한 카드 정보에 문제가 있다면 발생.
 */
export type TTossFailURLCodeType =
  | 'PAY_PROCESS_CANCELED' // mesaagge : 사용자에 의해 결제가 취소되었습니다.
  | 'PAY_PROCESS_ABORTED' // mesaagge : 결제 진행 중 승인에 실패하여 결제가 중단되었습니다.
  | 'REJECT_CARD_COMPANY' // mesaagge : 결제 승인이 거절되었습니다.
  | 'PAY_PROCESS_EXPIRED_CAMFIT'; // message: 이미 결제시간이 만료된 예약건입니다.

/**
 * successUrl 파라미터 중 결제 유형
 */
export type TTossSuccessURLPaymentType =
  | 'NORMAL' // 일반 결제입니다. 코어 결제 승인 API를 호출해서 결제를 완료하세요.
  | 'BRANDPAY' // 브랜드페이 결제입니다. 브랜드페이 결제 승인 API를 호출해서 결제를 완료하세요.
  | 'KEYIN'; // 키인 결제입니다. 코어 결제 승인 API를 호출해서 결제를 완료하세요.

export interface SelectedPaymentMethodType {
  /** 결제 타입 정보입니다. NORMAL(일반결제), BRANDPAY(브랜드페이), KEYIN(키인 결제), CUSTOM(커스텀 결제수단) 중 하나입니다. */
  type: 'NORMAL' | 'BRANDPAY' | 'KEYIN' | 'CUSTOM';
  /** 결제수단입니다. 카드, 가상계좌, 간편결제, 휴대폰, 계좌이체, 문화상품권, 도서문화상품권, 게임문화상품권 중 하나입니다. */
  method: string;
  /** 결제수단이 간편결제일 때 반환되는 간편결제 정보입니다. */
  easyPay: {
    /** 선택한 간편결제사 코드입니다. */
    provider: string;
  };
  /**
   * 결제 타입이 CUSTOM일 때 반환되는 커스텀 결제수단 키입니다.
   * @example bankPayment: 계좌이체
   *  */
  paymentMethodKey: string;
}

interface IShopbyCoupons {
  productCoupons: {
    /** 상품쿠폰 발행번호 */
    couponIssueNo: number;
    /** 상품 쿠폰 프로모션 코드 */
    promotionCode: string;
    /** 상품번호 */
    productNo: number;
  }[];
  /** 장바구니 쿠폰 발행 번호 */
  cartCouponIssueNo: number;
  /** 쿠폰 할인 코드 */
  promotionCode: string;
}

export interface IShopbyPurchase {
  /** 주문서 정보 */
  orderSheetNo: string;
  shippingAddress: {
    /**
     * 배송지 번호(0:신규, 0이상:이전배송지
     * */
    addressNo: number;
    /** 배송지 우편 번호 */
    receiverZipCd: string;
    /** 배송지 주소 */
    receiverAddress: string;
    /** 배송지 지번(지역추가배송비계산 시 사용) */
    receiverJibunAddress: string | null;
    /** 배송지 상세 주소 */
    receiverDetailAddress: string | null;
    /** 수령자 명 */
    receiverName: string;
    /** 주소록명 */
    addressName: string;
    /** 연락처1 */
    receiverContact1: string;
    /** 연락처2 */
    receiverContact2: string | null;
    /** 개인고유통관부호 */
    customsIdNumber: string | null;
    /** 국가코드 */
    countryCd: string | null;
  };
  /** 기본 주소지 설정 여부 */
  useDefaultAddress: boolean;
  /** 주소록에 등록 여부 */
  saveAddressBook: boolean;
  /** 주문메모 */
  orderMemo: string;
  /** 배송메모 */
  deliveryMemo: string;
  /** 회원여부 */
  member: boolean;
  /** 주문자 정보 */
  orderer: IShopbyOrdererBase | undefined;
  /** 주문자 정보로 회원정보 수정시 사용 */
  updateMember: boolean;
  /** 임시주문비밀번호 (비회원) */
  tempPassword: string | null;
  /** 쿠폰 정보 */
  coupons: IShopbyCoupons | null;
  /** 보조결제 수단 결제액(적립금 사용액) */
  subPayAmt: number;
  /** PG 명세서에 표시될 주문명 */
  orderTitle: string | null;
  /** 외부PG사 */
  pgType: string;
  /** 결제 수단 */
  payType: string;
  /** 결제 완료 후 리턴되는 쇼핑몰의 URL */
  clientReturnUrl: string;
  /** 결제완료 API 호출 후 다시 전달 받을 값(NCPPay script에서 넣음) (nullable) */
  clientParams: any;
  /** 앱내 결제 여부, Y인 경우 extraData.appUrl에 결제완료 후 돌아갈 app scheme을 넣어야 함 (nullable) */
  inAppYn: string;
  /**
   * 추가정보
   * @example {'cardNumber':4242424242424242, 'cardExpMonth':12, 'cardExpYear':'2020', 'cardCvc':'123', 'saveYn':'Y'} or {'cardId':'card_12354123124'}
   * */

  extraData: any;
  /** 검증을 위한 결제 예정금액(적립금 사용후) */
  paymentAmtForVerification: number | undefined;
  /** 결제 정보 저장 */
  savesLastPayType: boolean;
  /** 무통장 거래 시 현금영수증 발행 신청 여부(true: 신청, false: 미신청) * kcp 현금영수증 발행 대행 서비스 사용 시 true (nullable) */
  applyCashReceipt: boolean;
  /** 현금 영수증 */
  cashReceipt: null;
  /** 무통장 입금 정보 */
  bankAccountToDeposit: null;
  /** 무통장 입금 시 입금할 입금자 이름 */
  remitter: null;
  /** // 복수 배송지 */
  shippingAddresses: never[];
  /** 렌탈 상품 정보 */
  rentalInfo: null;
  /** 마이페이 결제인증 정보 */
  myPayInfo: null;
}

export type TSocialLoginProvider = 'kakao' | 'google' | 'apple';

export interface ISiteWithDate {
  date: string;
  dateObj: Date;
  data: {
    camp: string;
    site: string;
    type: string;
    floorType: string;
    size: string;
    price: number;
  }[];
}

export interface SiteItem {
  _id: string;
  startTimestamp: number;
  endTimestamp: number;
  siteData: {
    _id: string;
    name: string;
    accommodationCharge: number;
    siteWidth?: number;
    siteLength?: number;
  };
  zoneData: {
    _id: string;
    type: typeof campTypes[number]['value'];
    floorType: typeof floorTypes[number]['value'];
    canBringPet: boolean;
    name: string;
    siteWidth: number;
    siteLength: number;
  };
  campData: {
    _id: string;
    name: string;
    isOnlinePaymentAvailable: true;
    address: string;
    thumbnail: string;
  };
  badges: { id: string; name: string }[];
}

export interface SiteExhibitionSite {
  startTimestamp: number;
  sites: SiteItem[];
}

export interface SiteCurator {
  _id: string;
  title: string;
  startTimestamp: number;
  endTimestamp: number;
  status: 'IN_PROGRESS';
  id: string;
}

export interface SiteExhibitionData {
  title: string;
  image: string;
  siteCurator: SiteCurator;
  startTimestamps: number[];
  sitesGroupByDate: SiteExhibitionSite[];
}

export type TCampExhibitionType = 'main' | 'sub' | 'additional' | 'recommend';

export interface ICampExhibitionCode {
  _id: string;
  title: string;
  code: string;
}
