import {
  IBookResponse,
  IBooking,
  IBookingDate,
  ICamp,
  IPet,
  IServiceBrief,
  ISite,
  IZone,
} from '@types';
import { baseApi, getHistoryPopCacheConfig } from 'api';
import { ILongTermBookRequestPayload } from 'store/types';
import instance from './instance';

// 장박 캠핑장 목록 조회
export const getLongTermCamps = async (params: any) => {
  const res = await baseApi.get<{
    status: 'success' | 'fail';
    data: ICamp[];
  }>(`/long-term-campings/camps/legacy`, getHistoryPopCacheConfig({ params }));
  return res.data;
};

export const getLongTermCampsCount = async (params: {
  search: string;
  city: string;
  majors: string;
  types: string;
  leisureTypes: string;
  facilities: string;
  locationTypes: string;
  additionalFacilities: string;
  floorTypes: string;
  parkingTypes: string;
  minBookableDays: string;
  checkInTimestamp: number;
  checkoutTimestamp: number;
}) => {
  const res = await baseApi.get<{
    status: 'success' | 'fail';
    data: ICamp[];
  }>(`/long-term-campings/camps/count`, getHistoryPopCacheConfig({ params }));
  return res.data;
};

// 장박 캠핑존 목록 조회
export const getLongTermZones = async (campId: string, params: any) => {
  const res = await baseApi.get<{
    status: 'success' | 'fail';
    data: IZone[];
  }>(
    `/long-term-campings/zones?campId=${campId}`,
    getHistoryPopCacheConfig({ params }),
  );
  return res.data;
};

// 특정 장의 존 개수 조회
export const getLongTermZonesCount = async (
  campId: string,
  startTimestamp?: number,
  endTimestamp?: number,
) => {
  let path = `campId=${campId}`;
  if (startTimestamp && endTimestamp) {
    path += `&startTimestamp=${startTimestamp}&endTimestamp=${endTimestamp}`;
  }

  const res = await baseApi.get<{
    status: 'success' | 'fail';
    data: {
      count: number;
    };
  }>(`/long-term-campings/zones/count?${path}`);
  return res.data;
};

// 장박 사이트 목록 조회
export const getLongTermSites = async (
  zoneId: string,
  params: {
    startTimestamp: number;
    endTimestamp: number;
  },
) => {
  const res = await baseApi.get<{
    status: 'success' | 'fail';
    data: ISite[];
  }>(
    `/long-term-campings/sites?zoneId=${zoneId}`,
    getHistoryPopCacheConfig({ params }),
  );
  return res.data;
};

// 장박 사이트 개수 조회
// export const getLongTermSitesCount = async (
//   zoneId: string,
//   startTimestamp?: number,
//   endTimestamp?: number,
// ) => {
//   let path = `zoneId=${zoneId}`;

//   if (startTimestamp && endTimestamp) {
//     path += `&startTimestamp=${startTimestamp}&endTimestamp=${endTimestamp}`;
//   }

//   const res = await baseApi.get<{
//     status: 'success' | 'fail';
//     data: {
//       count: number;
//     };
//   }>(`/long-term-campings/sites/count?${path}`);
//   return res.data;
// };

export const getCalendarByCamp = async (campId: string) => {
  const res = await baseApi.get<{
    status: 'success' | 'fail';
    minBookingPeriod: number;
    data: {
      year: number;
      month: number;
      startTimestamp: number;
      endTimestamp: number;
      isAvailable: boolean;
    }[];
  }>(`/long-term-campings/camps/month?campId=${campId}`);
  return res.data;
};

// 특정 존 달력 조회
export const getCalendarByZone = async (zoneId: string) => {
  const res = await baseApi.get<{
    status: 'success' | 'fail';
    minBookingPeriod: number;
    data: {
      year: number;
      month: number;
      startTimestamp: number;
      endTimestamp: number;
      isAvailable: boolean;
    }[];
  }>(`/long-term-campings/zones/month?zoneId=${zoneId}`);
  return res.data;
};

// 장박 예약 전 요금 계산
export const calculateLongTermBooking = async (params: {
  siteId: string;
  checkInDate: IBookingDate;
  checkoutDate: IBookingDate;
  numOfAdults: number;
  numOfTeens: number;
  numOfChildren: number;
  numOfCars: number;
  services: IServiceBrief[];
  hasTrailer: boolean;
  hasCampingCar: boolean;
  pets: IPet[];
}) => {
  const res = await instance.post<IBooking>(
    `/long-term-campings/book/calculate`,
    params,
  );

  return res.data;
};

// 장박 예약
export const longTermBook = async (params: ILongTermBookRequestPayload) => {
  const res = await instance.post<IBookResponse>(
    `/long-term-campings/book`,
    params,
  );

  return res.data;
};
