import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ZoneState,
  IGetZoneListRequestPayload,
  IGetZoneRequestPayload,
  IGetAddOptsRequestPayload,
} from 'store/types';
import { ICoupon, IZone, IZoneDetail } from '@types';

const initialState: ZoneState = {
  campId: null,
  zone: {} as IZoneDetail,
  additionalOpts: [],
  zoneList: [],
  zoneCnt: 0,
  error: null,
  isMoreBtnClicked: false,
  isLoadedAll: false,
  bestCoupons: [],
  numOfCoupons: 0,
};

export const zoneSlice = createSlice({
  name: 'zone',
  initialState,
  reducers: {
    setZoneState: (state, action: PayloadAction<ZoneState>) => {
      state.zone = action.payload.zone;
      state.additionalOpts = action.payload.additionalOpts;
    },
    startFetchingZone: (state, action: PayloadAction<string>) => {
      state;
    },

    getZoneRequest: state => {
      // state.zone = {} as IZoneDetail;
      state.error = null;
    },
    getZoneSuccess: (state, action: PayloadAction<IZoneDetail>) => {
      state.zone = action.payload;
      state.error = null;
    },
    getZoneFailure: (state, action: PayloadAction<AxiosError>) => {
      state.zone = {} as IZoneDetail;
      state.error = action.payload;
    },

    getAdditionalOptsRequest: state => {
      state.additionalOpts = [];
      state.error = null;
    },
    getAdditionalOptsSuccess: (state, action) => {
      state.additionalOpts = action.payload;
      state.error = null;
    },
    getAdditionalOptsFailure: (state, action) => {
      state.additionalOpts = [];
      state.error = action.payload;
    },

    getZoneListRequest: (
      state,
      action: PayloadAction<IGetZoneListRequestPayload>,
    ) => {
      state.zoneList = action.payload.skip ? state.zoneList : [];
      state.isLoadedAll = action.payload.skip ? state.isLoadedAll : false;
      state.isMoreBtnClicked = action.payload.skip
        ? state.isMoreBtnClicked
        : false;
      state.error = null;
    },
    getZoneListSuccess: (state, action: PayloadAction<IZone[]>) => {
      state.zoneList = state.zoneList.concat(action.payload);
      state.error = null;
    },
    getZoneListFailure: (state, action: PayloadAction<AxiosError>) => {
      state.zoneList = [];
      state.error = action.payload;
    },

    // 장박 존목록 조회
    // getLongTermZonesRequest: (state, action: PayloadAction<any>) => {
    //   state.zoneList = action.payload.skip ? state.zoneList : [];
    //   state.isLoadedAll = action.payload.skip ? state.isLoadedAll : false;
    //   state.isMoreBtnClicked = action.payload.skip
    //     ? state.isMoreBtnClicked
    //     : false;
    //   state.error = null;
    // },

    // 장박 존목록 조회 성공
    getLongTermZonesSuccess: (
      state,
      action: PayloadAction<{ zones: IZone[]; isMoreBtnClicked: boolean }>,
    ) => {
      // state.zoneList = state.zoneList.concat(action.payload);
      state.zoneList = action.payload.zones;
      state.isMoreBtnClicked = action.payload.isMoreBtnClicked;
      state.error = null;
    },

    // 장박 존목록 조회 실패
    getLongTermZonesFailure: (state, action: PayloadAction<AxiosError>) => {
      state.zoneList = [];
      state.error = action.payload;
    },

    getZoneCntRequest: state => {
      // state.zoneCnt = 0;
      state.error = null;
    },
    getZoneCntSuccess: (state, action: PayloadAction<number>) => {
      state.zoneCnt = action.payload;
      state.error = null;
    },
    getZoneCntFailure: (state, action: PayloadAction<AxiosError>) => {
      state.zoneCnt = 0;
      state.error = action.payload;
    },

    // getLongTermZoneCntRequest: state => {
    //   state.zoneCnt = 0;
    // },
    getLongTermZoneCntSuccess: (state, action: PayloadAction<number>) => {
      state.zoneCnt = action.payload;
    },
    // getLongTermZoneCntFailure: (state, action: PayloadAction<AxiosError>) => {
    //   state.zoneCnt = 0;
    //   state.error = action.payload;
    // },

    showMoreZoneList: (state, action: PayloadAction<string>) => {
      state.isMoreBtnClicked = !state.isMoreBtnClicked;
    },

    checkLoadStatus: state => {
      state.isLoadedAll = true;
    },
    setCampId: (state, action: PayloadAction<string>) => {
      state.campId = action.payload;
    },

    getZonesCouponsRequest: (
      state,
      action: PayloadAction<{
        zoneId: string;
        startTimestamp: number;
        endTimestamp: number;
        price?: number;
      }>,
    ) => {
      state.error = null;
    },
    getZonesCouponsSuccess: (
      state,
      action: PayloadAction<{
        coupons: ICoupon[];
        numOfCoupons: number;
      }>,
    ) => {
      const { coupons, numOfCoupons } = action.payload;
      state.bestCoupons = coupons;
      state.numOfCoupons = numOfCoupons;
    },
    getZonesCouponsFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },
  },
});

export const {
  setZoneState,
  startFetchingZone,
  getZoneRequest,
  getZoneSuccess,
  getZoneFailure,
  getAdditionalOptsRequest,
  getAdditionalOptsSuccess,
  getAdditionalOptsFailure,
  getZoneListRequest,
  getZoneListSuccess,
  getZoneListFailure,

  // getLongTermZonesRequest,
  getLongTermZonesSuccess,
  getLongTermZonesFailure,

  getZoneCntRequest,
  getZoneCntSuccess,
  getZoneCntFailure,

  // getLongTermZoneCntRequest,
  getLongTermZoneCntSuccess,
  // getLongTermZoneCntFailure,

  showMoreZoneList,
  checkLoadStatus,
  setCampId,

  getZonesCouponsRequest,
  getZonesCouponsSuccess,
  getZonesCouponsFailure,
} = zoneSlice.actions;

export default zoneSlice.reducer;
